import React, { useContext } from 'react';
import { createMailtoLink } from '../../../../common/download';
import { Button } from '@freshfields/react-component-library';
import { AppContext } from '../../../../authentication/AppProvider';
import { FolderContext } from '../../../../context';
import shareIcon from '../../../../images/share.svg';

const Share: React.FC = () => {
  const { user } = useContext(AppContext);
  const [folderState] = useContext(FolderContext);

  const handleSharableLink = async () => {
		const { selectedFileIds, selectedFolderOrFileName } = folderState;
		if (selectedFileIds.length === 0) return;
		const link = createMailtoLink({
			selectedFolderOrFileName,
			selectedFileIds,
			name: user?.profile?.name,
		});
		document.body.appendChild(link).click();
  };
  const isFolderSelected = folderState.selectedFolderIds.length > 0;
  const isFileSelected  = folderState.selectedFileIds.length > 0;
  const enableButton = (!isFolderSelected && isFileSelected);
   
  return (
		<span className="action-bar__shareButton action-bar-button">
			<Button
				secondary
				id="share"
				type="button"
				data-e2e-testid="actionBarShareButton"
				data-testid="actionBarShareButton"
				onClick={() => handleSharableLink()}
				disabled={!enableButton}
			>
				<div className="action-bar-button__content">
					<img className="action-bar-button__icon" src={shareIcon} alt="Share" />
					Share
					<span className="u-visuallyhidden">share</span>
				</div>
			</Button>
		</span>
  );
};

export default Share;