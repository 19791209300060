export const getDateFormatted = (datestring: string) => {
    if(isNaN(Date.parse(datestring))){
        return '';
    }

    let d = new Date(datestring);
    let dd = d.getDate();
    let mm = d.getMonth()+1; 
    let year = d.getFullYear();
    let h = d.getHours();
    let m = d.getMinutes();
    let ss = d.getSeconds();
    
    let day = (dd < 10) ? '0' + dd : dd; //Pad day with leading 0 if less than 10
    let month = (mm < 10) ? '0' + mm : mm; 
    let hours = (h < 10) ? '0' + h : h;
    let minutes = (m < 10) ? '0' + m : m;
    let seconds = (ss < 10) ? '0' + ss : ss;

    return day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;
};
