import { useContext, useEffect } from 'react';
import { AppContext } from '../../authentication/AppProvider';
import { FolderContext } from '../../context';
import { PREVIOUS_FOLDER_ID } from '../../common/constants';
import { useLocation } from 'react-router-dom';
import { loadAndDisplaySelectedFolder } from '../../common/loadAndDisplaySelectedFolder';

export const FolderHubGroup = () => {
    const {hubConnection, user} = useContext(AppContext);   
    const [folderState, doFolderStateAction] = useContext(FolderContext);
	const { activeFolderId } = folderState;
    let location = useLocation();
  
    useEffect(() => {
        console.log(`>> Client connection >> ${hubConnection?.connectionId} <<`);
        
        if (hubConnection) {
            hubConnection.on('FolderContentHasChanged', (hubFolderId: number) => {
                const { activeFolderId } = folderState;

                console.log(`>> on FolderContentHasChanged rec'vd (hubFolderId)${hubFolderId} - ${activeFolderId} (propsFolderId) <<`);

                if (hubFolderId === activeFolderId) {
                    loadAndDisplaySelectedFolder({ id: hubFolderId, location, access_token: user?.access_token, doFolderStateAction });
                    console.log('Folder Ids same so refreshed files >> Extra FILTER <<');
                }
            });
        }
        var previousFolderId = sessionStorage.getItem(PREVIOUS_FOLDER_ID) as string;

        if (activeFolderId && Number(activeFolderId) !== Number(previousFolderId)){
            if (activeFolderId && hubConnection && hubConnection.state === 'Connected') {
                console.log(`>> SwitchClientFolderGroups from Folder${previousFolderId}Group to Folder${activeFolderId}Group <<`);
                hubConnection.send('SwitchClientFolderGroups', Number(previousFolderId), Number(activeFolderId));
            }

            sessionStorage.setItem(PREVIOUS_FOLDER_ID, activeFolderId);
        }

        // eslint-disable-next-line
    }, [activeFolderId]);

    return null;
};

