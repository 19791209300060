import React, { useState, useContext } from 'react';
import get from 'lodash/get';
import { upload } from '../../../../api';
import { sortFolderListing } from '../../../../common/sortFolderListing';
import { AppContext } from '../../../../authentication/AppProvider';
import { FolderContext, FOLDER_ACTIONS } from '../../../../context/FolderContext';
import { Button, ToastAtoms } from '@freshfields/react-component-library';
import { Modal } from '../../../Modal';
import uploadIcon from '../../../../images/upload.svg';
import './Upload.style.css';

const getErrorMessage = (response:string) => {
	if(response.includes('404')) {
		return 'No files found to upload';
	}
	else if(response.includes('409')) {
		return 'File already in folder';
	}
	else if(response.includes('415')) {
		return 'File is of unsupported type';
	}
	else {
		return 'Please check the file type, total size and number of files';
	}
};

const Upload: React.FC = () => {
	const { user } = useContext(AppContext);
	const { addToast } = ToastAtoms.useToasts();
	// NOTE: workaround for incorrect types on ToastableFeedbackBlock
	const ToastableFeedbackBlock = ToastAtoms.ToastableFeedbackBlock as any;
	const [folderState, doFolderStateAction] = useContext(FolderContext);
	const [uploadFilesModal, setUploadFilesModal] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [fileList, setUploadTarget] = useState([]);

	const handleUploadFile = async (event: React.FormEvent) => {
		event.preventDefault();
		if (fileList.length === 0) return;
		setIsUploading(true);
		upload({
			token: get(user, 'access_token'),
			fileList: fileList as never,
			folderId: folderState.activeFolderId,
		})
			.then((response) => {
				//Basic error checking
				if(response.status !== 201) {
					const errorMessage = getErrorMessage(response.toString());
					console.error(errorMessage);

					addToast(
						<ToastableFeedbackBlock
							error
							headerText={<span className="u-heading-xs">Upload failed</span>}
							bodyText={errorMessage}
						/>,
						{ autoDismiss: true }	
					);

					setIsUploading(false);	
					setUploadFilesModal(false);
					return;
	      		 };		
				doFolderStateAction({
					type: FOLDER_ACTIONS.FETCH_FILES,
					payload: sortFolderListing([...folderState.folders.files, ...response.data]),
				});
				addToast(
					<ToastableFeedbackBlock
						success
						headerText={<span className="u-heading-xs">Files uploaded</span>}
						bodyText="Your file has been uploaded to the selected folder"
					/>,
					{ autoDismiss: true }
				);
				setIsUploading(false);
				setUploadFilesModal(false);
			})
			.catch((error) => {
				console.error(error);
				setIsUploading(false);
				setUploadFilesModal(false);
				addToast(
					<ToastableFeedbackBlock
						error
						headerText={<span className="u-heading-xs">Upload failed</span>}
						bodyText="An error occurred while uploading your files"
					/>,
					{ autoDismiss: true }
				);
			});
	};	
	
	const isFolderSelected = folderState.selectedFolderIds.length > 0;
	const isFileSelected  = folderState.selectedFileIds.length > 0;
	const activeFolder = folderState.activeFolder != null;
	const enableButton = (!isFolderSelected && !isFileSelected && activeFolder);

	return (
		<span className="action-bar__uploadDocumentButton action-bar-button">
			<Button
				secondary
				id="uploadDocument"
				type="button"
				data-e2e-testid="actionBarUploadDocumentButton"
				data-testid="actionBarUploadDocumentButton"
				onClick={() => {
					setUploadFilesModal(!uploadFilesModal);
				}}
				disabled={!enableButton}
			>
				<div className="action-bar-button__content">
					<img className="action-bar-button__icon"  src={uploadIcon} alt="Upload Document" />
					Upload files
					<span className="u-visuallyhidden">Upload files</span>
				</div>
			</Button>
			<Modal modalIsOpen={uploadFilesModal} closeModal={() => setUploadFilesModal(false)}>
				<form onSubmit={handleUploadFile}>
					<div className="modal__body">
						<input
							type="file"
							id="myFile"
							name="filename"
							multiple
							onChange={(event) => setUploadTarget(get(event, 'target.files'))}
						/>
					</div>
					<div className="modal__footer">
						<Button
							secondary
							type="button"
							className="modal_btn modal__cancel-btn"
							onClick={() => {
								setIsUploading(false);
								setUploadFilesModal(false);
							}}
						>
							Cancel
						</Button>
						<Button 
							loading={isUploading} 
							primary="true" 
							disabled={isUploading}
							type="submit"
						>
							{!isUploading && <>Submit</>}
							{isUploading && <>Uploading</>}
						</Button>
					</div>
				</form>
			</Modal>
		</span>
	);
};

export default Upload;
