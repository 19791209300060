import { PERMISSIONS } from '../../../../enum';
import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../authentication/AppProvider';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Button } from '@freshfields/react-component-library';
import { DrawerPanel } from '../../../DrawerPanel';
import { TabPanel, tabA11yProps } from '../../../TabsPanel';
import jobsIcon from '../../../../images/jobs.svg';
import { JobsTable } from '../../../JobsTable';

const Jobs: React.FC = () => {
    const { user } = useContext(AppContext);
    const isAdmin = user?.profile?.roles.includes(PERMISSIONS.ADMIN);
    const [toggleDrawer, setToggleDrawer] = useState(false);
    const [tabsPanelValue, setTabsPanelValue] = useState(0);

    return (
        <span className="action-bar__uploadDocumentButton action-bar-button">
            <Button
				secondary
				id="jobs"
				type="button"
				data-e2e-testid="actionBarJobsButton"
				data-testid="actionBarJobsButton"
                onClick={() => setToggleDrawer(!toggleDrawer)}
			>
				<div className="action-bar-button__content">
					<img className="action-bar-button__icon"  src={jobsIcon} alt="Jobs" />
					Jobs
					<span className="u-visuallyhidden">Jobs</span>
				</div>
			</Button>
            <DrawerPanel toggleDrawer={toggleDrawer} setToggleDrawer={setToggleDrawer}>
                <div className="tabs-panel jobs-drawer">
                    <h3 className="tabs-panel__heading">
                        Job List
                    </h3>
                    <AppBar position="static">
                        <Tabs value={tabsPanelValue} onChange={(_, newValue) => setTabsPanelValue(newValue)} aria-label="tabs">
                            {isAdmin && <Tab label="Permissions" {...tabA11yProps(0)} />}
                        </Tabs>
                    </AppBar>
                    
                    {isAdmin && (
                        <TabPanel value={tabsPanelValue} index={0}>
                            <JobsTable onDone={() => setToggleDrawer(!toggleDrawer)} />
                        </TabPanel>
                    )}
                </div>
            </DrawerPanel>
        </span>
    );
};

export default Jobs;
