import React from 'react';
import { FolderMetaDataType } from '../../api';
import './FolderDetails.css';
import { getDateFormatted } from '../../common/getDateFormatted';
import { Location } from '../Location';
import { FolderTags } from '../FolderTags';
interface FolderDetailsPropType {
    item?: FolderMetaDataType;
}

export const FolderDetails: React.FC<FolderDetailsPropType> = ({ item }) => {
    if (!item) return null;

    if (item) {
        const { id, folderName, createdByName, createdDate, modifiedDate, folderNamePart1, folderNamePart2, parentFolderId } = item;
        return (
            <ul className="folder-details-list">
                <li className="folder-details-list__item">
                    <span className="folder-details-list__item-heading">Name:</span>
                    {folderName}
                </li>
                <li className="folder-details-list__item">
                    <span className="folder-details-list__item-heading">Ordnername/Name des Kläger:</span>
                    {folderNamePart1}
                </li>
                <li className="folder-details-list__item">
                    <span className="folder-details-list__item-heading">Aktenzeichen Prozessbevollmächtigter:</span>
                    {folderNamePart2}
                </li>
                <li className="folder-details-list__item">
                    <span className="folder-details-list__item-heading">Author:</span>
                    {createdByName}
                </li>
                <li className="folder-details-list__item">
                    <span className="folder-details-list__item-heading">Date Created:</span>
                    {getDateFormatted(createdDate)}
                </li>
                <li className="folder-details-list__item">
                    <span className="folder-details-list__item-heading">Date Modified:</span>
                    {getDateFormatted(modifiedDate)}
                </li>
                <li className="folder-details-list__item">
                    <span className="folder-details-list__item-heading">ID:</span>
                    {id}
                </li>
                <li className="folder-details-list__item">
                    <span className="folder-details-list__item-heading">Location:</span>
                    <Location />
                </li>
                {(parentFolderId !== 0) && 
                <li className="folder-details-list__item">
                    <span className="folder-details-list__item-heading">Tags:</span>
                    <FolderTags id={id} key={`FolderDetails:FolderTags${id}`}/>
                </li>}
            </ul>
        );
    }
    return <p>Folder / file details unavailable</p>;
};
