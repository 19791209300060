import React from 'react';
import { createPortal } from 'react-dom';
import { usePortal } from '../../hooks/usePortal';

interface PortalProps {
    id: string;
}

const Portal: React.FC<PortalProps>  = (props) => {
    const { id, children } = props;
    const target = usePortal(id);
    if (!target) return null;
    return createPortal(
        children,
        target,
    );
};

export default Portal;
