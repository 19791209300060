import React from 'react';
import { Link } from 'react-router-dom';
import './PageHeader.css';
import { NavigationAtoms } from '@freshfields/react-component-library';
import logo from '../../images/ff-angel-logo.svg';
import logoLarge from '../../images/ff-logo-civil-claims.svg';
import { UserBanner } from '../UserBanner/UserBanner';

const { TopNav } = NavigationAtoms;
const PageHeader: React.FC = () => (
	<TopNav className="bg-freshfields-brand-blue header-nav" showBurgerIcon={false}>
		<div className="c-ds-top-nav-wrapper">
			<Link to="/">
				<img src={logo} alt="short Civil Claims logo" className="c-ds-top-nav-wrapper__small-logo" />
				<img src={logoLarge} alt="Civil Claims logo" className="c-ds-top-nav-wrapper_large-logo" />
			</Link>
			<UserBanner />
		</div>
	</TopNav>
);

export default PageHeader;
