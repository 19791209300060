import React from 'react';
import { useUrlQuery } from '../../common/useUrlQuery';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '70%',
      color: '#1d2b4d',
    }
  })
);

export const SearchTitle = () => {
  const classes = useStyles();
  let query = useUrlQuery();
  
  return (
		<div className={classes.root}>
			<h3>Search results for &lsquo;{query.get('query')}&rsquo;</h3>
		</div>
  );
};
