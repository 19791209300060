import axios, { AxiosInstance } from 'axios';
import { getConfig } from './config';
declare module 'axios' {
  export interface AxiosResponse<T = any> extends Promise<T> { }
}

const axiosConfigFactory = () => {
  const config = getConfig();
  const instance = axios.create({
      baseURL: config.apiBase,
      withCredentials: true
  });
  instance.defaults.headers.common['Access-Control-Allow-Origin'] = config.apiBase;
  return instance;
};

const axiosConfig = (axiosInstance: AxiosInstance) => (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return axiosInstance;
};

export default axiosConfig(axiosConfigFactory());