import React, { FC } from 'react';
import { Portal } from '../Portal';
import Drawer from '@material-ui/core/Drawer';
import './DrawerPanel.css';

interface DrawerPanelProps {
  toggleDrawer: boolean;
  setToggleDrawer: Function;
}

export const DrawerPanel: FC<DrawerPanelProps> = ({
  toggleDrawer,
  setToggleDrawer,
  children,
}) => {
  return (
    <Portal id='root_draw-panel'>
      <Drawer
        anchor="right"
        open={toggleDrawer}
        className="drawer"
        transitionDuration={500}
      >
        <button
          className="drawer-close"
          onClick={() => setToggleDrawer(!toggleDrawer)}
        >
          &times;
        </button>
        {children}
      </Drawer>
    </Portal>
  );
};
