import React, { useContext } from 'react';
import compact from 'lodash/compact';
import { useHistory } from 'react-router-dom';
import { FolderContext, SearchContext, SORT_DIRECTION, SORT_BY } from '../../../../context';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import './Sort.css';


const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(1),
	},
	select: {
        '& Root':{
            position: 'relative',
        },
        
        '& ul': {
            backgroundColor: '#fff',
            padding: '0px',
            
        },
        '& li': {
            fontSize: '12px',
            backgroundColor: '#fff',
            
        },
	},
    
}));


const Sort = () => {
  const classes = useStyles();
  let history = useHistory();
  const [folderState] = useContext(FolderContext);
  const [searchState] = useContext(SearchContext);
  const { activeFolderId, folders, folderTree } = folderState;
  const handleSortByChange = (event: any, element: any) => {
		const order = element.props.value ? `order=${element.props.value}` : null;
		const asc = folders?.asc ? `asc=${folders?.asc}` : null;
		const page = folders?.page ? `page=${folders?.page}` : null;
		const query = compact([order, asc, page]).join('&');
		history.push(`/folder/${activeFolderId}/?${query}`, {
			folderTree,
			searchState,
		});
  };
  const handleSortDirectionChange = (event: any, element: any) => {
		const order = folders?.order ? `order=${folders?.order}` : null;
    const asc = element.props.value
		? `asc=${element.props.value === SORT_DIRECTION.ASCENDING ? 'true' : 'false'}`
		: null;
		const page = folders?.page ? `page=${folders?.page}` : null;
		const query = compact([order, asc, page]).join('&');
		history.push(`/folder/${activeFolderId}/?${query}`, {
			folderTree,
			searchState,
		});
  };
  return (
		<div className="cc-sortBy">
			<FormControl variant="outlined" className={classes.formControl}>
				<InputLabel id="sort-by">Sort by</InputLabel>
				<Select
					labelId="sort-by"
					id="sort-by"
					value={folders?.order ?? SORT_BY.LAST_MODIFIED}
					onChange={handleSortByChange}
					label="Sort by"
					MenuProps={{
						style: {
							border: '1px solid red'
						},
						classes: { 
							paper: classes.select 
						},
						anchorOrigin: {
						  vertical: 'bottom',
						  horizontal: 'left'
						},
						transformOrigin: {
						  vertical: 'top',
						  horizontal: 'left'
						},
						getContentAnchorEl: null
					  }}
					className="cc-sort-dropdown"
				>
					<MenuItem selected classes={{ root: 'cc-dropdown-option', selected: 'cc-selected' }} value={SORT_BY.NAME}>Name</MenuItem>
					<MenuItem selected classes={{ root: 'cc-dropdown-option', selected: 'cc-selected' }} value={SORT_BY.LAST_MODIFIED}>Last Modified</MenuItem>
				</Select>
			</FormControl>
			<FormControl variant="outlined" className={classes.formControl}>
				<Select
					labelId="sort-direction"
					id="sort-direction"
					value={folders?.asc === 'true' ? SORT_DIRECTION.ASCENDING : SORT_DIRECTION.DESCENDING}
					onChange={handleSortDirectionChange}
					MenuProps={{
						classes: { 
							paper: classes.select 
						},
						anchorOrigin: {
						  vertical: 'bottom',
						  horizontal: 'left'
						},
						transformOrigin: {
						  vertical: 'top',
						  horizontal: 'left'
						},
						getContentAnchorEl: null
					  }}
					className="cc-sort-dropdown"
				>
					<MenuItem selected classes={{ root: 'cc-dropdown-option', selected: 'cc-selected' }} value={SORT_DIRECTION.ASCENDING}>Ascending</MenuItem>
					<MenuItem selected classes={{ root: 'cc-dropdown-option', selected: 'cc-selected' }} value={SORT_DIRECTION.DESCENDING}>Descending</MenuItem>
				</Select>
			</FormControl>
		</div>
  );
};

export default Sort;
