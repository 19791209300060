import React, { useState, useContext, useEffect } from 'react';
import { jobs } from '../../api/get';
import { AppContext } from '../../authentication/AppProvider';
import {LoadingSpinner} from '../LodingSpinner';
import { getDateFormatted } from '../../common/getDateFormatted';
import { JobCancel } from '../JobCancel';

interface jobType {
	id: number;
	name: string;
	description: string;
	startDateTime: string;
	endDateTime: string;
	status: string;
	statusMessage: string;
	createdByName: string;
	createdDate: string;
	modifiedByName: string;
	modifiedDate: string;
	duration: string;
}

interface PermissionsTablePropsType {
	onDone: () => void;
}

export const JobsTable: React.FC<PermissionsTablePropsType> = ({ onDone }) => {
	const { user } = useContext(AppContext);
    const [isUpdating, setIsUpdating] = useState(true);
	const [jobsList, setJobsList] = useState<jobType[]>([]);

	const determineDuration = (d: jobType): string =>  {
		console.log(d);
		if (!d.endDateTime) {
			return '';
		}

		const startDate = new Date(d.startDateTime); 
		const endDate = new Date(d.endDateTime); 

		let diffInSeconds = Math.abs(endDate.getTime().valueOf() - startDate.getTime().valueOf()) / 1000;
		// calculate days
		const days = Math.floor(diffInSeconds / 86400);
		diffInSeconds -= days * 86400;
		console.log('calculated days', days);

		// calculate hours
		const hours = Math.floor(diffInSeconds / 3600) % 24;
		diffInSeconds -= hours * 3600;
		console.log('calculated hours', hours);

		// calculate minutes
		const minutes = Math.floor(diffInSeconds / 60) % 60;
		diffInSeconds -= minutes * 60;
		console.log('minutes', minutes);

		let difference = '';
		if (days > 0) {
			//dd days, hh:mm:ss.mm
			difference += (days === 1) ? `${days} day, ` : `${days} days, `;
		}

		difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;
		difference += (minutes === 0 || hours === 1) ? `${minutes} minutes, ` : `${minutes} minutes, `; 
		difference += (diffInSeconds === 0 || diffInSeconds === 1) ? `${Math.round(diffInSeconds)} second` : `${Math.round(diffInSeconds)} seconds`; 

		return difference;
	};

	const refreshJobsList = () => {
		if (user) {
			jobs({ token: user?.access_token})
			.then((response) => {
				let data = response.data.map((d: jobType) => {d.duration = determineDuration(d); return d;});
				setJobsList(data);
				}
			);
		}
		setIsUpdating(false);
	};

	useEffect(() => {
		refreshJobsList();
		// eslint-disable-next-line
	}, [user]);

	return (
		<>			
			{isUpdating && <LoadingSpinner data-testid={'loading-spinner'} />}
			{!isUpdating && 
			<>
			<table className="data-table">
				<thead>
					<tr>
						<th>Job ID</th>
						<th>Start date</th>
						<th>Duration</th>
						<th>Description</th>
						<th>Status</th>
						<th>Status Message</th>
						<th>Created By</th>
						<th>Cancel</th>
					</tr>
				</thead>
				<tbody>
					{jobsList.map((job, index) => {
						return (
							<>
								<tr key={`pt:${job?.name}:${index}`}>
									<td className="text-left">
										<div>
											<p>
												{job?.id}
											</p>
										</div>
									</td>
									<td>
										<div>
											<p>
												{job?.startDateTime ? getDateFormatted(job.startDateTime) : 'N/A'}
											</p>
										</div>
									</td>
									<td>
										<div>
											<p>
												{job?.duration}
											</p>
										</div>
									</td>
									<td>
										<div>
											<p>
												{job?.description}
											</p>
										</div>
									</td>
									<td>
										<div>
											<p>
												{job?.status}
											</p>
										</div>
									</td>
									<td>
										<div>
											<p>
												{job?.statusMessage}
											</p>
										</div>
									</td>
									<td>
										<div>
											<p>
												{job?.createdByName}
											</p>
										</div>
									</td>
									<td>
										{job?.status === 'Started' || job?.status === 'Queued' ? <JobCancel id={job?.id} onDone={() => onDone()} /> : <></> }
									</td>
								</tr>
							</>
						);
					})}
				</tbody>
			</table>
			</>
			}
		</>
	);
};

