import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { getConfig } from '../common/config';
import { ProtectedRoute } from './ProtectedRoute';
import { SignInRedirectCallback } from './SignInRedirectCallback';
import { Routes } from '../components/Routes';

export const AuthenticatedRoutes = () => (
  <Switch>
    <Route
      path={getConfig().oidc.redirectPath}
      exact
      component={() => <SignInRedirectCallback />}
    />

    <ProtectedRoute path="/" component={() => <Routes />} />
  </Switch>
);
