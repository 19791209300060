import React, { useContext } from 'react';
import './ActionBar.css';
import { PERMISSIONS } from '../../enum';
import { ACTIONS } from './actions.enum';
import { SearchContext, FolderContext } from '../../context';
import { Move, Copy, CopyToFolder, Delete, Upload, Download, Info, Share, NewFolder, Restore, DeleteInSearch, RootInfo, ShareInSearch } from './components';
import { Jobs } from './components/Jobs';
import { AppContext } from '../../authentication/AppProvider';
interface ActionBarType {
	actions?: ACTIONS[];
}

export const ActionBar: React.FC<ActionBarType> = ({ actions }) => {
	const [{ showDeleted }] = useContext(SearchContext);
	const [{activeFolder}] = useContext(FolderContext);
	const [{selectedFolderIds, selectedFileIds}] = useContext(FolderContext);
	const { user } = useContext(AppContext);
    const isAdmin = user?.profile?.roles.includes(PERMISSIONS.ADMIN);

	let showRootFolderInfo = false;

	if (!actions) return null;
	
	if(activeFolder){
		if(selectedFolderIds.length > 0 || selectedFileIds.length > 0 ){
			showRootFolderInfo = false;
		} else {
			showRootFolderInfo = activeFolder.parentFolderId === 0;
		}
	}

	return (
		<>
			<div className="action-bar">
				{actions.includes(ACTIONS.NEW_FOLDER) && <NewFolder />}
				{actions.includes(ACTIONS.UPLOAD) && <Upload />}
				{actions.includes(ACTIONS.DOWNLOAD) && <Download />}
				{actions.includes(ACTIONS.RESTORE) && <Restore />}
				{actions.includes(ACTIONS.DOWNLOAD_OR_RESTORE) && (
					<>
						{!showDeleted && <Download />}
						{showDeleted && <Restore />}
					</>
				)}
				{actions.includes(ACTIONS.MOVE) && <Move />}
				{actions.includes(ACTIONS.COPY) && <Copy />}
				{actions.includes(ACTIONS.COPYTOFOLDER) && <CopyToFolder />}
				{actions.includes(ACTIONS.DELETE) && <Delete />}
				{actions.includes(ACTIONS.DELETEINSEARCH) && <DeleteInSearch />}
				{actions.includes(ACTIONS.SHARE) && <Share />}
				{actions.includes(ACTIONS.SHAREINSEARCH) && <ShareInSearch />}
				{isAdmin && actions.includes(ACTIONS.JOBS) && <Jobs />}
				<>
					{showRootFolderInfo && <RootInfo />}
					{!showRootFolderInfo && <Info />}	
				</>
			</div>
		</>
	);
};

export default React.memo(ActionBar);

ActionBar.defaultProps = {
	actions: [
		ACTIONS.NEW_FOLDER,
		ACTIONS.UPLOAD,
		ACTIONS.DOWNLOAD,
		ACTIONS.MOVE,
		ACTIONS.COPY,
		ACTIONS.DELETE,
		ACTIONS.SHARE,
		ACTIONS.INFO,
		ACTIONS.COPYTOFOLDER,
		ACTIONS.JOBS,
		ACTIONS.ROOTINFO
	],
};
