import React, { useState, useEffect, useContext } from 'react';
import isNil from 'lodash/isNil';
import head from 'lodash/head';
import { permissions, removeFolderPermissions, setFolderPermissions } from '../../api';
import { AppContext } from '../../authentication/AppProvider';
import { FolderContext } from '../../context';
import { Button, ToastAtoms } from '@freshfields/react-component-library';
import './PermissionsTable.css';
import groupPermissionsIcon from '../../images/group-permissions.svg';
import {LoadingSpinner} from '../LodingSpinner';
import { Modal } from '../Modal';

interface groupType {
	deleted: boolean;
	groupFolderPermissionId: number;
	groupId: number;
	groupName: string;
	permissionName?: string;
	permissionType?: string;
}
interface PermissionsTablePropsType {
	onDone: () => void;
}

export const PermissionsTable: React.FC<PermissionsTablePropsType> = ({ onDone }) => {
	const { user } = useContext(AppContext);
	const [folderState] = useContext(FolderContext);
	const { selectedFolderIds, selectedFileIds } = folderState;
	const [groups, setGroups] = useState<groupType[]>([]);
    const [isUpdating, setIsUpdating] = useState(true);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [userConfirmation, setUserConfirmation] = useState({ groupName: '', groupId: 0, isChecked: false });
	const { addToast } = ToastAtoms.useToasts();
	// NOTE: workaround for incorrect types on ToastableFeedbackBlock
	const ToastableFeedbackBlock = ToastAtoms.ToastableFeedbackBlock as any;

	const refreshGroupList = () => {
		const selectedFolderId = selectedFolderIds.length > 0 ?
			head(selectedFolderIds) as number : 
			folderState.activeFolderId; //This is for root level folders
		if (selectedFolderId && user) {
			permissions({ token: user?.access_token, id: selectedFolderId })
			.then((response) =>
				setGroups(response.data?.permissions)
			);
		}
		setIsUpdating(false);
	};

	useEffect(() => {
		refreshGroupList();
		// eslint-disable-next-line
	}, [selectedFolderIds, user]);

	const handleSelectOnChange = (groupId: number, hasAccess: boolean) => {
		if(selectedFileIds.length > 0){
			return;
		}
		setIsUpdating(true);
		updatePermissions(groupId, hasAccess);
		setShowConfirmation(false);
		onDone();
	};

	const updatePermissions = (groupId: number, hasAccess: boolean) => {
		const selectedFolderId = selectedFolderIds.length > 0 ?
			head(selectedFolderIds) as number : 
			folderState.activeFolderId; //This is for root level folders
		if (!hasAccess) {
			removeFolderPermissions({ token: user?.access_token, groupId, id: selectedFolderId })
				.then((response) => {
					if(response.status === 202) {
						addToast(
							<ToastableFeedbackBlock
								success
								headerText={<span className="u-heading-xs">Permission change accepted</span>}
								bodyText='This change has been added as a job to do. Please monitor the jobs list.'
							/>,
							{ autoDismiss: true }
						);
					}
					else {
						addToast(
							<ToastableFeedbackBlock
								error
								headerText={<span className="u-heading-xs">Permission change failed</span>}
								bodyText="An error has happened and the request hasn't been accepted."
							/>,
							{ autoDismiss: true }
						);
					}
				})
				.catch((error) => {
					console.error(error);
					addToast(
						<ToastableFeedbackBlock
							error
							headerText={<span className="u-heading-xs">Error</span>}
							bodyText='An error has occurred.'
						/>,
						{ autoDismiss: true }
					);
				});
		} else {
			setFolderPermissions({ token: user?.access_token, groupId, id: selectedFolderId })
				.then((response) => {
					if(response.status === 202) {
						addToast(
							<ToastableFeedbackBlock
								success
								headerText={<span className="u-heading-xs">Permission change accepted</span>}
								bodyText='This change has been added as a job to do. Please monitor the jobs list.'
							/>,
							{ autoDismiss: true }
						);
					}
					else {
						addToast(
							<ToastableFeedbackBlock
								error
								headerText={<span className="u-heading-xs">Permission change failed</span>}
								bodyText="An error has happened and the request hasn't been accepted."
							/>,
							{ autoDismiss: true }
						);
					}
				})
				.catch((error) => {
					console.error(error);
					addToast(
						<ToastableFeedbackBlock
							error
							headerText={<span className="u-heading-xs">Error</span>}
							bodyText='An error has occurred.'
						/>,
						{ autoDismiss: true }
					);
				});
		}
	};

	return (
		<>			
			{isUpdating && <LoadingSpinner data-testid={'loading-spinner'} />}
			{!isUpdating && 
			<>
			<table className="data-table permissions-table">
				<thead>
					<tr>
						<th colSpan={2}>Access Folder</th>
					</tr>
					<tr>
						<th>Group Name</th>
						<th>Access</th>
					</tr>
				</thead>
				<tbody>
					{groups.map((group, index) => {
						const isChecked = isNil(group?.permissionType) ? false : !group?.deleted;
						return (
							<>
								<tr key={`pt:${group?.groupName}:${index}`}>
									<td className="text-left">
										<div>
											<img
												src={groupPermissionsIcon}
												alt="person icon"
												className="float-left mr-2 w-4"
											/>
											<div>
												<p>
													<strong>{group?.groupName}</strong>
												</p>
											</div>
										</div>
									</td>
									<td>
										<input
											type="checkbox"
											id={'cb1-a'}
											data-testid={'cb1-a'}
											onChange={() => {
												setUserConfirmation({groupName: group?.groupName, groupId: group?.groupId, isChecked: isChecked});
												setShowConfirmation(true);
											}}
											checked={isChecked}
										/>
									</td>
								</tr>
							</>
						);
					})}
				</tbody>
			</table>
			</>
			}
			
			<Modal modalIsOpen={showConfirmation} closeModal={() => setShowConfirmation(false)}>
				<div className="modal__header amended-modal-header">
					<h3 className="modal__heading">Change Permissions?</h3>
				</div>
				<div className="modal__body amended-modal-body footer-color">
					<p>Are you sure you want to change permission for <b>{userConfirmation.groupName}</b> to this and all subsequent folders?</p>
				</div>	
				<div className="modal__footer amended-modal-footer">
					<Button
						secondary
						type="button"
						className="modal__cancel-btn"
						onClick={() => setShowConfirmation(false)}>
						Cancel
					</Button>
					<Button
						primary="true"
						type="button"
						onClick={() => handleSelectOnChange(userConfirmation.groupId, !userConfirmation.isChecked)}
					>
						Change
					</Button>
				</div>
			</Modal>
		</>
	);
};
