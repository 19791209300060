import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { FOLDER_ACTIONS } from '../context';
import { getFilesByFolder, FileType } from '../api';
import queryString from 'query-string';

interface FileOrFolderType extends FileType {
    isFile: boolean;
}
const onlyFiles = (item: FileOrFolderType) => item.isFile;
const onlyFolders = (item: FileOrFolderType) => !item.isFile;

export const loadAndDisplaySelectedFolder = ({ id, location, access_token, doFolderStateAction }: any) => {
    const search = queryString.parse(location.search);
    getFilesByFolder({
        id,
        token: access_token,
        order: search?.order,
        asc: search?.asc,
        page: search?.page,
        itemsPerPage: search?.itemsPerPage
    }).then((response) => {
        if (!isNil(response?.data)) {
            const items = get(response, 'data.items', []) as any[];
            const files = items.filter(onlyFiles);
            const folders = items.filter(onlyFolders).map((folder) => ({ ...folder, folderName: folder.name }));

            doFolderStateAction({
                type: FOLDER_ACTIONS.FETCH_FOLDERS,
                payload: {
                    metaData: response?.data.folderMetaData,
                    page: response?.data.currentPage,
                    order: search?.order,
                    asc: search?.asc,
                    itemsPerPage: response?.data.itemsPerPage,
                    totalItems: response?.data.totalItems,
                    totalPages: response?.data.totalPages,
                    files,
                    children: folders,
                    loading: false,
                },
            });
            doFolderStateAction({
                type: FOLDER_ACTIONS.UPDATE_ACTIVE_FOLDER,
                payload: response?.data.folderMetaData,
            });
            doFolderStateAction({
                type: FOLDER_ACTIONS.UPDATE_ACTIVE_FOLDER_ID,
                payload: response?.data.folderMetaData?.id,
            });
            // if a user has selected files or folders, retain the selections
            // doFolderStateAction({
            //     type: FOLDER_ACTIONS.CLEAR_SELECTION,
            // });
        }
    });
};