import React, { useContext } from 'react';
import { ToastAtoms } from '@freshfields/react-component-library';
import { FolderContext } from '../../context/FolderContext';
import { AppContext } from '../../authentication/AppProvider';
import { addFileTag, removeFileTag } from '../../api';
import { FOLDER_ACTIONS } from '../../context';
import Chip from '@material-ui/core/Chip';
import { NewTag } from '../NewTag';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
const { ToastableFeedbackBlock } = ToastAtoms;

interface FileTags {
    id: number;
}

const useStyles = makeStyles((theme) => ({
	root: {
	  display: 'flex',
	//   justifyContent: 'center',
	  flexWrap: 'wrap',
	  listStyle: 'none',
	  padding: theme.spacing(0.5),
	  margin: 0,
	},
	chip: {
	  margin: theme.spacing(0.5),
	},
  }));

const FileTags: React.FC<FileTags> = ({id}) => {
    const { user } = useContext(AppContext);
    const [folderState, doFolderStateAction] = useContext(FolderContext);
    const { addToast } = ToastAtoms.useToasts();
    const classes = useStyles();
    const { folders } = folderState;
    const file = folders.files.find((item:any) => item.id === id);

	if (file === null || file === undefined) {

		return (
			<div>
				<span>tagging for this item unavailable until page refresh</span>
        	</div>			
		);
	}

    const tagList = file.tagsList ?? [];

    const handleNewFileTag = (fileId: number, newTag: string) => {
		// let tags = [newTag, ...tagList];
		const token = user!.access_token;
		addFileTag({
			token,
			id: fileId,
			details: {
				id: fileId,
				tag: newTag,
			},
		})
		.then((response) => {
			const { data: { tags }} = response;
			addToast(
				<ToastableFeedbackBlock
					success
					headerText={<span className="u-heading-xs">File Tag Added</span>}
					bodyText={`${newTag} added to file`}
				/>,
				{ autoDismiss: true }
			);
			doFolderStateAction({
				type: FOLDER_ACTIONS.UPDATE_FILE_TAGS,
				payload: { fileId: fileId, fileTags: tags },
			});
		})
		.catch((error) => {
			addToast(
				<ToastableFeedbackBlock
					error
					headerText={<span className="u-heading-xs">Failed to add the Tag</span>}
				/>,
				{ autoDismiss: true }
			);
		});
	};

    const handleFileTagDelete = (tagToDelete: string) => () => {
		const token = user!.access_token;
		removeFileTag({
			token,
			id: id,
			details: {
				id: id,
				tag: tagToDelete,
			},
		})
		.then((response) => {
			const { data: { tags }} = response;
			addToast(
				<ToastableFeedbackBlock
					success
					headerText={<span className="u-heading-xs">File Tag Deleted</span>}
					bodyText={`${tagToDelete} removed from file ${file.name}`}
				/>,
				{ autoDismiss: true }
			);
			doFolderStateAction({
				type: FOLDER_ACTIONS.UPDATE_FILE_TAGS,
				payload: { fileId: id, fileTags: tags },
			});
		})
		.catch((error) => {
			addToast(
				<ToastableFeedbackBlock
					error
					headerText={<span className="u-heading-xs">Failed to delete the Tag</span>}
				/>,
				{ autoDismiss: true }
			);
		});

	};

    return (
			<div className={classes.root}>
				<NewTag id={id} TagHandler={handleNewFileTag}/>
				{tagList.map((tag: string) => {
					return (
						<Chip
						variant="outlined"
						size="small"
						color="primary"
						clickable
						label={tag}
						className={classes.chip}
						onDelete={handleFileTagDelete(tag)}
						key={`File:${id}:${tag}`}
						deleteIcon={<ClearIcon />}
						/>
					);
				})}
			</div>
    );
};

export default FileTags;