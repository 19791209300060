import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { User } from 'oidc-client';
import { AppConsumer } from './AppProvider';

export interface ProtectedRouteProps {
  component: any;
  exact?: boolean;
  path: string | string[];
}

interface SignInProps {
  getUserOrSignIn: () => void
}

const userIsAuthenticated = (user: User | null) => user && !user.expired;

const SignIn = (props: SignInProps) => {
  props.getUserOrSignIn();
  return null;
};

export const ProtectedRoute = ({ component: Component, exact, ...rest }: ProtectedRouteProps) => (
  <AppConsumer>
    {({ user, getUserOrSignIn, setRedirectPath }) => (
      <Route
        exact={exact}
        render={(props: RouteProps) => {
          setRedirectPath(`${window.location.pathname}${window.location.search}`);
          return userIsAuthenticated(user) ? <Component {...props} /> : <SignIn getUserOrSignIn={getUserOrSignIn} />;
        }}
        {...rest}
      />
    )}
  </AppConsumer>
);
