
import React from 'react';
import question from '../../images/question-mark.svg';
import './UserBanner.style.css';

export const UserBanner: React.FC = () => {
  return (
		<div className="user-banner__container user-profile" data-testid="userBadge">
			<a href="mailto:dieselcivilclaims@freshfields.com?subject=Freshfields%20Civil%20Claims:%20Technical%20Support">
				<img src={question} alt="Help" />
			</a>
		</div>
  );
};
