import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select'; 
import { makeStyles } from '@material-ui/core/styles';
import './NumberResultsSelect.css';

export interface NumberResultsSelectProps {
    onItemsPerPageChange: (arg0: string) => void;
    itemsPerPageValue: string;
}

const useStyles = makeStyles({
    select: {
        '& Root':{
            position: 'relative',
        },
        
        '& ul': {
            backgroundColor: '#fff',
            padding: '0px',
            
        },
        '& li': {
            fontSize: '12px',
            backgroundColor: '#fff',
            
        },
    },
});



const NumberResultsSelect: React.FC<NumberResultsSelectProps> = ({onItemsPerPageChange, itemsPerPageValue}) => {
    const classes = useStyles();
    return ( 
        <>      
         <InputLabel id='number-results'>Rows per page</InputLabel>
            <Select
                labelId='number-results'
                id='number-results'
                value={itemsPerPageValue}
                onChange={(e) => onItemsPerPageChange(e.target.value as string)}
                label='Number of results'
                MenuProps={{
                    classes: { 
                        paper: classes.select 
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    anchorPosition: {
                        top: 10,
                        left: -10, 
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }}
                className="cc-dropdown"
            >
                <MenuItem selected classes={{ root: 'cc-dropdown-option', selected: 'cc-selected' }} value='25'>25</MenuItem>
                <MenuItem selected classes={{ root: 'cc-dropdown-option', selected: 'cc-selected' }} value='50'>50</MenuItem>
                <MenuItem selected classes={{ root: 'cc-dropdown-option', selected: 'cc-selected' }} value='75'>75</MenuItem>
                <MenuItem selected classes={{ root: 'cc-dropdown-option', selected: 'cc-selected' }} value='100'>100</MenuItem>
            </Select>
        </>
    );
};
 
export default NumberResultsSelect;