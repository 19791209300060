import React, { useRef, useState, useContext } from 'react';
import filter from 'lodash/filter';
import { AppContext } from '../../../../authentication/AppProvider';
import { FolderContext, SearchContext, SEARCH_ACTIONS } from '../../../../context';
import { Button, ToastAtoms } from '@freshfields/react-component-library';
import { FolderTree } from '../../../../components/FolderTree';
import { DrawerPanel } from '../../../../components/DrawerPanel';
import { restoreFilesToFolder, restoreFolders } from '../../../../api';
import copyIcon from '../../../../images/copy.svg';

const Restore: React.FC = () => {
	const { user } = useContext(AppContext);
	const [folderState] = useContext(FolderContext);
	const [searchState, doSearchStateAction] = useContext(SearchContext);
	const [showWindow, setShowWindow] = useState(false);
	const target = useRef({ id: -1, folderName: '' });
	const { addToast } = ToastAtoms.useToasts();
	const ToastableFeedbackBlock = ToastAtoms.ToastableFeedbackBlock as any;

	const handleRestoreFolders = () => {
		restoreFolders({
			token: user?.access_token,
			folderIds: folderState.selectedFolderIds,
			targetFolderId: Number(target.current.id),
		})
		.then((response) => {
			const { data } = response;
			setShowWindow(!showWindow);
			const filesWithoutId = filter(searchState.results.files, (file: any) => {
				return !data.restoredIds.includes(file.id);
			});
			doSearchStateAction({
				type: SEARCH_ACTIONS.UPDATE_SEARCH_RESULTS,
				payload: { ...searchState.results, files: filesWithoutId },
			});
			if(response.status === 200) {
				addToast(
					<ToastableFeedbackBlock
						success
						headerText={<span className="u-heading-xs">Restored</span>}
						bodyText="You've successfully restored these file(s)/folder(s), please refresh the page"
					/>,
					{ autoDismiss: true }
				);
			}		})
		.catch((error) => {
			console.error(error);
			addToast(
				<ToastableFeedbackBlock
					error
					headerText={<span className="u-heading-xs">Failed to retore</span>}
					bodyText='Error restoring file/folder'
				/>,
				{ autoDismiss: true }
			);		});
	};

	const handleRestoreFiles = () => {
		restoreFilesToFolder({
			token: user?.access_token,
			fileIds: folderState.selectedFileIds,
			targetFolderId: Number(target.current.id),
		})
			.then((response) => {
				const { data } = response;
				setShowWindow(!showWindow);
				const filesWithoutId = filter(searchState.results.files, (file: any) => {
					return !data.restoredIds.includes(file.id);
				});
				doSearchStateAction({
					type: SEARCH_ACTIONS.UPDATE_SEARCH_RESULTS,
					payload: { ...searchState.results, files: filesWithoutId },
				});
				if(response.status === 200) {
					addToast(
						<ToastableFeedbackBlock
							success
							headerText={<span className="u-heading-xs">Restored</span>}
							bodyText="You've successfully restored these file(s)/folder(s), please refresh the page"
						/>,
						{ autoDismiss: true }
					);
				}		
			})
			.catch((error) => {
				console.error(error);
				addToast(
					<ToastableFeedbackBlock
						error
						headerText={<span className="u-heading-xs">Failed to retore</span>}
						bodyText='Error restoring file/folder'
					/>,
					{ autoDismiss: true }
				);		
			});
	};

	const handleRestore = () => {
		if(folderState.selectedFolderIds && folderState.selectedFolderIds.length > 0) {
			handleRestoreFolders();
		}
		if(folderState.selectedFileIds && folderState.selectedFileIds.length > 0) {
			handleRestoreFiles();
		}		
	};

	return (
		<span className="action-bar__copyDocumentButton action-bar-button">
			<Button
				secondary
				id="restoreDocument"
				type="button"
				data-e2e-testid="actionBarRestoreDocumentButton"
				data-testid="actionBarRestoreDocumentButton"
				onClick={() => setShowWindow(!showWindow)}
			>
				<div className="action-bar-button__content">
					<img className="action-bar-button__icon" src={copyIcon} alt="Restore" />
					Restore
					<span className="u-visuallyhidden">Restore</span>
				</div>
			</Button>
			<DrawerPanel toggleDrawer={showWindow} setToggleDrawer={setShowWindow}>
				<div className="tabs-panel restore-drawer">
					<h3 className="mb-2">Restore</h3>
					<div className="flex mt-8">
						<div className="mr-2">
							<Button
								secondary
								id="cancel"
								type="button"
								data-e2e-testid="actionBarRestoreDocumentDrawButton"
								data-testid="actionBarRestoreDocumentDrawButton"
								onClick={() => setShowWindow(!showWindow)}
							>
								Cancel
							</Button>
						</div>
						<div>
							<Button
								primary="true"
								id="restoreDocument"
								type="button"
								data-e2e-testid="actionBarRestoreDocumentDrawButton"
								data-testid="actionBarRestoreDocumentDrawButton"
								onClick={handleRestore}
							>
								Restore
							</Button>
						</div>
					</div>
					<div className="ml-6 my-6">
						<h5 className="text-sm uppercase mb-3">Working folders</h5>
						<FolderTree
							independent
							onItemClick={(ft) => {
								target.current = ft;
							}}
						/>
					</div>
				</div>
			</DrawerPanel>
		</span>
	);
};

export default Restore;
