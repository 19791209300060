import React from 'react';
import { readableFileSizeFrom } from '../../common/file';
import { FileType } from '../../api';
import './FileDetails.css';
import { getDateFormatted } from '../../common/getDateFormatted';
import { Location } from '../Location';
import { FileTags } from '../FileTags';
interface FileDetailsPropType {
	item?: FileType
}

export const FileDetails: React.FC<FileDetailsPropType> = ({ item }) => {
	if (!item) return null;

	if (item) {
		const { id, name, size, createdByName, createdDate, modifiedDate, type } = item;
		return (
			<ul className="file-details-list">
				<li className="file-details-list__item">
					<span className="file-details-list__item-heading">Name:</span>
					{name}
				</li>
				<li className="file-details-list__item">
					<span className="file-details-list__item-heading">Size:</span>
					{size ? readableFileSizeFrom(size) : 'N/A'}
				</li>
				<li className="file-details-list__item">
					<span className="file-details-list__item-heading">Author:</span>
					{createdByName}
				</li>
				<li className="file-details-list__item">
					<span className="file-details-list__item-heading">Date Created:</span>
					{getDateFormatted(createdDate)}
				</li>
				<li className="file-details-list__item">
					<span className="file-details-list__item-heading">Date Modified:</span>
					{getDateFormatted(modifiedDate)}
				</li>
				<li className="file-details-list__item">
					<span className="file-details-list__item-heading">ID:</span>
					{id}
				</li>
				<li className="file-details-list__item">
					<span className="file-details-list__item-heading">Location:</span>
					<Location />
				</li>
				<li className="file-details-list__item">
					<span className="file-details-list__item-heading">Extension:</span>
					{type}
				</li>
				<li className="file-details-list__item">
					<span className="file-details-list__item-heading">Tags:</span>
					<FileTags id={id} key={`FileDetails:FileTags${id}`}/>
				</li>
			</ul>
		);
	}
	return <p>Folder / file details unavailable</p>;
};
