import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#1d2b4d',
    },
  })
);

export const LoadingSpinner = () => {
  const classes = useStyles();

  return (
		<div className="fade-in">
			<div className={classes.root}>
				<CircularProgress color="inherit" />
			</div>
		</div>
  );
};
