import axiosWithConfig from '../common/axiosWithConfig';
import { RequestSelectedType } from './api.types';

export const deleteFileItem = async ({ token, selectedIds }: RequestSelectedType) => {
	if (!token || selectedIds.length === 0) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.delete('/v1/files/bulkdelete', {data: {Ids: selectedIds}});
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const deleteFolderItem = async ({ token, selectedIds }: RequestSelectedType) => {
	if (!token || selectedIds.length === 0) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.delete('v1/folders/bulkdelete', {data: {Ids: selectedIds}});
	} catch (error) {
		console.error(error);
		return error;
	}
};

