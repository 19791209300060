import React, { useEffect, useContext, useRef } from 'react';
import { Modal } from '../Modal';
import { AppContext } from '../../authentication/AppProvider';
import { Portal } from '../Portal';
import './FileViewer.css';
import { download } from '../../api';
import { isIeOrLegacyEdge } from '../../common/isIeOrLegacyEdge';

interface FileViewerPropsType {
	id: number;
	onClose: () => void;
}

const FileViewer: React.FC<FileViewerPropsType> = ({ id, onClose }) => {
	const { user } = useContext(AppContext);
	const iframeEl = useRef<HTMLIFrameElement>(null);
	// const isIE11 = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
	const isIE11 = isIeOrLegacyEdge();

	useEffect(() => {
		download({
			token: user ?.access_token,
			selectedIds: [id],
		}).then(({ data }) => {
			// TODO: detect what type to display image/jpeg, application/pdf etc
			const blob = new Blob([data], { type: 'application/pdf' });
			const blobURL =
				window.URL && window.URL.createObjectURL
					? window.URL.createObjectURL(blob)
					: window.webkitURL.createObjectURL(blob);
			if (iframeEl.current) {
				if (isIE11) {
					iframeEl.current.src = `${process.env.PUBLIC_URL}/pdf-js/web/viewer.html?file=${blobURL}&disableFontFace=true`;
				} else {
					iframeEl.current.src = blobURL;
				}
			}
		});
	}, [user, id, isIE11]);

	return (
		<Portal id="root_fileview">
			<Modal modalIsOpen={true} closeModal={onClose}>
				<iframe title="File Preview" ref={iframeEl} width="90%" height="90%" className="viewer_iframe" />
			</Modal>
		</Portal>
	);
};

export default FileViewer;