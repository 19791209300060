import React, { useContext } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AppConsumer, AppContext } from '../../authentication/AppProvider';
import { ContextFromRouterState } from '../ContextFromRouterState';
import { PageHeader } from '../PageHeader';
import { ActionBar, ACTIONS } from '../ActionBar';
import { SideNav } from '../SideNav/SideNav';
import { FolderTree } from '../../components/FolderTree';
import { Breadcrumbs } from '../Breadcrumbs';
import { FileTable } from '../FileTable';
import { SearchTable } from '../SearchTable';
import { SearchTitle } from '../SearchTitle';
import { SearchInput } from '../SearchInput';
import { Banner } from '../Banner';
import './Default.css';
import { FolderHubGroup } from '../FolderHubGroup';
import { FolderHubSetup } from '../FolderHubSetup';

const Default = () => {
    const { user } = useContext(AppContext);
    const username = user ? user.profile.name! : 'User Not Found';
	const location = useLocation();
    const { searchState } = location.state ? location.state as any : {searchState: {}};

    return (
        <AppConsumer>
            {({ signInSilent }) => (
                <>
                    <FolderHubSetup />
                    <div className="App">
                        <ContextFromRouterState />
                        <FolderHubGroup />
                        <PageHeader />
                        <div className="civil-claims-container">
                            <SideNav>
                                <div className="text-center mb-10 mt-5">
                                    <h3>{username}</h3>
                                    <p>Freshfields</p>
                                </div>
                                <h5 className="mb-2">Civil Claims</h5>
                                <FolderTree />
                            </SideNav>
                            <div className="civil-claims-container__content">                      
                                <Switch>
                                    <Route
                                        path="/search"
                                        component={() => <ActionBar actions={searchState?.showDeleted ? [ACTIONS.DOWNLOAD_OR_RESTORE] :
                                            [ACTIONS.DOWNLOAD_OR_RESTORE, ACTIONS.COPYTOFOLDER, ACTIONS.MOVE, ACTIONS.DELETEINSEARCH , ACTIONS.SHAREINSEARCH, ACTIONS.INFO]} />}
                                    />
                                    <ActionBar />
                                </Switch>
                                <Banner />

                                <div className="civil-claims-container__content-inner">
                                    <div className="breadcrumbs-container">
                                        <Switch>
                                            <Route path="/search" component={() => <SearchTitle />} />
                                            <Breadcrumbs />
                                        </Switch>
                                    </div>
                                    <div className="search-container">
                                        <div className="ml-auto">
                                            <SearchInput />
                                        </div>
                                    </div>
                                    <div className="civil-claims-contianer__data-table">
                                        <Switch>
                                            <Route path="/search" component={() => <SearchTable />} />
                                            <FileTable />
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </AppConsumer>
    );
};

export default Default;
