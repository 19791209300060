import React, { useContext } from 'react';
import compact from 'lodash/compact';
import { useLocation, useHistory } from 'react-router-dom';
import ControlledPagination from '@material-ui/lab/Pagination';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SearchContext } from '../../context';
import queryString from 'query-string';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '2rem',
    },
  })
);

export const SearchPagination = () => {
  let history = useHistory();
  const [searchState] = useContext(SearchContext);
  const classes = useStyles();
  let pageQuery = 'page=1';
  let location = useLocation();
  const parsedQueryString = queryString.parse(location.search);
  let page = Number(parsedQueryString.page) ?? 1;
  const numberOfPages = 
    searchState.results.totalCount % searchState.results.itemsPerPage === 0 ? 
    Math.floor(searchState.results.totalCount / searchState.results.itemsPerPage) : 
    Math.floor(searchState.results.totalCount / searchState.results.itemsPerPage + 1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const term = `query=${searchState.term}`;
    const deleted = `deleted=${searchState.showDeleted}`;
    const scopeId = `scopeId=${searchState.scopeId}`;
    const isScoped = `scoped=${searchState.isScoped}`;
    pageQuery = value ? `page=${value}` : '';
    page = Number(value);
    const itemsPerPage = searchState.results.itemsPerPage ? `itemsPerPage=${searchState.results.itemsPerPage}` : 'itemsPerPage=25';
    const query = compact([term, deleted, scopeId, isScoped, pageQuery, itemsPerPage]).join('&');
		history.push(`/search?${query}`, {
			searchState,
		});
	};

  if (searchState.totalPages === 1) {
		return null;
  }

  return (
    <div className="pagination-next-page">
        <div className={classes.root}>
          <ControlledPagination count={numberOfPages} page={page} onChange={handleChange} color='primary' />     
        </div>
    </div>
		
  );
};
