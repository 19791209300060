import React from 'react';

interface State {
  caughtError: boolean
}

export default class ErrorBoundary extends React.Component<any, State>{
  constructor(props: any, state: any){
    super(props, state);
    this.state = {
      caughtError: false
    };
  }

  static getDerivedStateFromError() {
    return { caughtError: true };
  }

  render() {
    if (this.state.caughtError) {return (
		<div id="rootError">
			<h1>
				You have encountered an issue. Please refresh your page and try again, if your problem persists please
				contact support on 
				<a href="mailto:dieselcivilclaims@freshfields.com">dieselcivilclaims@freshfields.com</a>
			</h1>
		</div>
	);
  }
    return this.props.children;
  }
}