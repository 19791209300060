import React, { useRef, useState, useContext } from 'react';
import get from 'lodash/get';
import filter from 'lodash/filter';
import { sortFolderListing } from '../../../../common/sortFolderListing';
import { AppContext } from '../../../../authentication/AppProvider';
import { FolderContext, FOLDER_ACTIONS, FolderMetaDataType } from '../../../../context';
import { Button, ToastAtoms } from '@freshfields/react-component-library';
import { FolderTree } from '../../../../components/FolderTree';
import { DrawerPanel } from '../../../../components/DrawerPanel';
import { moveFilesToFolder, moveFolder } from '../../../../api';
import copyIcon from '../../../../images/copy.svg';
import { removeNode, find } from 'react-sortable-tree';
import { getNodeKey } from '../../../FolderTree';
import { FolderRetriever } from '../../../FolderRetriever';
import { Modal } from '../../../Modal';

const Move: React.FC = () => {
	const { user } = useContext(AppContext);
	const [folderState, doFolderStateAction] = useContext(FolderContext);
	const [showWindow, setShowWindow] = useState(false);
	const target = useRef({ id: -1, folderName: '' });
	const { addToast } = ToastAtoms.useToasts();
	// NOTE: workaround for incorrect types on ToastableFeedbackBlock
	const ToastableFeedbackBlock = ToastAtoms.ToastableFeedbackBlock as any;
	const [isMoving, setIsMoving] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [howToDoMessage, sethowToDoMessage] = useState('... or search via the folder tree');

	const searchId = ({ node, searchQuery }: any) => {
		return node.id === searchQuery;
	};

	const refreshView= () => {
		
        const folderChildrenWithoutId = filter(
            folderState.folders?.children,
            (folder: FolderMetaDataType) => !folderState.selectedFolderIds.includes(folder?.id)
        );
        doFolderStateAction({
            type: FOLDER_ACTIONS.FETCH_FOLDERS,
            payload: { ...folderState.folders, children: folderChildrenWithoutId },
        });

        doFolderStateAction({
            type: FOLDER_ACTIONS.CLEAR_SELECTION,
        });

        // Mark: Remove folder tree
		const { matches } = find({
			treeData: folderState?.folderTree?.data,
			searchQuery: Number(folderState.selectedFolderIds[0]),
			searchMethod: searchId,
			getNodeKey,
		});
		if (matches.length > 0) {
			matches.forEach((match) => {
				const treeData = removeNode({
					treeData: folderState?.folderTree?.data,
					ignoreCollapsed: true,
					path: match.path,
					getNodeKey,
				});
				if (treeData) {
					doFolderStateAction({
						type: FOLDER_ACTIONS.UPDATE_FOLDER_TREE,
						payload: {
							...folderState?.folderTree?.data,
							data: treeData?.treeData,
						},
					});
				}
			});
		}
    };

	const handleMoveFolders = () => {
		moveFolder({
			token: get(user, 'access_token'),
			folderId: folderState.selectedFolderIds[0],
			destinationFolderId: Number(target.current.id)
		})
			.then((response) => {
				setShowWindow(!showWindow);
                if(response.status === 200) {
                    addToast(
                        <ToastableFeedbackBlock
                            success
                            headerText={<span className="u-heading-xs">Moved</span>}
                            bodyText="You've successfully moved these file(s)/folder(s)"
                        />,
                        { autoDismiss: true }
                    );
                }
                refreshView();
				setIsMoving(false);
			})
			.catch((error) => {
				console.error(error);
				addToast(
					<ToastableFeedbackBlock
						error
						headerText={<span className="u-heading-xs">Failed to move</span>}
						bodyText='Please try again or check your permissions'
					/>,
					{ autoDismiss: true }
				);
				setIsMoving(false);
			});
	};

	const handleMoveFiles = () => {
		moveFilesToFolder({
			token: get(user, 'access_token'),
			fileIds: folderState.selectedFileIds,
			folderId: Number(target.current.id),
		})
			.then(({ data }) => {
				// TODO: Validation
				setShowWindow(!showWindow);
				const filesWithoutId = filter(folderState.folders.files, (file: any) => {
					return !data.movedIds.includes(file.id);
				});
				doFolderStateAction({
					type: FOLDER_ACTIONS.FETCH_FILES,
					payload: sortFolderListing([...filesWithoutId]),
				});
				doFolderStateAction({
					type: FOLDER_ACTIONS.UPDATE_ACTIVE_FOLDER_ID,
					payload: null,
				});
				doFolderStateAction({
					type: FOLDER_ACTIONS.CLEAR_SELECTION,
				});
				addToast(
					<ToastableFeedbackBlock
						success
						headerText={<span className="u-heading-xs">Moved</span>}
						bodyText="You've successfully moved these file(s)/folder(s)"
					/>,
					{ autoDismiss: true }
				);
			})
			.catch((error) => {
				console.error(error);
				addToast(
					<ToastableFeedbackBlock
						error
						headerText={<span className="u-heading-xs">Failed to move</span>}
						bodyText='Please try again or check your permissions'
					/>,
					{ autoDismiss: true }
				);
			});
	};

	const handleMove = () => {

		setShowConfirmation(false);

		if (target.current.id === -1) {
			return;
		}

		setIsMoving(true);
		if(folderState.selectedFolderIds && folderState.selectedFolderIds.length > 0) {
			handleMoveFolders();
		}
		if(folderState.selectedFileIds && folderState.selectedFileIds.length > 0) {
			handleMoveFiles();
		}
		setIsMoving(false);
	};

	const confirmAction = () => {
		if (target.current.id === -1) {
			setShowWindow(!showWindow);
			addToast(
				<ToastableFeedbackBlock
					error
					headerText={<span className="u-heading-xs">Please select a destination folder</span>}
					bodyText='Select a folder from the folder tree, or via search'
				/>,
				{ autoDismiss: true }
			);
			return;
		}

		setShowConfirmation(true);

	};

	const handleSearchFolderSelection = (folderId: number, folderName: string) => {
		target.current = { id: folderId, folderName: folderName };
	};

	const handleSearchFolderSelectionCancelled = () => {
		target.current = { id: -1, folderName: '' };
	};

	const showStandardHowToMessage = () => {
		const msg = '... or search via the folder tree';
		sethowToDoMessage(msg);

	};

	const showExtendedHowToMessage = () => {
		const msg = 'more than 50 results, be more specific if you do not find your folder ... or search via the folder tree';
		sethowToDoMessage(msg);

	};

	const handleOnNotifyAdditionalResults = () => {
		showExtendedHowToMessage();
	};

	const handleOnClearNotifyAdditionalResults = () => {
		showStandardHowToMessage();
	};

	const oneFolderSelected = folderState.selectedFolderIds.length === 1;
	const isFolderSelected = folderState.selectedFolderIds.length > 0;
	const isFileSelected = folderState.selectedFileIds.length > 0;
	const enableButton = (oneFolderSelected && !isFileSelected) || (!isFolderSelected && isFileSelected);

	const closeMe = () => {
		showStandardHowToMessage();
		setShowWindow(false);
	};

	return (
		<span className="action-bar__copyDocumentButton action-bar-button">
			<Button
				secondary
				id="moveDocument"
				type="button"
				data-e2e-testid="actionBarMoveDocumentButton"
				data-testid="actionBarMoveDocumentButton"
				onClick={() => setShowWindow(!showWindow)}
				disabled={!enableButton}
			>
				<div className="action-bar-button__content">
					<img className="action-bar-button__icon" src={copyIcon} alt="Move" />
					Move
					<span className="u-visuallyhidden">Move</span>
				</div>
			</Button>
			<DrawerPanel toggleDrawer={showWindow} setToggleDrawer={() => closeMe()}>
				<div className="default-drawer">
					<h3 className="mb-2">Move</h3>
					<>
						<div className="panel-search-card">
							<FolderRetriever OnClearSelectionHandler={handleSearchFolderSelectionCancelled} OnSelectionHandler={handleSearchFolderSelection}  
								OnNotifyAdditionalResults={handleOnNotifyAdditionalResults} OnClearNotifyAdditionalResults={handleOnClearNotifyAdditionalResults}/>
							<Button
								primary="true"
								id="moveDocument1"
								type="button"
								data-e2e-testid="actionBarMoveDocumentDrawButton"
								data-testid="actionBarMoveDocumentDrawButton"
								onClick={confirmAction}
								loading={isMoving}
								disabled={isMoving}
							>
								{!isMoving && <>Move</>}
								{isMoving && <>Moving</>}
							</Button>
						</div>
						<p className="panel-card-header">{howToDoMessage}</p>
						<div className="panel-search-card">
							<div className="folder-tree-header">
								<h5 className="text-sm uppercase mb-3">Folder Tree</h5>
							</div>
							<div className="ml-6 my-6">
								<FolderTree
									independent
									onItemClick={(ft) => {
										target.current = ft;
									}}
								/>
							</div>
							<Button
								primary="true"
								id="moveDocument2"
								type="button"
								data-e2e-testid="actionBarMoveDocumentDrawButton"
								data-testid="actionBarMoveDocumentDrawButton"
								onClick={confirmAction}
								loading={isMoving}
								disabled={isMoving}
							>
								{!isMoving && <>Move</>}
								{isMoving && <>Moving</>}
							</Button>
						</div>
					</>
				</div>
			</DrawerPanel>

			<Modal modalIsOpen={showConfirmation} closeModal={() => setShowConfirmation(false)}>
				<div className="modal__header amended-modal-header">
					<h3 className="modal__heading">Move file(s) / folder(s)?</h3>
				</div>
				<div className="modal__body amended-modal-body footer-color">
					<p>Are you sure you want to move these file(s) / folder(s)?</p>
				</div>	
				<div className="modal__footer amended-modal-footer">
					<Button
						secondary
						type="button"
						className="modal__cancel-btn"
						onClick={() => setShowConfirmation(false)}>
						Cancel
					</Button>
					<Button
							primary="true"
							type="button"
							onClick={() => handleMove()}
						>
							Move
						</Button>
					</div>
			</Modal>
		</span>
	);
};

export default Move;
