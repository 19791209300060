import React, { FC } from 'react';
import MuiModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Portal } from '../Portal';
import './Modal.css';

interface ModalProps {
  children: React.ReactNode;
  modalIsOpen?: boolean;
  closeModal?: any;
  classes?: string;
}

export const Modal: FC<ModalProps> = ({
  children,
  modalIsOpen = false,
  closeModal,
  classes,
}) => {
  return (
		<Portal id="root_modal">
			<MuiModal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className="modal"
				open={modalIsOpen}
				onClose={closeModal}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={modalIsOpen}>
					<div className={`modal__inner ${classes}`}>
						<div>{children}</div>
					</div>
				</Fade>
			</MuiModal>
		</Portal>
  );
};
