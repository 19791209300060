import Breadcrumb from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FolderContext } from '../../context/FolderContext';

interface BreadcrumbType {
	folderId: number;
	folderName: string;
}


const useStyles = makeStyles(() =>
	createStyles({
		root: {
			maxWidth: '100%',
			position: 'relative',
  			borderTop: '1px solid #EBEBEB',
  			borderBottom: '1px solid #EBEBEB',
  			padding: '8px',
  			display: 'block',
  			margin: '0px 0 20px 0',
		}
	})
);

export const Breadcrumbs = () => {
	const [folderState] = useContext(FolderContext);
	const { breadcrumbs, folderTree } = folderState;
	const length = breadcrumbs.length;
	const folderLocationPaths = breadcrumbs.slice(0, length - 1);
	const lastFolder = breadcrumbs[length - 1];

	const classes = useStyles();
	let history = useHistory();

	const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		let href = event.currentTarget.getAttribute('data-route');
		history.push(href ? href : '', { folderTree });
	};

	if (!lastFolder) {
		return null;
	}

	return (
		<div className="cc-breadcrumbs-container">
			<div className={classes.root}>
				<Breadcrumb maxItems={5} aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" className="cc-breadcrumbs" />}>
					{
						folderLocationPaths.map((breadcrumb: BreadcrumbType) => {
							return (
								<Link color="inherit" href="#" data-route={`/folder/${breadcrumb?.folderId}`} onClick={handleClick} key={breadcrumb?.folderId}>
									{breadcrumb?.folderName}
								</Link>
							);
						})
					}
					<Typography color="textPrimary">{lastFolder.folderName}</Typography>
				</Breadcrumb>
			</div>
		</div>
	);
};
