import React, { useState, useContext } from 'react';
import get from 'lodash/get';
import { sortFolderListing } from '../../../../common/sortFolderListing';
import { AppContext } from '../../../../authentication/AppProvider';
import { FolderContext, FOLDER_ACTIONS } from '../../../../context/FolderContext';
import { Button, ToastAtoms } from '@freshfields/react-component-library';
import { copyFilesToFolder } from '../../../../api';
import copyIcon from '../../../../images/copy.svg';

const Copy: React.FC = () => {
	const { user } = useContext(AppContext);
	const [folderState, doFolderStateAction] = useContext(FolderContext);
	const [showWindow, setShowWindow] = useState(false);
	const { addToast } = ToastAtoms.useToasts();
	// NOTE: workaround for incorrect types on ToastableFeedbackBlock
	const ToastableFeedbackBlock = ToastAtoms.ToastableFeedbackBlock as any;
	const [isCopying, setIsCopying] = useState(false);

	const handleCopyFile = () => {
		setIsCopying(true);
		copyFilesToFolder({
			token: get(user, 'access_token'),
			fileIds: folderState.selectedFileIds,
			folderId: folderState.activeFolderId,
		})
			.then(({ data }) => {
				// TODO: Validation
				setShowWindow(!showWindow);
				doFolderStateAction({
					type: FOLDER_ACTIONS.FETCH_FILES,
					payload: sortFolderListing(sortFolderListing([...folderState.folders.files, ...data])),
				});
				doFolderStateAction({
					type: FOLDER_ACTIONS.CLEAR_SELECTION,
				});
				addToast(
					<ToastableFeedbackBlock
						success
						headerText={<span className="u-heading-xs">File(s) copied</span>}
						bodyText='Your file(s) have been copied to the selected folder'
					/>,
					{ autoDismiss: true }
				);
				setIsCopying(false);
			})
			.catch((error) => {
				console.error(error);
				addToast(
					<ToastableFeedbackBlock
						error
						headerText={<span className="u-heading-xs">Copy failed</span>}
						bodyText='An error occurred while copying your file(s)'
					/>,
					{ autoDismiss: true }
				);
				setIsCopying(false);
			});
	};

	const isFolderSelected = folderState.selectedFolderIds.length > 0;
	const isFileSelected  = folderState.selectedFileIds.length > 0;
	const enableButton = (!isFolderSelected && isFileSelected);
	
	return (
		<span className="action-bar__copyDocumentButton action-bar-button">
			<Button
				secondary
				id="copyDocument"
				type="button"
				data-e2e-testid="actionBarCopyDocumentButton"
				data-testid="actionBarCopyDocumentButton"
				onClick={handleCopyFile}
				loading={isCopying}
				disabled={isCopying || !enableButton}
			>
				<div className="action-bar-button__content">
					<img className="action-bar-button__icon" src={copyIcon} alt="Copy" />
					{!isCopying && <>Copy</>}
					{isCopying && <>Copying</>}
					<span className="u-visuallyhidden">Copy</span>
				</div>
			</Button>
		</span>
	);
};

export default Copy;
