import createUserManager from './createUserManager';
import { getConfig } from '../common/config';
import Oidc from 'oidc-client';


const getUrlBase = () =>
  `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

const authConfig = () => {
  const config = getConfig();

  if (config.environment === 'prod') {
    Oidc.Log.level = Oidc.Log.NONE;
  }
  else {
    Oidc.Log.logger = console;
    Oidc.Log.level = config.environment === 'local' ? Oidc.Log.DEBUG : Oidc.Log.INFO;
  }

  const { oidc } = config;

  return {
    client_id: oidc.clientId,
    redirect_uri: `${getUrlBase()}${oidc.redirectPath}`,
    response_type: oidc.responseType,
    authority: oidc.authority,
    scope: oidc.scope,
    silent_redirect_uri: `${getUrlBase()}${oidc.silentRenewPath}`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: false,
    metadataUrl: oidc.metadataUrl,
  };
};

export default createUserManager(authConfig());
