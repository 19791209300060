import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export const NotFoundPage: FC = () => {
  return (
    <div className="bg-white p-8 pb-32" data-testid="pageNotFound" data-e2e-testid="pageNotFound">
      <h1>Page not found</h1>
      <div className="my-4">
        {'Click '}
        <Link to="/" className="underline">
          {'here'}
        </Link>
        {' to go back to the home page'}
      </div>
    </div>
  );
};
