import last from 'lodash/last';

interface CreateDownloadLinkType {
	data: string | ArrayBuffer | ArrayBufferView | Blob;
	filename: string;
	mime?: string;
	bom?: string;
}

export const createFilename = ({ selectedFolderOrFileName, selectedFileIds }: any): string => {
	const lastSelectName = String(last(selectedFolderOrFileName));
	return selectedFileIds.length === 1 ? lastSelectName : `DieselClaims_${Date.now()}.zip`;
};

export const fileDownload = ({ data, filename, mime, bom }:CreateDownloadLinkType) => {
	var blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
	var blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
	if (typeof window.navigator.msSaveBlob !== 'undefined') {
		// IE workaround for "HTML7007: One or more blob URLs were
		// revoked by closing the blob for which they were created.
		// These URLs will no longer resolve as the data backing
		// the URL has been freed."
		window.navigator.msSaveBlob(blob, filename);
	} else {
		var blobURL =
			window.URL && window.URL.createObjectURL
				? window.URL.createObjectURL(blob)
				: window.webkitURL.createObjectURL(blob);
		var tempLink = document.createElement('a');
		tempLink.style.display = 'none';
		tempLink.href = blobURL;
		tempLink.setAttribute('download', filename);
		if (typeof tempLink.download === 'undefined') {
			tempLink.setAttribute('target', '_blank');
		}
		document.body.appendChild(tempLink);
		tempLink.click();
		setTimeout(function () {
			document.body.removeChild(tempLink);
			window.URL.revokeObjectURL(blobURL);
		}, 200);
	}
};

export const createMailtoLink = ({ selectedFolderOrFileName, selectedFileIds, name }: any) => {
	let currentHref = window.location.href;
	let currentSearch = window.location.search;
	let newHref = currentHref.replace(currentSearch, '');
	let seperator = newHref.endsWith('/') ? '' : '/';
	const link = document.createElement('a');
	const lastSelectName = selectedFolderOrFileName[selectedFolderOrFileName.length - 1];
	const fileName = selectedFileIds.length === 1 ? lastSelectName : `DieselClaims_${Date.now()}.zip`;
	const fileDownload = (type = 'application/octet-stream') => {
		const currentQueryWithDownload = `${newHref}${seperator}download/${selectedFileIds.join('-')}${currentSearch}`;
		return encodeURI(currentQueryWithDownload);
	};
	link.href = `
		mailto:your@recipient.com?
		subject=${name}%20has%20shared%20a%20Freshfields%20Civil%20Claims%20
		document: ${encodeURIComponent(fileName)}&body=${fileDownload()}
	`;
	link.setAttribute('download', createFilename({ selectedFileIds, selectedFolderOrFileName }));
	return link;
};

export const createMailtoLinkFromSearch = ({ selectedFolderOrFileName, selectedFileIds, name, parentFolderId }: any) => {
	let currentHref = window.location.href;
	let currentSearch = window.location.search;
	let newHref = currentHref.replace(currentSearch, '');
	newHref = newHref.replace('search', `folder/${parentFolderId}`);
	let seperator = newHref.endsWith('/') ? '' : '/';
	const link = document.createElement('a');
	const lastSelectName = selectedFolderOrFileName[selectedFolderOrFileName.length - 1];
	const fileName = selectedFileIds.length === 1 ? lastSelectName : `DieselClaims_${Date.now()}.zip`;

	const fileDownload = (type = 'application/octet-stream') => {
		const currentQueryWithDownload = `${newHref}${seperator}download/${selectedFileIds.join('-')}`;
		return encodeURI(currentQueryWithDownload);
	};
	link.href = `
		mailto:your@recipient.com?
		subject=${name}%20has%20shared%20a%20Freshfields%20Civil%20Claims%20
		document: ${encodeURIComponent(fileName)}&body=${fileDownload()}
	`;
	link.setAttribute('download', createFilename({ selectedFileIds, selectedFolderOrFileName }));
	return link;
};
