import React, { useState, useContext } from 'react';
import { ToastAtoms } from '@freshfields/react-component-library';
import { readableFileSizeFrom } from '../../common/file';
import { FolderContext } from '../../context/FolderContext';
import './Table.css';
import folderIcon from '../../images/folder.svg';
import fileGeneric from '../../images/file-generic.svg';
import filePdf from '../../images/pdf.svg';
import { FOLDER_ACTIONS, SearchStateType } from '../../context';
import { useHistory } from 'react-router-dom';
import { FileViewer } from '../FileViewer';
import { getDateFormatted } from '../../common/getDateFormatted';
import { FileType, FolderMetaDataType } from '../../api';
import { FolderTags } from '../FolderTags';
import { FileTags } from '../FileTags';

export enum COLUMN {
	INPUT,
	ICON,
	NAME,
	SIZE,
	AUTHOR,
	MODIFIED,
	STATUS,
	EXTENSION,
	TAGS,
}

enum FILE {
	PDF = 'pdf'
}

interface TablePropsType {
	columns?: COLUMN[];
	results: { folders: FolderMetaDataType[]; files: FileType[] };
	searchState?: SearchStateType;
}
interface isCheckedType {
	lookFor: number;
	within: number[];
}

const isChecked = ({ lookFor: id, within: items }: isCheckedType) => {
	return items.includes(id);
};

const Table: React.FC<TablePropsType> = ({ columns, results, searchState }) => {
	let history = useHistory();
	const { addToast } = ToastAtoms.useToasts();
	// NOTE: workaround for incorrect types on ToastableFeedbackBlock
	const ToastableFeedbackBlock = ToastAtoms.ToastableFeedbackBlock as any;
	const [showFileViewId, setShowFileViewId] = useState(-1);
	const [folderState, doFolderStateAction] = useContext(FolderContext);
	const { selectedFileIds, selectedFolderIds, folderTree } = folderState;
	const [ checkAll, setCheckAll ] = useState(false);
	const isSelectAllChecked = () => {
		return selectedFolderIds.length === results.folders.length && selectedFileIds.length === results.files.length;
	};
	
	const handleCheckAll = (selectAll: boolean) => {
		setCheckAll(selectAll);
		doFolderStateAction({
			type: FOLDER_ACTIONS.CLEAR_SELECTION,
		});

		if(!selectAll){
			return;
		}

		results.folders.forEach((folderItem) => {
			doFolderStateAction({
				type: FOLDER_ACTIONS.SELECTED_FOLDER_ID,
				payload: folderItem.id,
			});
			doFolderStateAction({
				type: FOLDER_ACTIONS.SELECTED_FOLDER_OR_FILE_NAME,
				payload: folderItem.folderName,
			});
		});
		results.files.forEach((fileItem) => {
			if(!fileItem.quarantined && !fileItem.pending) {
				doFolderStateAction({
					type: FOLDER_ACTIONS.SELECTED_FILE_ID,
					payload: fileItem.id,
				});
				doFolderStateAction({
					type: FOLDER_ACTIONS.SELECTED_FOLDER_OR_FILE_NAME,
					payload: `${fileItem.name}.${fileItem.type}`,
				});
			}
		});
	};

	const handleShiftKeySelect = (id: number, isFolder: boolean) => {
		if(isFolder) {
			//Don't care about files if you shift + click on a folder
			handleShiftKeySelectFolder(id);
		} else {
			if(selectedFolderIds.length > 0) {
				//Only here if the top click is on a folder and shift + click is on a file
				const firstIndex = results.folders.map(function(e) { return e.id; }).indexOf(selectedFolderIds[0]);
				const lastIndex = results.folders.map(function(e) { return e.id; }).indexOf(results.folders[results.folders.length - 1].id);
				handleUpdateSelectedFoldersShift(firstIndex, lastIndex);
			}
			handleShiftKeySelectFile(id);
		}
	};

	const handleShiftKeySelectFolder = (selectedFolderId: number) => {
		const firstIndex = results.folders.map(function(e) { return e.id; }).indexOf(selectedFolderIds[0]);
		const lastIndex = results.folders.map(function(e) { return e.id; }).indexOf(selectedFolderId) - 1;
		handleUpdateSelectedFoldersShift(firstIndex, lastIndex);
	};

	const handleUpdateSelectedFoldersShift = (firstIndex: number, lastIndex: number) => {
		for (let index = firstIndex + 1; index <= lastIndex ; index++) {
			const folderItem = results.folders[index];
			doFolderStateAction({
				type: FOLDER_ACTIONS.SELECTED_FOLDER_ID,
				payload: folderItem.id,
			});
			doFolderStateAction({
				type: FOLDER_ACTIONS.SELECTED_FOLDER_OR_FILE_NAME,
				payload: folderItem.folderName,
			});
		}
	};
	
	const handleShiftKeySelectFile = (selectedFileId: number) => {
		const firstIndex = results.files.map(function(e) { return e.id; }).indexOf(selectedFileIds[0]);
		const lastIndex = results.files.map(function(e) { return e.id; }).indexOf(selectedFileId);

		for (let index = firstIndex + 1; index < lastIndex ; index++) {
			const fileItem = results.files[index];
			if(!fileItem.quarantined && !fileItem.pending) {
				doFolderStateAction({
					type: FOLDER_ACTIONS.SELECTED_FILE_ID,
					payload: fileItem.id,
				});
				doFolderStateAction({
					type: FOLDER_ACTIONS.SELECTED_FOLDER_OR_FILE_NAME,
					payload: `${fileItem.name}.${fileItem.type}`,
				});
			}
		}
	};

	return (
		<>
			<table className="data-table">
				<thead>
					<tr>
						{columns && columns.includes(COLUMN.INPUT) && 
							<th>
								<input
									type="checkbox"
									className="alignCenter"
									name="file-group"
									onChange={() => {
										handleCheckAll(!checkAll);
									}}
									checked={isSelectAllChecked()}
								/>
						</th>}
							{columns && columns.includes(COLUMN.ICON) && <th className="col-icon"></th>}
							{columns && columns.includes(COLUMN.NAME) && <th className="col-name">Name</th>}
							{columns && columns.includes(COLUMN.EXTENSION) && <th className="col-extension">Extension</th>}
							{columns && columns.includes(COLUMN.SIZE) && <th className="col-size">Size</th>}
							{columns && columns.includes(COLUMN.AUTHOR) && <th className="col-author">Author</th>}
							{columns && columns.includes(COLUMN.MODIFIED) && <th className="col-modified">Last modified</th>}
							{columns && columns.includes(COLUMN.STATUS) && <th className="col-status">Status</th>}
							{columns && columns.includes(COLUMN.TAGS) && <th className="col-tags">Tags</th>}
						</tr>
				</thead>
				<tbody>
					{results &&
						results.folders.map((folderItem: any) => (
							<tr
								style={{ cursor: 'pointer' }}
								tabIndex={0}
								key={`folder-${folderItem.id}`}
								onDoubleClick={() =>
									history.push(`/folder/${folderItem.id}`, { folderTree, searchState })
								}
							>
								{columns && columns.includes(COLUMN.INPUT) && (
									<td>
										<input
											type="checkbox"
											className="alignCenter"
											checked={isChecked({
												lookFor: folderItem.id,
												within: selectedFolderIds,
											})}
											id={`row-${folderItem.id}`}
											name="file-group"
											value={folderItem.id}
											onChange={() => {
												doFolderStateAction({
													type: FOLDER_ACTIONS.SELECTED_FOLDER_ID,
													payload: folderItem.id,
												});
												doFolderStateAction({
													type: FOLDER_ACTIONS.SELECTED_FOLDER_OR_FILE_NAME,
													payload: folderItem.folderName,
												});
											}}
											onClick={(e) => {
												if(e.shiftKey) {
													handleShiftKeySelect(folderItem.id, true);
												}
											}}
										/>
									</td>
								)}
								{columns && columns.includes(COLUMN.ICON) && (
									<td>
										<img width="20" src={folderIcon} alt="folder/file icon" />
									</td>
								)}
								{columns && columns.includes(COLUMN.NAME) && <td>{folderItem.folderName}</td>}
								{columns && columns.includes(COLUMN.EXTENSION) && <td>{''}</td>}
								{columns && columns.includes(COLUMN.SIZE) && <td>{folderItem.size ?? 'N/A'}</td>}
								{columns && columns.includes(COLUMN.AUTHOR) && (
									<td>{folderItem.createdByName ?? 'N/A'}</td>
								)}
								{columns && columns.includes(COLUMN.MODIFIED) && (
									<td>
										{folderItem.modifiedDate ? getDateFormatted(folderItem.modifiedDate) : 'N/A'}
									</td>
								)}
								{columns && columns.includes(COLUMN.STATUS) && <td>N/A</td>}
								{columns && columns.includes(COLUMN.TAGS) && (
										<td>
											<FolderTags id={folderItem.id} key={`FolderTags${folderItem.id}`}/>
										</td>
									)}
							</tr>
						))}

					{results &&
						results.files.map((fileItem) => {
							const fileSize = readableFileSizeFrom(fileItem.size);
							return (
								<tr
									style={{ cursor: 'pointer' }}
									tabIndex={0}
									key={`file-${fileItem.id}`}
									onDoubleClick={() => {
										if (fileItem.type.toLowerCase() === FILE.PDF) {
											if (fileItem.pending) {
												addToast(
													<ToastableFeedbackBlock
														warning
														headerText={
															<span className="u-heading-xs">
																Uploading, file still pending.
															</span>
														}
													/>,
													{ autoDismiss: true }
												);
											} else {
												setShowFileViewId(fileItem.id);
											}
										} else {
											addToast(
												<ToastableFeedbackBlock
													warning
													headerText={
														<span className="u-heading-xs">
															Preview does not support this document type.
														</span>
													}
												/>,
												{ autoDismiss: true }
											);
										}
									}}
								>
									{columns && columns.includes(COLUMN.INPUT) && (
										<td>
											<input
												type="checkbox"
												className="alignCenter"
												disabled={!!fileItem ?.quarantined || !!fileItem ?.pending}
												checked={isChecked({
													lookFor: fileItem.id,
													within: selectedFileIds,
												})}
												id={`row-${fileItem.id}`}
												name="file-group"
												value={fileItem.id}
												onChange={() => {
													doFolderStateAction({
														type: FOLDER_ACTIONS.SELECTED_FILE_ID,
														payload: fileItem.id,
													});
													doFolderStateAction({
														type: FOLDER_ACTIONS.SELECTED_FOLDER_OR_FILE_NAME,
														payload: `${fileItem.name}.${fileItem.type}`,
													});
												}}
												onClick={(e) => {
													if(e.shiftKey) {
														handleShiftKeySelect(fileItem.id, false);
													}
												}}
											/>
										</td>
									)}
									{columns && columns.includes(COLUMN.ICON) && (
										<td>
											{fileItem.type === FILE.PDF && (
												<img width="20" src={filePdf} alt="file icon" />
											)}
											{fileItem.type !== FILE.PDF && (
												<img width="20" src={fileGeneric} alt="file icon" />
											)}
										</td>
									)}
									{columns && columns.includes(COLUMN.NAME) && <td>{fileItem.name}</td>}
									{columns && columns.includes(COLUMN.EXTENSION) && <td>{fileItem.type ?? 'N/A'}</td>}
									{columns && columns.includes(COLUMN.SIZE) && (
										<td>{fileItem.size ? fileSize : 'N/A'}</td>
									)}
									{columns && columns.includes(COLUMN.AUTHOR) && (
										<td>{fileItem.createdByName ?? 'N/A'}</td>
									)}
									{columns && columns.includes(COLUMN.MODIFIED) && (
										<td>{getDateFormatted(fileItem.modifiedDate)}</td>
									)}
									{columns && columns.includes(COLUMN.STATUS) && (
										<td>
											{fileItem.pending && <>pending</>}
											{fileItem.quarantined && <>Quarantined</>}
											{!fileItem.quarantined && !fileItem.pending && <>Ok</>}
										</td>
									)}
									{columns && columns.includes(COLUMN.TAGS) && (
										<td>
											<FileTags id={fileItem.id} key={`FileTags:${fileItem.id}`}/>
										</td>
									)}
								</tr>
							);
						})}
				</tbody>
			</table>
			{showFileViewId >= 0 && <FileViewer id={showFileViewId} onClose={() => setShowFileViewId(-1)} />}
		</>
	);
};

Table.defaultProps = {
	columns: [COLUMN.INPUT, COLUMN.ICON, COLUMN.NAME, COLUMN.EXTENSION, COLUMN.SIZE, COLUMN.AUTHOR, COLUMN.MODIFIED, COLUMN.STATUS, COLUMN.TAGS],
};

export default Table;
