import get from 'lodash/get';
import { PERMISSIONS } from '../../../../enum';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { FileType, FolderMetaDataType, getBreadcrumbs } from '../../../../api';
import { FolderContext, FOLDER_ACTIONS } from '../../../../context/FolderContext';
import { AppContext } from '../../../../authentication/AppProvider';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Button } from '@freshfields/react-component-library';
import { DrawerPanel } from '../../../DrawerPanel';
import { PermissionsTable } from '../../../PermissionsTable';
import { History } from '../../../History';
import { TabPanel, tabA11yProps } from '../../../TabsPanel';
import { FolderDetails } from '../../../FolderDetails';
import infoIcon from '../../../../images/info.svg';

const RootInfo: React.FC = () => {
    const { user } = useContext(AppContext);
    const isAdmin = user?.profile?.roles.includes(PERMISSIONS.ADMIN);
    const [folderState, doFolderStateAction] = useContext(FolderContext);
    const [toggleDrawer, setToggleDrawer] = useState(false);
    const [tabsPanelValue, setTabsPanelValue] = useState(0);
    const [isSelectItemSingular, setIsSelectItemSingular] = useState(true);
    const selected: React.MutableRefObject<{ file?: FileType; folder?: FolderMetaDataType; isFolder?: boolean }> = useRef({
        file: undefined,
        folder: undefined,
        isFolder: true,
    });
    const { selectedFileIds, selectedFolderIds, activeFolder } = folderState;
    const [documentTitle, setDocumentTitle] = useState('');
      const selectedItem: React.MutableRefObject<{ Id: number; Type: string  }> = useRef({
        Id : 0,
        Type: ''
    });
  
    useEffect(() => {
        if (activeFolder) { 
            setDocumentTitle(activeFolder.folderName);
            selectedItem.current.Id = activeFolder.id ?? 0;
            selectedItem.current.Type = 'folder';
            selected.current = { folder: activeFolder };
        }
        setIsSelectItemSingular(activeFolder && activeFolder.parentFolderId === 0 && (selectedFolderIds.length === 0 && selectedFileIds.length === 0));
        // eslint-disable-next-line
    }, [folderState]);

    useEffect(() => {
        let currentFolderId = selected.current.folder?.id;
        if (!currentFolderId) {
            return;
        }
        getBreadcrumbs({ id: currentFolderId, token: get(user, 'access_token') }).then(
            (response) => {
                const { data } = response;
                doFolderStateAction({
                    type: FOLDER_ACTIONS.SET_BREADCRUMBS,
                    payload: data
                });
            }
        );

    }, [doFolderStateAction, user]);

    return (
        <span className="action-bar__info-button">
            {isSelectItemSingular && (
                <Button
                    secondary
                    id="share"
                    type="button"
                    data-e2e-testid="actionBarInfoButton"
                    data-testid="actionBarInfoButton"
                    onClick={() => setToggleDrawer(!toggleDrawer)}
                >
                    <div className="action-bar-button__content">
                        <img src={infoIcon} alt="Info" />
                        <span className="u-visuallyhidden">info</span>
                    </div>
                </Button>
            )}

            <DrawerPanel toggleDrawer={toggleDrawer} setToggleDrawer={setToggleDrawer}>
                <div className="tabs-panel">
                    <h3 className="tabs-panel__heading">
                        <label>{documentTitle}</label>
                    </h3>
                    <AppBar position="static">
                        <Tabs value={tabsPanelValue} onChange={(_, newValue) => setTabsPanelValue(newValue)} aria-label="tabs">
                            <Tab label="Details" {...tabA11yProps(1)} />
                            <Tab label="History" {...tabA11yProps(2)} />
                            {isAdmin && <Tab label="Permissions" {...tabA11yProps(0)} />}
                        </Tabs>
                    </AppBar>
                    
                    <TabPanel value={tabsPanelValue} index={0}>
                        <FolderDetails item={selected.current?.folder} />
                    </TabPanel>

                    <TabPanel value={tabsPanelValue} index={1}>
                            <History itemId={selectedItem.current.Id} itemType={selectedItem.current.Type} />
                    </TabPanel>
                    
                    {isAdmin && (
                        <TabPanel value={tabsPanelValue} index={2}>
                            <PermissionsTable onDone={() => setToggleDrawer(!toggleDrawer)} />
                        </TabPanel>
                    )}
                </div>
            </DrawerPanel>
        </span>
    );
};

export default RootInfo;
