import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import axiosWithConfig from '../common/axiosWithConfig';
import * as Api from './api.types';

export const restoreFilesToFolder = async ({ token, targetFolderId, fileIds }: Api.RequestRestoreType) => {
	const hasValues = targetFolderId || fileIds.length > 0;
	if (!token || !hasValues) return null;
	try {
		const axios = axiosWithConfig(token);
		return axios.post('/v1/files/restore', { folderId: targetFolderId, fileIds });
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const moveFilesToFolder = async ({ token, fileIds, folderId }: Api.RequestBulkFileType) => {
	const hasValues = folderId || fileIds.length > 0;
	if (!token || !hasValues) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.post('/v1/files/bulkmove', { folderId, fileIds });
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const copyFilesToFolder = async ({ token, fileIds, folderId }: Api.RequestBulkFileType) => {
	const hasValues = folderId || fileIds.length > 0;
	if (!token || !hasValues) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.post('/v1/files/bulkcopy', { folderId, fileIds });
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const copyItemsToFolder = async ({ token, fileIds, folderIds, folderId }: Api.RequestBulkItemType) => {
	const hasValues = folderId || fileIds.length > 0 || folderIds.length > 0;
	if (!token || !hasValues) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.post('/v1/files/bulkcopyitems', { folderId, fileIds, folderIds });
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const upload = async ({ token, fileList, folderId }: Api.RequestUploadType) => {
	if (!token) return null;
	try {
		const axios = axiosWithConfig(token);
		const uploadData = new FormData();
		Array.from(fileList).forEach((file) => {
			uploadData.append('UploadArray', file);
			const params = omitBy(
				{
					Name: file.name,
					Size: String(file.size),
					Type: file.type,
					FolderId: folderId,
					// CreatedById: get(user, 'profile.sub'),
				},
				(v?: string | number) => isNil(v)
			);
			for (const [param, value] of Object.entries(params)) {
				uploadData.append(param, String(value));
			}
		});

		return await axios.post('/v1/files/bulkupload', uploadData);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const download = async ({ token, selectedIds }: Api.RequestSelectedType) => {
	if (!token || selectedIds.length === 0) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.post(
			'/v1/files/bulkdownload',
			{ Ids: selectedIds },
			{
				responseType: 'blob',
				headers: {
					'content-type': 'application/json',
					accept: '*/*',
				},
			}
		);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const search = async ({ token, query, showDeleted, page, folderId, itemsPerPage }: Api.RequestSearchQueryType) => {
	if (!token || !query) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.post('v1/search', {
			showDeleted,
			searchPhrase: query,
			currentPage: page,
			folderId,
			itemsPerPage
		});
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const createFolder = async ({ token, folderNamePart1, folderNamePart2, parentFolderId }: Api.RequestFileDataType) => {
	if (!token) return null;
	try {
		const axios = axiosWithConfig(token);
		const params = omitBy(
			{
				FolderNamePart1: folderNamePart1,
				FolderNamePart2: folderNamePart2,
				ParentFolderId: parentFolderId,
			},
			(v?: string | number) => !v
		);
		return await axios.post('/v2/folders', params);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const setFolderPermissions = async ({ id: folderId, groupId, token }: Api.RequestGroupType) => {
	if (isNil(token) || isNil(folderId) || isNaN(folderId)) return { data: [] };
	try {
		const axios = axiosWithConfig(token);
		return await axios.post('/v1/permissions', {
			folderId,
			groupId,
		});
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const removeFolderPermissions = async ({ id: folderId, groupId, token }: Api.RequestGroupType) => {
	if (isNil(token) || isNil(folderId) || isNaN(folderId)) return { data: [] };
	try {
		const axios = axiosWithConfig(token);
		return await axios.post('/v1/permissions/remove', {
			folderId,
			groupId,
		});
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const matchingFolders = async({token, searchTerm}: Api.RequestMatchFolderType) => {
	if (!token || !searchTerm) return [];
	try {
		const axios = axiosWithConfig(token);
		return await axios.post('/v1/folders/match', {
			searchTerm
		});
	} catch (error) {
		console.error(error);
		return error;
	}
};