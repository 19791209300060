import React, { Component, createContext, ReactNode } from 'react';
import { User, UserManager } from 'oidc-client';
import { match, withRouter } from 'react-router-dom';
import { History as BrowserHistory, Location as BrowserLocation } from 'history';
import { OIDC_SIGN_IN_REDIRECT_PATH } from '../common/constants';
import { HubConnection } from '@microsoft/signalr';

interface AppContext {
  user: User | null;
  getUserOrSignIn(): void;
  signInRedirect(): void;
  signInRedirectCallback(): Promise<User>;
  signInSilent(): void;
  setRedirectPath(path: string): void;
  getRedirectPath(): string;
  history: BrowserHistory;
  location: BrowserLocation;
  match: match;
  hubConnection: HubConnection | null;
  setHubConnection(connection: HubConnection): void;
}

interface Props {
  children: ReactNode;
  userManager: UserManager;
  history: BrowserHistory;
  location: BrowserLocation;
  match: match;
}

interface State {
  userManager: UserManager;
  user: User | null;
  hubConnection: HubConnection | null;
}

export const AppContext = createContext<AppContext>({} as any);

export class AppProviderComponent extends Component<Props, State> {
  state: State;
  props: Props;

  constructor(props: Props) {
    super(props);

    this.props = props;

    this.state = {
      userManager: this.props.userManager,
      user: null,
      hubConnection: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.state.userManager.events.addUserLoaded((user: User) => {
      this.loadUser(user);
    });
  }
  
  getUserOrSignIn = () => {
    this.state.userManager.getUser().then(user => {
      if (user === null || user.expired) {
        this.signInRedirect();
      } else {
        this.loadUser(user);
      }
    });
  };

  loadUser = (user: User) => {
    this.setState({
      user
    });
  };

  signInRedirect = () => {
    this.state.userManager.signinRedirect();
  };

  signInRedirectCallback = () => {
    return this.state.userManager.signinRedirectCallback();
  }

  signInSilent = () => {
    this.state.userManager.signinSilent();
  }

  setRedirectPath = (path: string) => {
    sessionStorage.setItem(OIDC_SIGN_IN_REDIRECT_PATH, path);
  };

  getRedirectPath = () : string => 
    sessionStorage.getItem(OIDC_SIGN_IN_REDIRECT_PATH) !== null ? sessionStorage.getItem(OIDC_SIGN_IN_REDIRECT_PATH) as string : '/';
  
  setHubConnection = (connection: HubConnection) => {
    this.setState({hubConnection: connection});
  }

  render = () => (
    <AppContext.Provider
      value={{
        user: this.state.user,
        getUserOrSignIn: this.getUserOrSignIn,
        signInRedirect: this.signInRedirect,
        signInRedirectCallback: this.signInRedirectCallback,
        signInSilent: this.signInSilent,
        setRedirectPath: this.setRedirectPath,
        getRedirectPath: this.getRedirectPath,
        history: this.props.history,
        location: this.props.location,
        match: this.props.match,
        hubConnection: this.state.hubConnection,
        setHubConnection: this.setHubConnection
      }}
    >
      {this.props.children}
    </AppContext.Provider>
  );
}

export const AppConsumer = AppContext.Consumer;

export default withRouter(AppProviderComponent);