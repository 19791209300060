import axiosWithConfig from '../common/axiosWithConfig';
import * as Api from './api.types';

export const file = async ({ token, id, details }: Api.RequestFileDetailsType) => {
	if (!token || !id || !details) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.put(`/v1/files/${id}`, details);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const folder = async ({ token, id, details }: Api.RequestFolderDetailsType) => {
	if (!token || !id || !details) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.put(`/v2/folders/${id}`, details);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const moveFolder = async ({ token, folderId, destinationFolderId }: Api.RequestMoveFolderType) => {
	const hasValues = folderId || destinationFolderId;
	if (!token || !hasValues) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.put(`/v1/folders/move/${folderId}/${destinationFolderId}`);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const addFolderTag = async ({ token, id, details }: Api.RequestTagType) => {
	if (!token || !id || !details) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.put(`/v1/folders/${id}/tags/add`, details);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const removeFolderTag = async ({ token, id, details }: Api.RequestTagType) => {
	if (!token || !id || !details) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.put(`/v1/folders/${id}/tags/remove`, details);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const addFileTag = async ({ token, id, details }: Api.RequestTagType) => {
	if (!token || !id || !details) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.put(`/v1/files/${id}/tags/add`, details);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const removeFileTag = async ({ token, id, details }: Api.RequestTagType) => {
	if (!token || !id || !details) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.put(`/v1/files/${id}/tags/remove`, details);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const restoreFolders = async ({ token, targetFolderId, folderIds }: Api.RequestRestoreFolderType) => {
	const hasValues = targetFolderId || folderIds.length > 0;
	if (!token || !hasValues) return null;
	try {
		const axios = axiosWithConfig(token);
		return axios.put('/v2/folders/restore', { targetFolderId: targetFolderId, folderIds });
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const cancelJob = async ({ token, id }: Api.RequestIdType) => {
	if (!token || !id ) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.put(`/v1/jobs/${id}`);
	} catch (error) {
		console.error(error);
		return error;
	}
};
