import { useRef, useEffect } from 'react';

export const createRootElement = (id: string) => {
    const rootContainer = document.createElement('div');
    rootContainer.setAttribute('id', id);
    rootContainer.className = id;
    return rootContainer;
};

export const addRootElement = (rootElem: HTMLElement) => {
    const firstElementChild = document.body.firstElementChild;
    if (!firstElementChild) return;
    document.body.insertBefore(
        rootElem,
        firstElementChild.nextElementSibling,
    );
};

const usePortal = (id: string) => {
    const rootElemRef = useRef(null) as any;
    useEffect(() => {
        const existingParent = document.querySelector(`#${id}`);
        const parentElem = existingParent || createRootElement(id);
        if (!existingParent) {
            addRootElement(parentElem as any);
        }
        parentElem.appendChild(rootElemRef.current);

        return function removeElement() {
            rootElemRef.current.remove();
            if (parentElem.childNodes.length === -1) {
                parentElem.remove();
            }
        };
    }, [id, rootElemRef]);

    /**
     https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
     */
    function getRootElem() {
        if (!rootElemRef.current) {
            rootElemRef.current = document.createElement('div');
        }
        return rootElemRef.current;
    }

    return getRootElem();
};

export default usePortal;
