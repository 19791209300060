import get from 'lodash/get';
import React, { useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useUrlQuery } from '../../common/useUrlQuery';
import { search, FileType } from '../../api';
import { useLocation, useHistory } from 'react-router-dom';
import { SearchTableType } from './search.types';
import { AppContext } from '../../authentication/AppProvider';
import { FolderContext, SearchContext, FOLDER_ACTIONS, SEARCH_ACTIONS } from '../../context';
import { Table as RawTable, COLUMN } from '../Table';
import { LoadingSpinner } from '../LodingSpinner';
import { NumberResultsSelect } from '../NumberResultsSelect';
import { SearchPagination } from '../SearchPagination';
import { Input, InputLabel } from '@material-ui/core';
import compact from 'lodash/compact';

interface SearchType extends FileType {
    isFile: boolean;
}

const onlyFiles = (item: SearchType) => item.isFile;
const onlyFolders = (item: SearchType) => !item.isFile;

export const SearchTableWithData: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useContext(AppContext);
    const [searchState, doSearchStateAction] = useContext(SearchContext);
    const [, doFolderStateAction] = useContext(FolderContext);

    let query = useUrlQuery();
    let location = useLocation();
    const parsedQueryString = queryString.parse(location.search);
    const isDeleted = parsedQueryString.deleted === 'true';
    const scoped = parsedQueryString.scoped === 'true';
    const itemsPerPage = Number(parsedQueryString.itemsPerPage) ?? 25;
    const page = Number(parsedQueryString.page) ?? 1;
    const scopeId = Number(parsedQueryString.scopeId);

    useEffect(() => {
        async function loadSearchQuery() {
            try {
                const response = await search({
                    token: get(user, 'access_token'),
                    query: query.get('query'),
                    showDeleted: isDeleted,
                    folderId: scoped ? scopeId : null,
                    page,
                    itemsPerPage
                });
                if (!response) return;
                const items = get(response, 'data.items', []) as SearchType[];
                const files = items.filter(onlyFiles);
                const folders = items.filter(onlyFolders).map((folder) => ({ ...folder, folderName: folder.name }));
                doSearchStateAction({
                    type: SEARCH_ACTIONS.UPDATE_SEARCH_RESULTS,
                    payload: {
                        files,
                        folders,
                        hasNextPage: response.data.hasNextPage,
                        hasPrevPage: response.data.hasPrevPage,
                        page,
                        itemsPerPage,
                        totalCount: response.data.totalCount
                    },
                });
                doSearchStateAction({
                    type: SEARCH_ACTIONS.UPDATE_SEARCH_SCOPE_ID,
                    payload: scopeId,
                });
                doFolderStateAction({
                    type: FOLDER_ACTIONS.SET_FROM_SEARCH,
                    payload: {
                        files,
                        folders,
                    },
                });
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                //TODO: Show error
                setIsLoading(false);
            }
        }
        loadSearchQuery();
        // eslint-disable-next-line
    }, [searchState.showDeleted, location]);
    if (searchState?.results?.files.length === 0 && searchState?.results.folders?.length === 0 && isLoading === false) {
        return <p className="text-center my-12">No files / folders available</p>;
    }
    if (isLoading) return <LoadingSpinner />;
    return <SearchTable results={searchState.results} />;
};

export const Table: React.FC<SearchTableType> = (params) => {
    const { results } = params;
    let history = useHistory();
    const [{ folderTree }, doFolderStateAction] = useContext(FolderContext);
    const [searchState] = useContext(SearchContext);
    let itemsPerPage = searchState.results.itemsPerPage;
    let location = useLocation();
    const parsedQueryString = queryString.parse(location.search);
    const pageFromQuery = Number(parsedQueryString.page) ?? 1;
    let page = 1;

    const handlePageChange = () => {
        const term = `query=${searchState.term}`;
        const deleted = `deleted=${searchState.showDeleted}`;
        const scopeId = `scopeId=${searchState.scopeId}`;
        const isScoped = `scoped=${searchState.isScoped}`;
        const itemsPerPage = searchState.results.itemsPerPage ? `itemsPerPage=${searchState.results.itemsPerPage}` : 'itemsPerPage=25';
        const pageQuery =  `page=${page}`;
        const query = compact([term, deleted, scopeId, isScoped, pageQuery, itemsPerPage]).join('&');
            history.push(`/search?${query}`, {
                folderTree,
                searchState: {
                    ...searchState,
                    results: { ...searchState.results, page: page, itemsPerPage: itemsPerPage },
                },
            });
        };
    

    const handleItemsPerPageChange = (itemsPerPageSelection: string ) => {
        const currentPage = Number(searchState.results.page);
        const currentPageQuery = `page=${currentPage}`;
        const term = `query=${searchState.term}`;
        const deleted = `deleted=${searchState.showDeleted}`;
        const scopeId = `scopeId=${searchState.scopeId}`;
        const isScoped = `scoped=${searchState.isScoped}`;
        const itemsPerPage = Number(itemsPerPageSelection);
        const itemsPerPageQuery = `itemsPerPage=${itemsPerPage}`;

        const query = compact([term, deleted, scopeId, isScoped, currentPageQuery, itemsPerPageQuery]).join('&');
            history.push(`/search?${query}`, {
                folderTree,
                searchState: {
                    ...searchState,
                    results: { ...searchState.results, page: currentPage, itemsPerPage: itemsPerPage },
                },
            });
    };

    const handlePageChangeSubmit = (e: any) => {
        if(e.key === 'Enter' || e.key === 'Tab'){
            handlePageChange();
        }
    };

    useEffect(() => {
        doFolderStateAction({
            type: FOLDER_ACTIONS.CLEAR_SELECTION,
        });
        // eslint-disable-next-line
    }, [results]);

    const handleOnGotoPageChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const pages = Math.ceil(searchState.results.totalCount/itemsPerPage);
		if(parseInt(e.target.value) > pages){
			page=pages;	
			e.target.value = pages.toString();
			return;
		}
		if (e.target.value !== '') {
			const num = parseInt(e.target.value);
			page = num < 1 ? 1 : num;
			e.target.value = num < 1 ? '1' : num.toString();
		}
	};
    
    const resultCount = () => {
        const firstValue = (itemsPerPage * pageFromQuery) - itemsPerPage + 1;
        var filesAndFoldersOnPage = results.files.length + results.folders.length;
        const secondValue = ((filesAndFoldersOnPage) >= itemsPerPage) ? 
            itemsPerPage * pageFromQuery : 
            (itemsPerPage * (pageFromQuery - 1)) + filesAndFoldersOnPage;
        return searchState.results.totalCount > 0 ? 
            <div className="rc-components"><p>Showing</p><span className="pagination-bold">{`${firstValue}`}</span> - <span className="pagination-bold">{`${secondValue}`}</span> of <span className="pagination-bold">{`${searchState.results.totalCount}`}</span> items</div> : 
            <div className="rc-components"><p>Showing</p><span className="pagination-bold">{`${firstValue}`}</span> - <span className="pagination-bold">{`${secondValue}`}</span> items</div>;
    };

    return (
        <>
            <RawTable
                results={results}
                searchState={searchState}
                columns={[COLUMN.INPUT, COLUMN.ICON, COLUMN.NAME, COLUMN.SIZE, COLUMN.AUTHOR, COLUMN.MODIFIED, COLUMN.TAGS]}
            />
            <div className="pagination-page-bottom">
                <div id="cc-pagination" className="pagination-bottom">
                    <div className="pagination-item">
                        <InputLabel id="go-to-page">Go to page</InputLabel>
                        <Input 
                            type="number" 
                            id="go-to-page-input"
                            onChange={(e) => {
                                handleOnGotoPageChange(e);
                            }}
                            onKeyPress={(e) => {handlePageChangeSubmit(e);}}
                        />
                    </div>
                    
                    <div  className="pagination-item">
                        <div id="resultCount">{resultCount()}</div>
                    </div>
                    <div  className="number-result-select-container">
                        <NumberResultsSelect onItemsPerPageChange={handleItemsPerPageChange}  itemsPerPageValue={itemsPerPage} />
                    </div>                
                </div>
                <SearchPagination />
            </div>        
        </>
    );
};

export const SearchTable = React.memo(Table);
export default React.memo(SearchTableWithData);
