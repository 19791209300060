import React, { FC } from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '../authentication/ProtectedRoute';
import Default from './Default';
import { NotFoundPage } from '../pages/NotFoundPage';
import { FolderProvider, SearchProvider } from '../context';

const DefaultWithContext = () => (
	<FolderProvider>
		<SearchProvider>
			<Default />
		</SearchProvider>
	</FolderProvider>
);

export const Routes: FC = () => {
  return (
		<Switch>
			<ProtectedRoute
				exact
				path={['/', '/folder/:folderId', '/folder/:folderId/download/:downloadIds', '/search']}
				component={() => <DefaultWithContext />}
			/>
			<NotFoundPage />
		</Switch>
  );
};
