export enum ACTIONS {
	NEW_FOLDER,
	UPLOAD,
	DOWNLOAD,
	DOWNLOAD_OR_RESTORE,
	MOVE,
	COPY,
	DELETE,
	SHARE,
	RESTORE,
	INFO,
	COPYTOFOLDER,
	DELETEINSEARCH,
	ROOTINFO,
	SHAREINSEARCH,
	JOBS
}