import React, { createContext, useReducer } from 'react';
import { FolderStateType } from './folder.context.types';
import { useLocation } from 'react-router-dom';
import { addOrRemoveIfExistingFrom } from '../common/addOrRemoveIfExistingFrom';

export enum FOLDER_ACTIONS {
    UPDATE_ACTIVE_FOLDER = 'UPDATE_ACTIVE_FOLDER',
    UPDATE_ACTIVE_FOLDER_ID = 'UPDATE_ACTIVE_FOLDER_ID',
    UPDATE_ACTIVE_FILE = 'UPDATE_ACTIVE_FILE',
    FETCH_FOLDERS = 'FETCH_FOLDERS',
    SELECTED_FILE_ID = 'SELECTED_FILE',
    SELECTED_FOLDER_ID = 'SELECTED_FOLDER',
    SELECTED_FOLDER_OR_FILE_NAME = 'SELECTED_FOLDER_OR_FILE_NAME',
    CLEAR_SELECTION = 'CLEAR_SELECTION',
    SET_BREADCRUMBS = 'SET_BREADCRUMBS',
    UPDATE_FOLDER_TREE = 'UPDATE_FOLDER_TREE',
    FETCH_FILES = 'FETCH_FILES',
    SET_FROM_SEARCH = 'SET_FROM_SEARCH',
    UPDATE_FOLDER_TAGS = 'UPDATE_FOLDER_TAGS',
    UPDATE_FILE_TAGS = 'UPDATE_FILE_TAGS',
}

export interface FolderActionReducerType<T> {
    type: FOLDER_ACTIONS;
    payload: T;
}

export const FolderContext = createContext([] as any);

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case FOLDER_ACTIONS.UPDATE_ACTIVE_FOLDER:
            return { ...state, activeFolder: action.payload };
        case FOLDER_ACTIONS.UPDATE_ACTIVE_FOLDER_ID:
            return { ...state, activeFolderId: action.payload };
        case FOLDER_ACTIONS.UPDATE_ACTIVE_FILE:
            return { ...state, activeFile: action.payload };
        case FOLDER_ACTIONS.FETCH_FOLDERS:
            return { ...state, folders: action.payload };
        case FOLDER_ACTIONS.FETCH_FILES:
            return { ...state, folders: { ...state.folders, files: action.payload } };
        case FOLDER_ACTIONS.CLEAR_SELECTION:
            return { ...state, selectedFileIds: [], selectedFolderIds: [], selectedFolderOrFileName: [] };
        case FOLDER_ACTIONS.SELECTED_FILE_ID:
            return {
                ...state,
                selectedFileIds: addOrRemoveIfExistingFrom({
                    currentIds: state.selectedFileIds,
                    id: action.payload,
                }),
            };
        case FOLDER_ACTIONS.SELECTED_FOLDER_ID:
            return {
                ...state,
                selectedFolderIds: addOrRemoveIfExistingFrom({
                    currentIds: state.selectedFolderIds,
                    id: action.payload,
                }),
            };
        case FOLDER_ACTIONS.SELECTED_FOLDER_OR_FILE_NAME:
            if (state.selectedFolderOrFileName.includes(action.payload)) {
                const newselectedFolderOrFileNameState = new Set([...state.selectedFolderOrFileName]);
                newselectedFolderOrFileNameState.delete(action.payload);

                return {
                    ...state,
                    selectedFolderOrFileName: Array.from(newselectedFolderOrFileNameState),
                };
            }

            return {
                ...state,
                selectedFolderOrFileName: [...state.selectedFolderOrFileName, action.payload],
            };
        case FOLDER_ACTIONS.SET_BREADCRUMBS:
            return { ...state, breadcrumbs: action.payload };
        case FOLDER_ACTIONS.UPDATE_FOLDER_TREE:
            return { ...state, folderTree: action.payload };
        case FOLDER_ACTIONS.SET_FROM_SEARCH:
            let { folders } = state;
            folders = {
                ...folders,
                files: action.payload.files,
                children: action.payload.folders,
            };
            return { ...state, folders: folders };
        case FOLDER_ACTIONS.UPDATE_FOLDER_TAGS:
            const {folderId, folderTags } = action.payload;
            let folderList = state.folders.children;
            folderList = folderList.map((folder:any) => {
                return folderId !== folder.id ? folder : { ...folder, tagsList: folderTags };
            });
            return { ...state, folders: { ...state.folders, children: folderList } };
        case FOLDER_ACTIONS.UPDATE_FILE_TAGS:
            const {fileId, fileTags } = action.payload;
            let fileList = state.folders.files;
            fileList = fileList.map((file:any) => {
                return fileId !== file.id ? file : { ...file, tagsList: fileTags };
            });
            return { ...state, folders: { ...state.folders, files: fileList } };
        default:
            throw Error('FolderContext reducer error');
    }
};

const initialState: FolderStateType = {
    activeFolder: undefined,
    activeFolderId: undefined,
    activeFile: undefined,
    folders: {
        metaData: {},
        files: [],
        children: [],
        loading: true,
        page: 1,
        itemsPerPage: 0,
        totalItems: 0,
        totalPages: 0,
    },
    selectedFileIds: [],
    selectedFolderIds: [],
    selectedFolderOrFileName: [],
    breadcrumbs: [],
    folderTree: {
        data: [],
        loading: true,
        error: false,
    },
};

export const FolderProvider: React.FC = (props) => {
    const locationState = useLocation<any>().state;
    const folderTree = locationState ?.folderTree ?? initialState.folderTree;
    const stateHook = useReducer(reducer, {
        ...initialState,
        folderTree,
    });

    return <FolderContext.Provider value={stateHook}>{props.children}</FolderContext.Provider>;
};
