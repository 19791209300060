import Breadcrumb from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FolderContext } from '../../context/FolderContext';

interface BreadcrumbType {
	folderId: number;
	folderName: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			maxWidth: '70%',
		}
	})
);

export const Location = () => {
	const [folderState] = useContext(FolderContext);
	const { breadcrumbs, folderTree } = folderState;
	const classes = useStyles();
	let history = useHistory();

	const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		let href = event.currentTarget.getAttribute('data-route');
		history.push(href ? href : '', { folderTree });
	};

	return (
		<div className={classes.root}>
			<Breadcrumb maxItems={5} aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
				{
					breadcrumbs.map((breadcrumb: BreadcrumbType) => {
						return (
							<Link color="inherit" href="#" data-route={`/folder/${breadcrumb?.folderId}`} onClick={handleClick} key={breadcrumb?.folderId}>
								{breadcrumb?.folderName}
							</Link>
						);
					})
				}
			</Breadcrumb>
		</div>
	);
};
