import React, { useState, useEffect, useRef } from 'react';
import { useKeypress, useOnClickOutside } from '../../hooks';
import editIcon from '../../images/edit.svg';
import './InlineEdit.css';

interface InlineEditType {
	text: string;
	onSetText: (arg0: string) => void;
}

const InlineEdit: React.FC<InlineEditType> = (props) => {
	const [isInputActive, setIsInputActive] = useState(false);
	const [inputValue, setInputValue] = useState(props.text);
	const wrapperRef = useRef(null);
	const textRef = useRef(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const enter = useKeypress('Enter');
	const esc = useKeypress('Escape');
	// check to see if the user clicked outside of this component
	useOnClickOutside(wrapperRef, () => {
		if (isInputActive) {
			props.onSetText(inputValue);
			setIsInputActive(false);
		}
	});
	// focus the cursor in the input field on edit start
	useEffect(() => {
		if (inputRef.current && isInputActive) {
			inputRef.current.focus();
		}
		// eslint-disable-next-line
	}, [isInputActive]);
	useEffect(() => {
		if (isInputActive) {
			// if Enter is pressed, save the text and case the editor
			if (enter) {
				props.onSetText(inputValue);
				setIsInputActive(false);
			}
			// if Escape is pressed, revert the text and close the editor
			if (esc) {
				setInputValue(props.text);
				setIsInputActive(false);
			}
		}
		// eslint-disable-next-line
	}, [enter, esc]); // watch the Enter and Escape key presses
	return (
		<>
			<span className="inline-text" ref={wrapperRef}>
				<span
					ref={textRef}
					onClick={() => setIsInputActive(true)}
					className={`inline-text_copy inline-text_copy--${!isInputActive ? 'active' : 'hidden'}`}
				>
					{props.text}
				</span>
				<input
					ref={inputRef}
					// set the width to the input length multiplied by the x height
					// it's not quite right but gets it close
					style={{ width: Math.ceil(inputValue.length * 1.1) + 'ex' }}
					value={inputValue}
					onChange={(e) => {
						setInputValue(e.target.value);
					}}
					className={`inline-text_input inline-text_input--${isInputActive ? 'active' : 'hidden'}`}
				/>
				{!isInputActive && <img className="panel-header-icon" width="20" height="20" src={editIcon} alt="edit file/folder name" />}
			</span>
			
		</>
	);
};
export default InlineEdit;
