import React, { useState, useContext } from 'react';
import head from 'lodash/head';
import { createFolder } from '../../../../api';
import { AppContext } from '../../../../authentication/AppProvider';
import { Button, ToastAtoms } from '@freshfields/react-component-library';
import { Modal } from '../../../Modal';
import { FolderContext, FOLDER_ACTIONS } from '../../../../context';
import addIcon from '../../../../images/add.svg';
import './NewFolder.style.css';
import { getNodeKey, transformToTreeData } from '../../../FolderTree';
import { addNodeUnderParent, TreeItem } from 'react-sortable-tree';

const NewFolder: React.FC = () => {
	const { user } = useContext(AppContext);
	const [folderState, doFolderStateAction] = useContext(FolderContext);
	const [newFolderTitlePart1, setNewFolderTitlePart1] = useState('');
	const [newFolderTitlePart2, setNewFolderTitlePart2] = useState('');
	const [newFolderModal, setNewFolderModal] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { addToast } = ToastAtoms.useToasts();
	
	// NOTE: workaround for incorrect types on ToastableFeedbackBlock
	const ToastableFeedbackBlock = ToastAtoms.ToastableFeedbackBlock as any;

	const handleCreateFolder = async () => {
		const maxNameLength = 100;
		// add our validation here
		// 1. part 1 of name is mandatory
		if ((newFolderTitlePart1.trim().length) === 0) {
			addToast(
				<ToastableFeedbackBlock
				error
				headerText={<span className="u-heading-xs">New Folder</span>}
				bodyText="Ordnername/Name des Kläger is required"
				/>,
				{ autoDismiss: true }
				);
				return;
			};
			
		// 2. combined length of both parts should not exceed 100 characters
		if ((newFolderTitlePart1.length + newFolderTitlePart2.length) > maxNameLength) {
			addToast(
				<ToastableFeedbackBlock
					error
					headerText={<span className="u-heading-xs">New Folder</span>}
					bodyText="Folder name cannot be greater than 100 characters"
				/>,
				{ autoDismiss: true }
			);
			return;
		};

		const token = user!.access_token;
		setIsSaving(true);
		createFolder({
			token,
			folderNamePart1: newFolderTitlePart1,
			folderNamePart2: newFolderTitlePart2,
			parentFolderId: folderState.activeFolderId,
		})
			.then((response) => {
				//This is a workaround for creating a new root folder which errored
				//at the doFolderStateAction stage despite creating the folder successfully

				let childrenArray =
					folderState.folders.children.length === 0
						? [response.data]
						: [response.data,...folderState.folders.children];

				doFolderStateAction({
					type: FOLDER_ACTIONS.FETCH_FOLDERS,
					payload: {
						...folderState.folders,
						children: childrenArray,
					}
				});

				
				try {
					doFolderStateAction({
						type: FOLDER_ACTIONS.UPDATE_FOLDER_TREE,
						payload: {
							...folderState?.folderTree?.data,
							data: addNodeUnderParent({
								treeData: folderState?.folderTree?.data,
								ignoreCollapsed: true,
								parentKey: response.data.parentFolderId,
								newNode: head(transformToTreeData([response.data])) as TreeItem,
								getNodeKey,
							}).treeData
						},
					});
				} catch {
					console.warn('folder tree is not open at parent level');
				}
				
				addToast(
					<ToastableFeedbackBlock
						success
						headerText={<span className="u-heading-xs">New folder created</span>}
					/>,
					{ autoDismiss: true }
				);
				setIsSaving(false);
				setNewFolderModal(false);
			})
			.catch((error) => {
				console.error(error);		
				addToast(
					<ToastableFeedbackBlock
						error
						headerText={<span className="u-heading-xs">Failed to create new folder</span>}
					/>,
					{ autoDismiss: true }
				);
				setIsSaving(false);
				setNewFolderModal(false);
			});
	};

	
	const isInFolder = folderState.activeFolderId;
	const isFolderSelected = folderState.selectedFolderIds.length > 0;
	const isFileSelected  = folderState.selectedFileIds.length > 0;
	const enableButton = (!isFolderSelected && !isFileSelected && isInFolder);

	return (
		<span className="action-bar__newDocumentButton action-bar-button">
			<Button
				secondary
				id="newDocument"
				type="button"
				data-e2e-testid="actionBarNewDocumentButton"
				data-testid="actionBarNewDocumentButton"
				onClick={() => {
					setNewFolderTitlePart1('');
					setNewFolderTitlePart2('');
					setNewFolderModal(!newFolderModal);
				}}
				disabled={!enableButton}
			>
				<div className="action-bar-button__content">
					<img className="action-bar-button__icon" src={addIcon} alt="Add Document" />
					New folder
					<span className="u-visuallyhidden">Add new folder</span>
				</div>
			</Button>
			<Modal modalIsOpen={newFolderModal} closeModal={() => setNewFolderModal(false)}>
				<div className="modal__header amended-modal-header">
					<h3 className="modal__heading">New folder</h3>
				</div>
				<div className="modal__body new-folder-body">
					<label htmlFor="addNewFolderPart1" className="modal__label new-folder-label">
						Ordnername/Name des Kläger
					</label>
					<input
						type="text"
						id="addNewFolderPart1"
						name="addNewFolderPart1"
						className={`modal__input ${newFolderTitlePart1 === '' ? 'modal__input--error' : ''}`}
						onChange={(event) => setNewFolderTitlePart1(event.target.value)}
						maxLength={100}
						autoFocus={true}
						onKeyPress={({key}) => {
							if (key === 'Enter') handleCreateFolder();
						}}
					/>
					<label htmlFor="addNewFolderPart2" className="modal__label new-folder-label">
						Aktenzeichen Prozessbevollmächtigter
					</label>
					<input
						type="text"
						id="addNewFolderPart2"
						name="addNewFolderPart2"
						className="modal__input"
						onChange={(event) => setNewFolderTitlePart2(event.target.value)}
						maxLength={100}
						onKeyPress={({key}) => {
							if (key === 'Enter') handleCreateFolder();
						}}
					/>
				</div>
				<div className="modal__footer amended-modal-footer">
					<Button
						secondary
						type="button"
						className="modal__cancel-btn"
						onClick={() => setNewFolderModal(false)}
					>
						Cancel
					</Button>
					<Button
						primary="true"
						type="button"
						loading={isSaving}
						disabled={newFolderTitlePart1.trim() === '' ? true : false ?? isSaving}
						onClick={() => handleCreateFolder()}
					>
						{!isSaving && <>Save</>}
						{isSaving && <>Saving</>}
					</Button>
				</div>
			</Modal>
		</span>
	);
};

export default NewFolder;
