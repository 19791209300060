import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: '#1d2b4d',
      padding: '20px 30px',
      borderRadius: '2px',
    },
  })
);

const Banner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p style={{ fontSize: '0.9rem' }}>Liebe Userinnen und User, bitte wenden Sie sich bei Anfragen rund um den Datenraum sowie für die Freischaltung neuer User an unseren DieselTech ServiceDesk unter <a href='mailto:dieselcivilclaims@freshfields.com'>DieselCivilClaims@freshfields.com</a>. Vielen Dank!</p>
    </div>
  );
};

export default Banner;