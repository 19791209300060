interface CheckForExistingType {
	currentIds: string[];
	id: string;
}

export const addOrRemoveIfExistingFrom = ({ currentIds, id }: CheckForExistingType) => {
	if (currentIds.includes(id)) {
		return currentIds.filter((cId) => cId !== id);
	}
  return [id, ...currentIds]; 
};
