import React, { useContext, useRef } from 'react';
import queryString from 'query-string';
import { PERMISSIONS } from '../../enum';
import { useLocation, useHistory } from 'react-router-dom';
import { AppContext } from '../../authentication/AppProvider';
import { FolderContext, SearchContext, SEARCH_ACTIONS } from '../../context';
import { Search } from '@freshfields/react-component-library';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { isEmpty, join } from 'lodash';

const useStyles = makeStyles(() =>
	createStyles({
		files: {
			padding: '3px 0',
            marginLeft: '20px',
			'& input': {
				marginRight: '8px',
			},
			'& label': {
				display: 'inline-block',
				transform: 'translateY(-1px)',
                fontSize: '12px'
			},
		},
	})
);

const SearchInput: React.FC = () => {
	const classes = useStyles();
	let history = useHistory();
	const { user } = useContext(AppContext);
	const [{ folderTree }] = useContext(FolderContext);
	const [searchState, doSearchStateAction] = useContext(SearchContext);
	const inputValue = useRef(searchState.term);
	const { term, showDeleted, scopeId, isScoped } = searchState;
	const isAdmin = user?.profile?.roles.includes(PERMISSIONS.ADMIN);
	const location = useLocation();

    const cleanSearchTerms = (rawSearchTerms: string) => {

        // applies the same character replacement as the API
        // ensures the UI shows the terms as actually searched for
        if (isEmpty(rawSearchTerms)){
            return rawSearchTerms;
        }

        const cleanSearchTerms =
            rawSearchTerms
            .replaceAll('§', ' ')
            .replaceAll('-', ' ')
            .replaceAll('/', ' ')
            .replaceAll('<', ' ')
            .replaceAll('>', ' ')
            .replaceAll('.', ' ')
            .replaceAll(',', ' ');

        const terms = cleanSearchTerms.split(' ');
        const termsToKeep =
            terms.filter((t) => !isEmpty(t));
        const sanitisedTerms = join(termsToKeep, ' ');
        return sanitisedTerms.trim();

    };

	const submitNewQuery = () => {
      	let { pathname } = location;
        let newScopeId = pathname.includes('folder') ? pathname.replace('/folder/', '') : scopeId;
        const sanitisedTerms = cleanSearchTerms(inputValue.current);
        inputValue.current = sanitisedTerms;
        history.push(
            `/search?query=${inputValue.current}&deleted=${showDeleted}&scopeId=${newScopeId}&scoped=${isScoped}&page=1&itemsPerPage=25`,
            {
                folderTree,
                searchState,
            }
        );
    };

	return (
        <>
            <Search
                defaultValue={term}
                items={[]}
                searchItemValueToString={(selectedItem:any) => (selectedItem ? selectedItem.name : '')}
                onSubmit={() => {submitNewQuery();}}
                onInputValueChange={(value: string) => {
                    if (value !== '') inputValue.current = value;
                }}
                placeholderText="Search..."
            />
            <div id="search-check-options" className={classes.files}>
                <input
                    type="checkbox"
                    id="scope"
                    name="scope"
                    className="search-checkbox"
                    checked={isScoped}
                    onChange={() => {
                        let search = queryString.parse(location.search);
                        let { scopeId } = search;
                        if (location.pathname.includes('search')) {
                            history.push(
                                `/search?query=${
                                    searchState.term
                                }&deleted=${showDeleted}&scopeId=${scopeId}&scoped=${!isScoped}&page=1&itemsPerPage=25`,
                                {
                                    folderTree,
                                    searchState: {
                                        ...searchState,
                                        isScoped: !isScoped,
                                    },
                                }
                            );
                        } else {
                            doSearchStateAction({
                                type: SEARCH_ACTIONS.UPDATE_SEARCH_IS_SCOPED,
                                payload: !isScoped,
                            });
                            doSearchStateAction({
                                type: SEARCH_ACTIONS.UPDATE_SEARCH_PAGE,
                                payload: 1,
                            });
                        }
                    }}
                />
                <label htmlFor="scope">current folder only</label>
            </div>

            {isAdmin && (
                <div id="search-check-options" className={classes.files}>
                    <input
                        type="checkbox"
                        id="restore"
                        name="restore"
                        className="search-checkbox"
                        checked={showDeleted}
                        onChange={() => {
                            let search = queryString.parse(location.search);
                            let { scopeId } = search;
                            if (location.pathname.includes('search')) {
                                history.push(
                                    `/search?query=${
                                        searchState.term
                                    }&deleted=${!showDeleted}&scopeId=${scopeId}&scoped=${isScoped}&page=1&itemsPerPage=25`,
                                    {
                                        folderTree,
                                        searchState: {
                                            ...searchState,
                                            showDeleted: !showDeleted,
                                        },
                                    }
                                );
                            } else {
                                doSearchStateAction({
                                    type: SEARCH_ACTIONS.UPDATE_SEARCH_SHOW_DELETED,
                                    payload: !showDeleted,
                                });
                                doSearchStateAction({
                                    type: SEARCH_ACTIONS.UPDATE_SEARCH_PAGE,
                                    payload: 1,
                                });
                            }
                        }}
                    />
                    <label htmlFor="restore">show deleted files</label>
                </div>
            )}
        </>
    );
};

export default SearchInput;
