import React, { useContext, useState, useEffect } from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import compact from 'lodash/compact';
import { download, FileType, downloadFolder } from '../../../../api';
import { AppContext } from '../../../../authentication/AppProvider';
import { FolderContext } from '../../../../context/FolderContext';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { Button, ToastAtoms } from '@freshfields/react-component-library';
import downloadIcon from '../../../../images/download.svg';
import { fileDownload, createFilename } from '../../../../common/download';
interface DownloadItemsType {
	filteredIds?: number[] | null;
	selectedFolderOrFileName: string[];
}

const Download: React.FC = () => {
	const { user } = useContext(AppContext);
	const [folderState] = useContext(FolderContext);
	const { addToast } = ToastAtoms.useToasts();
	// NOTE: workaround for incorrect types on ToastableFeedbackBlock
	const ToastableFeedbackBlock = ToastAtoms.ToastableFeedbackBlock as any;
	const [isDownloading, setIsDownloading] = useState(false);
	let location = useLocation();
	let params = useParams<{ folderId?: string, downloadIds?: string}>();
	let history = useHistory();

	useEffect(() => {
		if (params?.downloadIds && folderState.folders.files.length > 0) {
			const downloadArray = params?.downloadIds.split('-').map(i => Number(i));
			// get the filenames from the view
			const names = downloadArray.map((id) => {
				const match = find(folderState.folders.files, { id });
				return `${match?.name}.${match?.type}`;
			});
			downloadItems({ filteredIds: downloadArray, selectedFolderOrFileName: names });
			history.replace(`/folder/${params?.folderId}`, {
				folderTree: folderState.folderTree,
			});
		}
		// eslint-disable-next-line
	}, [location, folderState.folders.files]);

	const downloadItems = ({ filteredIds, selectedFolderOrFileName }: DownloadItemsType) => {
		if (!filteredIds || filteredIds.length === 0) return;
		download({
			token: get(user, 'access_token'),
			selectedIds: filteredIds,
		})
			.then((response) => {
				const filename = createFilename({ selectedFileIds: filteredIds, selectedFolderOrFileName });
				fileDownload({
					data: response.data,
					filename,
					mime: response?.headers?.['content-type'],
				});
				addToast(
					<ToastableFeedbackBlock
						success
						headerText={<span className="u-heading-xs">Files downloaded</span>}
						bodyText={'You have successfully downloaded ' + filename}
					/>,
					{ autoDismiss: true }
				);
				setIsDownloading(false);
			})
			.catch((error) => {
				console.error(error);
				const filename = createFilename({ selectedFileIds: filteredIds, selectedFolderOrFileName });
				addToast(
					<ToastableFeedbackBlock
						error
						headerText={<span className="u-heading-xs">Download failed</span>}
						bodyText={'An error occurred while downloading ' + filename}
					/>,
					{ autoDismiss: true }
				);
				setIsDownloading(false);
			});
	};

	const handleDownload = async () => {
		if(isFileSelected) {
			handleDownloadFile();
		}
		else {
			handleDownloadFolder();
		}
	};

	const handleDownloadFile = async () => {
		const { selectedFileIds, selectedFolderOrFileName, folders } = folderState;
		const listOfAvActiveIds = folders?.files.map((file: FileType) => {
			return file?.pending || file?.quarantined ? file.id : null;
		});
		const filteredIds: number[] = compact(
			selectedFileIds.map((id: number) => {
				return listOfAvActiveIds.includes(id) ? null : id;
			})
		);
		if (filteredIds.length === 0) return;
		setIsDownloading(true);
		downloadItems({ filteredIds, selectedFolderOrFileName });
	};

	const handleDownloadFolder = async () => {
		const { selectedFolderOrFileName} = folderState;
		const selectedFolderId = folderState.selectedFolderIds[0];
		setIsDownloading(true);
		downloadFolder({
			token: get(user, 'access_token'),
			id: selectedFolderId
		})
			.then((response) => {
				if(response.status === 200) {
					const filename = `DieselClaims_${selectedFolderOrFileName}.zip` ;
					fileDownload({
						data: response.data,
						filename,
						mime: response?.headers?.['content-type'],
					});
					addToast(
						<ToastableFeedbackBlock
							success
							headerText={<span className="u-heading-xs">Folder downloaded</span>}
							bodyText={'You have successfully downloaded ' + filename}
						/>,
						{ autoDismiss: true }
					);
				} else if(response.message === 'Request failed with status code 413'){ 
					console.error(response.error);
					addToast(
						<ToastableFeedbackBlock
							error
							headerText={<span className="u-heading-xs">Download failed</span>}
							bodyText={'Download exceeds 1.5GB. Reduce your selection size and try again'}
						/>,
						{ autoDismiss: true }
					);
				} else if(response.message === 'Request failed with status code 404'){ 
					console.error(response.error);
					addToast(
						<ToastableFeedbackBlock
							error
							headerText={<span className="u-heading-xs">Download failed</span>}
							bodyText={'Folder or files not found'}
						/>,
						{ autoDismiss: true }
					);
				} else {
					console.error(response.error);
					const filename = `DieselClaims_${selectedFolderOrFileName}.zip` ;
					
					addToast(
						<ToastableFeedbackBlock
							error
							headerText={<span className="u-heading-xs">Download failed</span>}
							bodyText={'An error occurred while downloading ' + filename + '. ' + response.message}
						/>,
						{ autoDismiss: true }
					);
				}
				setIsDownloading(false);
			})
			.catch((error) => {
				console.error(error);
				const filename = `DieselClaims_${selectedFolderOrFileName}.zip` ;
				addToast(
					<ToastableFeedbackBlock
						error
						headerText={<span className="u-heading-xs">Download failed</span>}
						bodyText={'An error occurred while downloading ' + filename}
					/>,
					{ autoDismiss: true }
				);
				setIsDownloading(false);
			});

	};
	
	const oneFolderSelected = folderState.selectedFolderIds.length === 1;
	const isFolderSelected = folderState.selectedFolderIds.length > 0;
	const isFileSelected = folderState.selectedFileIds.length > 0;
	const enableButton = (oneFolderSelected && !isFileSelected) || (!isFolderSelected && isFileSelected);

	return (
		<span className="action-bar__downloadDocumentButton action-bar-button">
			<Button
				secondary
				id="downloadDocument"
				type="button"
				data-e2e-testid="actionBarDownloadDocumentButton"
				data-testid="actionBarDownloadDocumentButton"
				onClick={handleDownload}
				disabled={isDownloading || !enableButton}
				loading={isDownloading}
			>
				<div className="action-bar-button__content">
					<img className="action-bar-button__icon" src={downloadIcon} alt="Download Document" />
					{!isDownloading && <>Download</>}
					{isDownloading && <>Downloading</>}					
					<span className="u-visuallyhidden">download</span>
				</div>
			</Button>
		</span>
	);
};

export default Download;
