import React, { useContext } from 'react';
import compact from 'lodash/compact';
import { useHistory } from 'react-router-dom';
import ControlledPagination from '@material-ui/lab/Pagination';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { FolderContext, SearchContext } from '../../context';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '2rem',
    },
  })
);

export const Pagination = () => {
  let history = useHistory();
  const [folderState] = useContext(FolderContext);
  const [searchState] = useContext(SearchContext);
  const { activeFolderId, folders, folderTree } = folderState;
  const { totalPages, page } = folders;
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const order = folders?.order ? `order=${folders?.order}` : null;
    const asc = folders?.asc ? `asc=${folders?.asc}` : null;
    const page = value ? `page=${value}` : null;
    const itemsPerPage = `itemsPerPage=${folders?.itemsPerPage}`;
    const query = compact([order, asc, page, itemsPerPage]).join('&');
		history.push(`/folder/${activeFolderId}/?${query}`, {
			folderTree,
			searchState,
		});
	};

  if (totalPages === 1) {
		return null;
  }

  return (
    <div className="pagination-next-page">
      	<div className={classes.root}>
          <ControlledPagination count={totalPages} page={page} onChange={handleChange}  color="primary" />
        </div>
    </div>
	
  ); 
};
