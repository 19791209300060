import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { PERMISSIONS } from '../../../../enum';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { sortFolderListing } from '../../../../common/sortFolderListing';
import { getSelectedFileFrom, getSelectedFolderFrom } from '../../../../common/getSelectedFileFrom';
import { file, folder, FileType, FolderMetaDataType, RequestFileDataType, getBreadcrumbs } from '../../../../api';
import { FolderContext, FOLDER_ACTIONS } from '../../../../context/FolderContext';
import { AppContext } from '../../../../authentication/AppProvider';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Button, ToastAtoms } from '@freshfields/react-component-library';
import { DrawerPanel } from '../../../DrawerPanel';
import { PermissionsTable } from '../../../PermissionsTable';
import { History } from '../../../History';
import { TabPanel, tabA11yProps } from '../../../TabsPanel';
import { FileDetails } from '../../../FileDetails';
import { FolderDetails } from '../../../FolderDetails';
import { InlineEdit } from '../../../InlineEdit';
import infoIcon from '../../../../images/info.svg';
import { isEmpty } from 'lodash';
import split from 'lodash/split';
import last from 'lodash/last';
import { useLocation } from 'react-router-dom';

const Info: React.FC = () => {
    const { user } = useContext(AppContext);
    const isAdmin = user?.profile?.roles.includes(PERMISSIONS.ADMIN);
    const [folderState, doFolderStateAction] = useContext(FolderContext);
    const [toggleDrawer, setToggleDrawer] = useState(false);
    const [tabsPanelValue, setTabsPanelValue] = useState(0);
    const [isSelectItemSingular, setIsSelectItemSingular] = useState(false);
    const selected: React.MutableRefObject<{ file?: FileType; folder?: FolderMetaDataType; isFolder?: boolean }> = useRef({
        file: undefined,
        folder: undefined,
        isFolder: false,
    });
    const { folders, selectedFileIds, selectedFolderIds, activeFolderId } = folderState;
    const { files } = folders;
    const [documentTitle, setDocumentTitle] = useState('Edit');
    const { addToast } = ToastAtoms.useToasts();
    // NOTE: workaround for incorrect types on ToastableFeedbackBlock
    const ToastableFeedbackBlock = ToastAtoms.ToastableFeedbackBlock as any;
    const location = useLocation();

    const selectedItem: React.MutableRefObject<{ Id: number; Type: string  }> = useRef({
        Id : 0,
        Type: ''
    });
    interface ConvertFolderName {
        folderNamePart1: string;
        folderNamePart2: string;
        validLength: boolean;
    }

    useEffect(() => {
        selected.current = {
            file: getSelectedFileFrom({ files, selectedIds: selectedFileIds }),
            folder: getSelectedFolderFrom({ folders: folders.children, selectedIds: selectedFolderIds }),
        };
        selected.current.isFolder = !isNil(selected.current.folder);

        if (selected.current.file) {
            setDocumentTitle(selected.current.file?.name);
            selectedItem.current.Id = selected.current.file?.id;
            selectedItem.current.Type = 'file';
        }

        if (selected.current.folder) { 
            setDocumentTitle(selected.current.folder?.folderName);
            selectedItem.current.Id = selected.current.folder?.id ?? 0;
            selectedItem.current.Type = 'folder';
        }

        setIsSelectItemSingular(selectedFileIds.length + selectedFolderIds.length === 1);
        // eslint-disable-next-line
    }, [folderState]);

    useEffect(() => {
        let currentFolderId = activeFolderId;
        if (location.pathname === '/search') {
            currentFolderId = selected.current.isFolder ? selected.current.folder?.id : selected.current.file?.folderId;
            if (!currentFolderId) {
                return;
            }
        }
        getBreadcrumbs({ id: currentFolderId, token: get(user, 'access_token') }).then(
            (response) => {
                const { data } = response;
                doFolderStateAction({
                    type: FOLDER_ACTIONS.SET_BREADCRUMBS,
                    payload: data
                });
            }
        );
    }, [doFolderStateAction, user, selectedFileIds, selectedFolderIds, activeFolderId, location.pathname]);

    const changeFileTitle = (selectedFile: FileType, title: string) => {
        file({
            token: user?.access_token,
            id: selectedFile.id,
            details: {
                ...selectedFile,
                name: title,
            },
        })
            .then(({ data: update }) => {
                setDocumentTitle(update?.name);
                const folderListing = folderState.folders.files.map((file: FileType) => {
                    return update.id === file?.id ? update : file;
                });
                doFolderStateAction({
                    type: FOLDER_ACTIONS.FETCH_FILES,
                    payload: sortFolderListing(folderListing),
                });
            })
            .catch((error) => {
                console.error(error);
                addToast(
                    <ToastableFeedbackBlock
                        error
                        headerText={<span className="u-heading-xs">Rename failed</span>}
                        bodyText={'Error renaming file'}
                    />,
                    { autoDismiss: true }
                );
            });
    };

    const changeFolderTitle = (selectedFolder: RequestFileDataType, folderNamePart1: string, folderNamePart2: string) => {
        if (isNil(selectedFolder.id)) return;
        folder({
            token: user?.access_token,
            id: selectedFolder.id ?? -1,
            details: {
                ...selectedFolder,
                folderNamePart1: folderNamePart1,
                folderNamePart2: folderNamePart2,
            },
        })
            .then(({ data: update }) => {
                setDocumentTitle(update?.folderName);
                const folderListing = folderState.folders.children.map((folder: FolderMetaDataType) => {
                    return update.id === folder?.id ? update : folder;
                });
                doFolderStateAction({
                    type: FOLDER_ACTIONS.FETCH_FOLDERS,
                    payload: {
                        ...folderState.folders,
                        children: folderListing,
                    },
                });
            })
            .catch((error) => {
                console.error(error);
                addToast(
                    <ToastableFeedbackBlock
                        error
                        headerText={<span className="u-heading-xs">Rename failed</span>}
                        bodyText={'Error renaming folder'}
                    />,
                    { autoDismiss: true }
                );
            });
    };

    const convertFolderTitle = (title: string) : ConvertFolderName => {
        const maxNameLength = 100;
        const separator = ' - ';
        const nameParts = split(title, separator);

        // no <<space hyphen space>> , then cannot be split
        if (nameParts.length === 1) {
            return { folderNamePart1: title, folderNamePart2: '', validLength: (title.length <= maxNameLength)};
        }

        let folderNamePart2 = last(nameParts) ?? '';
        folderNamePart2 = folderNamePart2.trim();

        // everything apart from the folderNamePart2 substring is folderNamePart1
        const folderNamePart1Parts = nameParts.slice(0, (nameParts.length - 1));
        const folderNamePart1 = folderNamePart1Parts.join(separator).trim();

        return { folderNamePart1: folderNamePart1, folderNamePart2: folderNamePart2, validLength: (folderNamePart1.length + folderNamePart2.length <= maxNameLength)};
    };

    const handleTitleChange = (title: string) => {
        if (isEmpty(title)) {
            addToast(
                <ToastableFeedbackBlock
                    error
                    headerText={<span className="u-heading-xs">Rename failed</span>}
                    bodyText="Name cannot be blank"
                />,
                { autoDismiss: true }
            );
            return;
        }
        if (selected.current.file) changeFileTitle(selected.current.file, title);
        if (selected.current.folder) {
            const { folderNamePart1, folderNamePart2, validLength} = convertFolderTitle(title);
            if (!validLength) {
                addToast(
                    <ToastableFeedbackBlock
                        error
                        headerText={<span className="u-heading-xs">Rename failed</span>}
                        bodyText="Folder name cannot be greater than 100 characters"
                    />,
                    { autoDismiss: true }
                );
                return;
            }
            changeFolderTitle(selected.current.folder, folderNamePart1, folderNamePart2);
        }
        setDocumentTitle(title);
    };

    return (
        <span className="action-bar__info-button">
            {isSelectItemSingular && (
                <Button
                    secondary
                    id="share"
                    type="button"
                    data-e2e-testid="actionBarInfoButton"
                    data-testid="actionBarInfoButton"
                    onClick={() => setToggleDrawer(!toggleDrawer)}
                >
                    <div className="action-bar-button__content">
                        <img src={infoIcon} alt="Info" />
                        <span className="u-visuallyhidden">info</span>
                    </div>
                </Button>
            )}
            <DrawerPanel toggleDrawer={toggleDrawer} setToggleDrawer={setToggleDrawer}>
                <div className="tabs-panel default-drawer">
                    <h3 className="tabs-panel__heading">
                        <InlineEdit text={documentTitle} onSetText={(value: string) => handleTitleChange(value)} />
                    </h3>
                    <AppBar position="static">
                        <Tabs value={tabsPanelValue} onChange={(_, newValue) => setTabsPanelValue(newValue)} aria-label="tabs">
                            <Tab label="Details" {...tabA11yProps(1)} />
                            <Tab label="History" {...tabA11yProps(2)} />
                            {isAdmin && <Tab label="Permissions" {...tabA11yProps(0)} />}
                        </Tabs>
                    </AppBar>
                    
                    <TabPanel value={tabsPanelValue} index={0}>
                        {selected.current.isFolder && <FolderDetails item={selected.current?.folder} />}
                        {!selected.current.isFolder && <FileDetails item={selected.current?.file} />}
                    </TabPanel>

                    <TabPanel value={tabsPanelValue} index={1}>
                            <History itemId={selectedItem.current.Id} itemType={selectedItem.current.Type} />
                    </TabPanel>
                    
                    {isAdmin && (
                        <TabPanel value={tabsPanelValue} index={2}>
                            <PermissionsTable onDone={() => setToggleDrawer(!toggleDrawer)} />
                        </TabPanel>
                    )}




                </div>
            </DrawerPanel>
        </span>
    );
};

export default Info;
