import React, { useEffect, useContext } from 'react';
import queryString from 'query-string';
import { useParams, useLocation } from 'react-router-dom';
import { FolderContext, SearchContext, SEARCH_ACTIONS } from '../../context';
import { loadAndDisplaySelectedFolder } from '../../common/loadAndDisplaySelectedFolder';

import { AppContext } from '../../authentication/AppProvider';

const ContextFromRouterState: React.FC = () => {
    let { folderId } = useParams();
    let location = useLocation();

    const { user } = useContext(AppContext);
    const [, doFolderStateAction] = useContext(FolderContext);
    const [, doSearchStateAction] = useContext(SearchContext);
    const locationState: any = location?.state;

    useEffect(() => {
        loadAndDisplaySelectedFolder({ id: Number(folderId), location, access_token: user?.access_token, doFolderStateAction });
        // eslint-disable-next-line
    }, [folderId]);

    useEffect(() => {
        if (locationState?.searchState) {
            const search = queryString.parse(location.search);
            doSearchStateAction({
                type: SEARCH_ACTIONS.UPDATE_SEARCH_STATE,
                payload: { ...locationState?.searchState, isDeleted: search?.deleted, term: search?.query },
            });
        }
        // eslint-disable-next-line
    }, [location]);

    return null;
};

export default ContextFromRouterState;
