import React from 'react';
import './App.css';
import AppProvider from './authentication/AppProvider';
import TelemetryProvider from './telemetry-provider';
import { ToastAtoms } from '@freshfields/react-component-library';
import { AuthenticatedRoutes } from './authentication/AuthenticatedRoutes';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import userManager from './authentication/getUserManager';
import { getConfig } from './common/config';
import ErrorBoundary from './components/ErrorBoundary';

const App = () => {
    const browserHistory = createBrowserHistory({ basename: '' });
    const instrumentationKey = getConfig().appInsightsInstrumentationKey;
	console.log(userManager);
    return (
		<ErrorBoundary>
			<ToastAtoms.ToastProvider placement="top-right">
				<Router history={browserHistory}>
					<AppProvider userManager={userManager}>
						<TelemetryProvider instrumentationKey={instrumentationKey} after={() => {}}>
							<AuthenticatedRoutes />
						</TelemetryProvider>
					</AppProvider>
				</Router>
			</ToastAtoms.ToastProvider>
		</ErrorBoundary>
	);
};

export default App;
