import React, { useContext } from 'react';
import { LoadingSpinner } from '../LodingSpinner';
import { Table } from '../Table';
import { FolderContext, SearchContext } from '../../context';
import { Pagination } from '../Pagination';
import { Sort } from './components/Sort';
import compact from 'lodash/compact';
import { useHistory, useLocation } from 'react-router-dom';
import { NumberResultsSelect } from '../NumberResultsSelect';
import { Input, InputLabel } from '@material-ui/core';
import queryString from 'query-string';

export const FileTable = () => {
	const [folderState] = useContext(FolderContext);
	const { activeFolder, folders, folderTree } = folderState;
	const [searchState] = useContext(SearchContext);
	let history = useHistory();
	let page = 1;
	let location = useLocation();
    const parsedQueryString = queryString.parse(location.search);
    const pageFromQuery = Number(parsedQueryString.page) ?? 1;
    let itemsPerPage = folders?.itemsPerPage;

	if (folders.loading) {
		return <LoadingSpinner />;
	}
	if (!activeFolder) {
		return <p className="text-center my-12">Please select a folder to display contents</p>;
	}

	if (folders?.files.length === 0 && folders?.children.length === 0) {
		return (
			<p className="text-center my-12">
				No files / folders available inside
				<br />
				{activeFolder?.folderName}
			</p>
		);
	}

	const handleItemsPerPageChange = (itemsPerPageSelection: string) => {
		folders.itemsPerPage = Number(itemsPerPageSelection);
		const order = folders?.order ? `order=${folders?.order}` : null;
        const asc = folders?.asc ? `asc=${folders?.asc}` : null;
		const pageQuery = `page=${page}`;
		const itemsPerPageQuery = `itemsPerPage=${folders.itemsPerPage}`;
		const query = compact([pageQuery, order, asc, itemsPerPageQuery]).join('&');
		
		history.push(`/folder/${activeFolder.id}/?${query}`, {
                folderTree,
                searchState,
		});
	};

	const handlePageChangeSubmit = (e: any) => {
		if(e.key === 'Enter' || e.key === 'Tab'){
			handlePageChange();
		}
	};

	const handlePageChange = () => {
		const order = folders?.order ? `order=${folders?.order}` : null;
		const asc = folders?.asc ? `asc=${folders?.asc}` : null;
		const pageQuery = `page=${page}`;
		const itemsPerPageQuery = `itemsPerPage=${folders.itemsPerPage as string}`;
		const query = compact([pageQuery, order, asc, itemsPerPageQuery]).join('&');
		history.push(`/folder/${activeFolder.id}/?${query}`, {
				folderTree,
				searchState,
				});			
	};

	const handleOnGotoPageChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const pages = Math.ceil(folders.totalItems/folders.itemsPerPage);
		if(parseInt(e.target.value) > pages){
			page=pages;	
			e.target.value = pages.toString();
			return;
		}
		
		if (e.target.value !== '') {
			const num = parseInt(e.target.value);
			page = num < 1 ? 1 : num;
			e.target.value = num < 1 ? '1' : num.toString();
		}
	};

	const resultCount = () => {
		const currentPage = isNaN(pageFromQuery) ? 1 : pageFromQuery;
		const itemsPerPage = folders.itemsPerPage;
		const firstValue = (itemsPerPage * currentPage) - itemsPerPage + 1;
		var filesAndFoldersOnPage = folders?.children.length +folders?.files.length;
		const secondValue = ((filesAndFoldersOnPage) >= itemsPerPage) ? 
			itemsPerPage * currentPage : 
			(itemsPerPage * (currentPage - 1)) + filesAndFoldersOnPage;

		return (<div className="rc-components"><p>Showing</p><span className="pagination-bold">{`${firstValue}`}</span> - <span className="pagination-bold">{`${secondValue}`}</span> of <span className="pagination-bold">{`${folders.totalItems}`}</span> items</div>);
	};

	return (
		<>	
			<Sort />
			
			<Table results={{ folders: folders?.children, files: folders?.files }} />
			<div className="pagination-page-bottom">
				<div id="cc-pagination" className="pagination-bottom">	
					<div className="pagination-item">
						<InputLabel id="go-to-page">Go to page</InputLabel>
						<Input 
							type="number" 
							id="go-to-page-input"
							onChange={(e) => {
								handleOnGotoPageChange(e);
							}}
							onKeyPress={(e) => {handlePageChangeSubmit(e);}}
						/>	
					</div>	
					<div  className="pagination-item search-result-count">
						<div id="resultCount">{resultCount()}</div>
					</div>
					<div  className="number-result-select-container">
						<NumberResultsSelect onItemsPerPageChange={handleItemsPerPageChange} itemsPerPageValue={itemsPerPage} />
					</div>
				</div>
				<Pagination />
			</div>
		</>
	);
};
