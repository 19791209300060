import React, { Component } from 'react';
import { AppConsumer } from './AppProvider';
import { Redirect } from 'react-router-dom';
import { User } from 'oidc-client';

interface Props {
  redirectTo: string;
  signInRedirectCallback(): Promise<User>;
}

interface State {
  signedIn: boolean,
  redirectTo: string,
  message: string
}

export class Callback extends Component<Props, State> {

  state: State

  constructor(props: Props) {
    super(props);

    this.state = {
      signedIn: false,
      message: '',
      redirectTo: props.redirectTo
    };
  }

  render() {
    const { signedIn, redirectTo } = this.state;

    if (signedIn) {
      return <Redirect to={redirectTo} />;
    }

    this.props.signInRedirectCallback()
      .then(() => {
        this.setState({ signedIn: true });
      });
    
    return null;
  }
}

export const SignInRedirectCallback = () => {
  return (
    <AppConsumer>
      {({ signInRedirectCallback, getRedirectPath }) => {
        return <Callback signInRedirectCallback={signInRedirectCallback} redirectTo={getRedirectPath()} />;
      }}
    </AppConsumer>
  );
};