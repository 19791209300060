interface OidcConfig {
  clientId: string;
  redirectPath: string;
  scope: string;
  silentRenewPath: string;
  metadataUrl: string;
  authority: string;
  responseType: string;
}

export interface EnvConfig {
  appInsightsInstrumentationKey: string;
  message: string;
  environment: string;
  oidc: OidcConfig;
  apiBase : string;
  hubBase : string;
}

export const getConfig = (): EnvConfig => (window as any).env;