import { FileType } from '../api';

export enum SORT_BY {
    NAME = 'name',
    LAST_MODIFIED = 'lastModifiedDate',
}

export enum SORT_DIRECTION {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

export interface FolderMetaDataType {
    id: number;
    folderName: string;
    parentFolderId: number;
    children?: FolderMetaDataType[];
}

export interface FoldersType {
    metaData: {};
    files: FileType[];
    loading: boolean;
    children?: FolderMetaDataType[];
    page: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
    order?: SORT_BY | null;
    asc?: SORT_DIRECTION | null;
}

export interface FolderTreeDataType {
    title: string;
    id: number;
    parentFolderId: number;
    children: FolderTreeDataType[];
    expanded: boolean;
}

export interface FolderStateType {
    activeFolder?: FolderMetaDataType;
    activeFolderId?: string;
    activeFile?: string;
    folders: FoldersType;
    selectedFileIds: number[];
    selectedFolderIds: number[];
    selectedFolderOrFileName: string[];
    breadcrumbs?: any;
    folderTree: {
        data: FolderTreeDataType[];
        loading: boolean;
        error: boolean;
    };
}
