import React, { useState, useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { matchingFolders } from '../../api';
import get from 'lodash/get';
import { AppContext } from '../../authentication/AppProvider';
import { Search } from '@freshfields/react-component-library';
import head from 'lodash/head';

interface matches {
    id: number,
    folderName: string,
    location: string,
    resultsLimitedTo: number, 
    totalMatching: number,
};

interface props {
    OnSelectionHandler: (folderId: number, folderName: string) => void,
    OnClearSelectionHandler: () => void,
    OnNotifyAdditionalResults: () => void,
    OnClearNotifyAdditionalResults: () => void
}

const FolderRetriever: React.FC<props> = ({OnSelectionHandler, OnClearSelectionHandler, OnNotifyAdditionalResults, OnClearNotifyAdditionalResults}) => {
    const defaultCaption = 'search folders ...';
    const { user } = useContext(AppContext);
    const [term, setTerm] = useState('');
    const [caption, setCaption] = useState(defaultCaption);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState<matches>();

    function clearOptions() {
        setOptions([]);
    }

    function setCaptionToDefault() {
        setCaption(defaultCaption);
    }

    function setCaptionToSelected() {
        setCaption('folder selected...');
    }

    function clearSelection() {
        OnClearSelectionHandler();
        clearOptions();
        setCaptionToDefault();
        setSelectedFolder(undefined);

    }

    function handleSearch() {
        if (!term) {
            clearSelection();
            return;
        }
        (async () => {
            setLoading(true);
            matchingFolders({token: get(user, 'access_token'), searchTerm: term})
            .then((response) => {
                handleMoreResultsThanShown(response.data);
                setOptions(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
        })();
    };

    function handleMoreResultsThanShown(data: matches[]) {
        const numberOfItems = data.length ?? 0;
        if (numberOfItems === 0) {
            OnClearNotifyAdditionalResults();
            return;
        }
        const { resultsLimitedTo, totalMatching } :any = head(data);

        if (totalMatching > resultsLimitedTo) {
            OnNotifyAdditionalResults();
        } else {
            OnClearNotifyAdditionalResults();
        }
    }

    function handleResultsClick(option: matches) {
        setCaptionToSelected();
        OnSelectionHandler(option.id, option.folderName);
        setSelectedFolder(option);
    }

    return (
        <div className="search-autocomplete-container">
            <Search
                defaultValue={term}
                items={[]}
                searchItemValueToString={(selectedItem:any) => (selectedItem ? selectedItem.name : '')}
                onSubmit={() => {handleSearch();}}
                onInputValueChange={(value: string) => {
                    if (value !== '') {
                        setTerm(value);
                    } else {
                        clearSelection();
                    }
                }}
                placeholderText={caption}
            />
              
            <div className="search-results-container">
                
                {loading && <CircularProgress color="inherit" size={20} />}
                <div className="dropdown-container">
                    <ul className="search-results-dropdownList sticky">
                    {options.map((option: matches) => {
                        return(
                            <li key={option.id} onClick={() => handleResultsClick(option)} ><span className="sr-foldername">{option.folderName}</span><span className="sr-location">{option.location}</span> </li>
                        );
                    })}
                </ul>
                </div>
            </div>
            <div className="mui-style-location">
                <div className="mui-style-label">Selected location: </div>
                <div className="mui-style-selection">
                    { selectedFolder && (<span className="selection-foldername">{selectedFolder.folderName}</span>)}
                    { selectedFolder && (<span className="selection-location">{selectedFolder.location}</span>)}
                </div>
            </div>
        </div>
    );
};

export default FolderRetriever;
