import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import axiosWithConfig from '../common/axiosWithConfig';
import * as api from './api.types';

export const getFolderTree = async ({ token }: api.RequestType) => {
	if (isNil(token)) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.get('/v1/folders/basiclist');
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const permissions = async ({ id: folderId, token }: api.RequestIdType) => {
	if (isNil(token) || isNil(folderId) || isNaN(folderId)) return { data: [] };
	try {
		const axios = axiosWithConfig(token);
		return await axios.get(`/v1/permissions/${folderId}`);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const jobs = async ({ token }: api.RequestType) => {
	if (isNil(token)) return { data: [] };
	try {
		const axios = axiosWithConfig(token);
		return await axios.get('/v1/jobs/');
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const fileHistory = async ({ id: itemId, token }: api.RequestIdType) => {
	if (isNil(token) || isNil(itemId) || isNaN(itemId)) return { data: [] };
	try {
		const axios = axiosWithConfig(token);
		return await axios.get(`/v1/files/${itemId}/history`);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const folderHistory = async ({ id: itemId, token }: api.RequestIdType) => {
	if (isNil(token) || isNil(itemId) || isNaN(itemId)) return { data: [] };
	try {
		const axios = axiosWithConfig(token);
		return await axios.get(`/v1/folders/${itemId}/history`);
	} catch (error) {
		console.error(error);
		return error;
	}
};


export const getFilesByFolder = async ({
	id,
	page = 1,
	order = 'lastModifiedDate',
	asc = false,
	itemsPerPage=25,
	token,
}: api.RequestQueryType) => {
	if (isNil(token) || isNil(id) || isNaN(id)) return { data: [] };
	try {
		const date = Date.now();
		const axios = axiosWithConfig(token);
		return await axios.get(`/v1/folders/${id}/items?page=${page}&order=${order}&asc=${asc}&itemsPerPage=${itemsPerPage}&timestamp=${date}`);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const getRelativeFolders = async ({ id, token }: api.RequestIdType) => {
	if (isNil(token) || isNil(id) || isNaN(id)) return { data: [] };
	try {
		const axios = axiosWithConfig(token);
		return await axios.get(`/v1/folders/${id}/RelativeFolders`);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const getBreadcrumbs = async ({ id, token }: api.RequestIdType) => {
	if (isNil(token) || isNil(id) || isNaN(id)) return { data: [] };
	try {
		const axios = axiosWithConfig(token);
		return await axios.get(`/v1/folders/${id}/breadcrumbs`);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const downloadFolder = async ({ token, id }: api.RequestIdType) => {
	if (!token || !id) return null;
	try {
		const axios = axiosWithConfig(token);
		return await axios.get(
			`/v1/folders/download/${id}`,
			{
				responseType: 'blob',
				headers: {
					'content-type': 'application/json',
					accept: '*/*',
				},
			}
		);
	} catch (error) {
		console.error(error);
		return error;
	}
};
