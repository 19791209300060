import React, { useState } from 'react';
import { Modal } from '../Modal';
import { Button } from '@freshfields/react-component-library';
import './NewTag.style.css';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';

interface TagTarget {
    id: number;
    TagHandler: (itemId: number, tagText: string) => void
}

const useStyles = makeStyles((theme) => ({
	chip: {
	  margin: theme.spacing(0.5),
	},
  }));

const NewTag: React.FC<TagTarget> = ({ id, TagHandler }) => {
    const [newTagModal, setNewTagModal] = useState(false);
    const [newTag, setNewTag] = useState('');

    const handleCreateTag = () => {
        setNewTagModal(false);
        TagHandler(id, newTag);
    };
    const classes = useStyles();

    return (
        <>
            <Chip
                size="small"
                icon={<AddIcon />}
                color="primary"
                onClick={() => {
					setNewTag('');
					setNewTagModal(!newTagModal);
				}}
                label={'Add'}
                className={classes.chip}
                />
            <Modal modalIsOpen={newTagModal} closeModal={() => setNewTagModal(false)}>
				<div className="modal__header amended-modal-header">
                    <h3 className="modal__heading">New Tag</h3>
				</div>
				<div className="modal__body amended-modal-body footer-color">
					<label htmlFor="addNewTag" className="modal__label">
						Tag
					</label>
					<input
						type="text"
						id="addNewTag"
						name="addNewTag"
						className={`modal__input ${newTag === '' ? 'modal__input--error' : ''}`}
						onChange={(event) => setNewTag(event.target.value)}
						maxLength={30}
						autoFocus={true}
						onKeyPress={({key}) => {
							if (key === 'Enter') handleCreateTag();
						}}
					/>
				</div>
				<div className="modal__footer amended-modal-footer">
					<Button
						secondary
						type="button"
						className="modal__cancel-btn"
						onClick={() => setNewTagModal(false)}
					>
						Cancel
					</Button>
					<Button
						primary="true"
						type="button"
						disabled={newTag === '' ? true : false}
						onClick={() => handleCreateTag()}
					>
						<>Save</>
					</Button>
				</div>
            </Modal>
        </>
    );
};

export default NewTag;