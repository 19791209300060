import React, { useEffect, useContext, useState } from 'react';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../authentication/AppProvider';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { LoadingSpinner } from '../LodingSpinner';
import { FolderContext, SearchContext, FOLDER_ACTIONS, FolderMetaDataType } from '../../context';
import { getFolderTree, getRelativeFolders, getBreadcrumbs } from '../../api';
import SortableTree, { changeNodeAtPath, TreeItem } from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import './FolderTree.style.css';

interface TreeItemDataType extends FolderMetaDataType {
	children: TreeItemDataType[];
	hasChildren: boolean;
}

interface DataTreeViewType {
	data: TreeItemDataType[];
	loading: boolean;
	error: boolean;
}

interface FolderTreeProps {
	onItemClick?: (arg0: FolderMetaDataType) => void;
	independent?: boolean;
}

interface FolderTreeDataProps {
	onItemClick?: (arg0: FolderMetaDataType, arg1?: any) => void;
	independent?: boolean;
	items: DataTreeViewType;
}

export const transformToTreeData = (items: TreeItemDataType[], isAlwaysExpandable?: boolean): any => {
	if (!items) return [];
	return items.map((item) => ({
		title: item.folderName,
		id: item.id,
		parentFolderId: item.parentFolderId,
		children: item.hasChildren ? [{ title: '' }] : transformToTreeData(item.children),
	}));
};

export const getNodeKey = (info: any) => info.node.id;

export const FolderTree: React.FC<FolderTreeDataProps> = ({ items, onItemClick, independent }) => {
	const { user } = useContext(AppContext);
	let history = useHistory();
	const [searchState] = useContext(SearchContext);
	const [folderState, doFolderStateAction] = useContext(FolderContext);
	const { loading, error, folderTree, activeFolder } = folderState;
	const [treeData, setTreeData] = useState<TreeItem[]>([]);

	useEffect(() => {
		if (!independent && folderTree.data.length === 0) {
			doFolderStateAction({
				type: FOLDER_ACTIONS.UPDATE_FOLDER_TREE,
				payload: {
					...folderTree.data,
					data: transformToTreeData(items.data),
				},
			});
		} else {
			setTreeData(transformToTreeData(items.data));
		}

	}, [items, doFolderStateAction, folderTree.data, independent]);

	useEffect(() => {

		getBreadcrumbs({ id: activeFolder ?.id, token: get(user, 'access_token') }).then(
			(response) => {
				const { data } = response;
				doFolderStateAction({
					type: FOLDER_ACTIONS.SET_BREADCRUMBS,
					payload: data
				});
			}
		);

	}, [doFolderStateAction, activeFolder, user]);

	if (loading) return <LoadingSpinner />;
	if (error) return <p>Issue loading folders, please re-try</p>;

	return (
		<div
			id='cc-folder-tree'
			className={independent ? 'folder-tree__independent' : ''}
		>
			<SortableTree
				treeData={independent ? treeData : folderTree.data}
				canDrag={({ node }) => false}
				canDrop={() => false}
				onChange={(treeData: TreeItemDataType[]) => {
					if (!onItemClick) {
						doFolderStateAction({
							type: FOLDER_ACTIONS.UPDATE_FOLDER_TREE,
							payload: {
								...folderTree,
								data: treeData,
							},
						});
					}
				}}
				getNodeKey={getNodeKey}
				onVisibilityToggle={(rowInfo: any) => {
					getRelativeFolders({ id: rowInfo.node.id, token: get(user, 'access_token') }).then(
						(response) => {
							const { data } = response;
							// NOTE: expand hasn't been reflected in the data yet
							var tree: any = changeNodeAtPath({
								treeData: independent ? treeData : folderTree.data,
								path: rowInfo.path,
								getNodeKey,
								newNode: {
									...rowInfo.node,
									expanded: rowInfo.expanded,
									children: transformToTreeData(data.folders, true),
								},
							});
							if (!independent) {
								doFolderStateAction({
									type: FOLDER_ACTIONS.UPDATE_FOLDER_TREE,
									payload: {
										...folderTree,
										data: tree,
									},
								});
							} else {
								setTreeData(tree);
							}
						}
					);
				}}
				generateNodeProps={(rowInfo) => {
					const { node } = rowInfo;
					return {
						title: [
							<a
								key={`gp:${node.id}`}
								href={`/folder/${node.id}`}
								onClick={(e) => {
									e.preventDefault();
									if (!onItemClick) {

										history.push(`/folder/${node.id}`, {
											folderTree: {
												loading: false,
												error: false,
												data: folderTree.data,
											},
											searchState,
										});
									} else {
										onItemClick(
											{
												id: node.id,
												folderName: node.name,
												parentFolderId: node.parentFolderId,
											},
											{
												folderTree: {
													...folderTree,
													data: folderTree.data,
												},
												searchState,
											}
										);
									}
								}}
								title={`${node.title}`}
							>
								{rowInfo.node.title}
							</a>,
						],
					};
				}}
				theme={FileExplorerTheme}
			/>
		</div>
	);
};

const FolderTreeWithData: React.FC<FolderTreeProps> = ({ onItemClick, independent }) => {
	const { user } = useContext(AppContext);
	const [error, setError] = useState(false);
	const [items, setItems] = useState({
		data: [],
		loading: true,
	});

	useEffect(() => {
		getFolderTree({ token: get(user, 'access_token') })
			.then(({ data }) => {
				setItems({
					data,
					loading: false,
				});
			})
			.catch((msg) => {
				console.error(msg);
				setError(true);
			});
		// eslint-disable-next-line
	}, []);
	return <FolderTree
		items={{ ...items, error }}
		onItemClick={onItemClick}
		independent={independent}
	/>;
};

export default FolderTreeWithData;
