import React, { useState, useEffect, useContext } from 'react';
import { Badge } from '@freshfields/react-component-library/dist';
import { fileHistory, folderHistory } from '../../api';
import { AppContext } from '../../authentication/AppProvider';
import { getInitials } from '../../common/getInitials';
import { getInitialsReversed } from '../../common/getInitialsReversed';
import { ActionLine } from '../ActionLine/ActionLine';
import { Time } from '../Time';
import { format, parse } from 'date-fns';
import './History.css';

interface groupedAuditItemTypes {
	date : string;
	itemsByDate : any
}
interface auditItemType {
	itemId: number;
	description: string;
    userName: string;
    rowKey: string;
    timestamp: Date;
}

interface HistoryPropsType {
    itemId: number;
    itemType: string;
}


export const History: React.FC<HistoryPropsType> = ({ itemId, itemType }) => {
	const { user } = useContext(AppContext);
	const [groupedAuditItems, setAuditItemTypes] = useState<groupedAuditItemTypes[]>([]);

	const getHistory = () => {
		if (itemId && user) {
            if (itemType === 'folder'){
                folderHistory({ token: user?.access_token, id: itemId })
                .then((response) => {
					let data = response.data;
					if (data) {
						var groupedData = groupByDate(data);
						setAuditItemTypes(groupedData);
					}
				});
            }

            if (itemType === 'file'){
                fileHistory({ token: user?.access_token, id: itemId })
                .then((response) => {
					let data = response.data;
					if (data) {
						var groupedData = groupByDate(data);
						setAuditItemTypes(groupedData);
					}
				});
			}
		}
	};

	function groupByDate(data : auditItemType[]) {
		const groups = data.reduce((groups : any, auditItem : any) => {
			const date = auditItem.timestamp.split('T')[0];
			if (!groups[date]) {
			  groups[date] = [];
			}
			groups[date].push(auditItem);
			return groups;
		  }, {});
		  
		  const groupArrays = Object.keys(groups).map((date) => {
			return {
			  date,
			  itemsByDate: groups[date]
			};
		  });

		 return groupArrays;
	}

	useEffect(() => {
		getHistory();
		// eslint-disable-next-line
	}, [user]);

	return groupedAuditItems?.length > 0 ? (
		<>								
			<ActionLine>
				<ul>
					<li className="group-by-day__devider">&nbsp;</li>
					{
					groupedAuditItems.map((auditItems) => {
						return (
							<>	
								<li className="group-by-day__title">
									{format(parse(auditItems.date, 'yyyy-MM-dd', new Date()), 'DDDD, dd MMMM yyyy')}
									<hr className="group-by-day__line"></hr>
								</li>

								{
									auditItems.itemsByDate.map((auditItem : any) => {
									return (
											<li className="action-report" data-testid="actionReport" data-e2e-testid="recentActivityPageLogLine">
												<div className="action-report__badge" aria-hidden="true">
												<Badge small text={auditItem.emailAddress.toLowerCase().includes('freshfields.com') ?  getInitialsReversed(auditItem.userName) : getInitials(auditItem.userName)} numberOfLetters={2}></Badge>
												</div>
												<div className="action-report__content">
												<div className="action-report__line">
													<div className="action-report__text">
														<span className="action-report__text-action">{auditItem.description}</span>
														<span className="action-report__text">by {auditItem.userName} { auditItem.groupName?.length > 0 && <>({auditItem.groupName})</>}</span>
														<span className="action-report__time"><Time date={auditItem.timestamp} accessibilityContext="at" /></span>
													</div>
													</div>
												</div>
											</li>
										);
									})
								}

							</>
						);
					})
				}
				<li className="group-by-day__devider">&nbsp;</li>
				</ul>
			</ActionLine>
		</>
		
	) : <><p className={'group-by-day__title'}>No audit data for this item.</p></>;

};
