import React, { useContext, useState } from 'react';
import filter from 'lodash/filter';
import { Button, ToastAtoms } from '@freshfields/react-component-library';
import { AppContext } from '../../../../authentication/AppProvider';
import { FolderContext, FOLDER_ACTIONS, FolderMetaDataType, SearchContext, SEARCH_ACTIONS } from '../../../../context';
import deleteIcon from '../../../../images/delete.svg';
import { deleteFileItem, deleteFolderItem } from '../../../../api';
import { removeNode, find } from 'react-sortable-tree';
import { getNodeKey } from '../../../FolderTree';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../../Modal';

interface DeleteResponse {
	data: {
		succeeded : boolean;
		partiallySucceeded: boolean;
		failed: boolean;
		deletedIds: number[];
		failedDeletedIds: number[];
	};
}
const searchId = ({ node, path, treeIndex, searchQuery }: any) => {
	return node.id === searchQuery;
};

const DeleteInSearch: React.FC = () => {
	const history = useHistory();
	const { user } = useContext(AppContext);
	const [searchState, doSearchStateAction] = useContext(SearchContext);
	const [folderState, doFolderStateAction] = useContext(FolderContext);
	const { folderTree } = folderState;
	const { addToast } = ToastAtoms.useToasts();
	// NOTE: workaround for incorrect types on ToastableFeedbackBlock
	const ToastableFeedbackBlock = ToastAtoms.ToastableFeedbackBlock as any;
	const [isDeleting, setIsDeleting] = useState(false);
	const { results, scopeId, isScoped } = searchState;
	const { page, hasPrevPage, hasNextPage } = results;
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const refreshPage = (fileCount: number, folderCount: number) => {

		if ( fileCount !== 0 || folderCount !== 0 ) {
			return; // still showing results
		}

		// no longer showing results
		if (hasPrevPage === false && hasNextPage === false) {
			return; // there is no page to go to
		}

		// if there was a next page, then refreshing this page will bring that data forward
		// if there is only a previous page, then thats what we ask for
		const newPage = hasNextPage ? Number(page) : Number(page) - 1;
		console.log('newPage ' + newPage);

        history.push(
            `/search?query=${searchState.term}&deleted=${searchState.showDeleted}&scopeId=${scopeId}&scoped=${isScoped}&page=${newPage}`,
            {
                folderTree,
                searchState,
            }
        );

	};

	const deleteFile = () => {
		const { selectedFileIds, selectedFolderIds } = folderState;
		if (selectedFileIds.length === 0) { 
			return; 
		}
		setIsDeleting(true);
		const token = user!.access_token;
		deleteFileItem({ token, selectedIds: selectedFileIds })
			.then(() => {
				const filesWithoutId = filter(searchState.results.files, (file: any) => {
					return !selectedFileIds.includes(file.id);
				});
				doSearchStateAction({
					type: SEARCH_ACTIONS.UPDATE_SEARCH_RESULTS,
					payload: { ...searchState.results, files: filesWithoutId },
				});		
				doFolderStateAction({
					type: FOLDER_ACTIONS.CLEAR_SELECTION,
				});
				addToast(
					<ToastableFeedbackBlock
						success
						headerText={<span className="u-heading-xs">Deleted</span>}
						bodyText='If you need to recover the item(s), please contact support.'
					/>,
					{ autoDismiss: true }
				);
				setIsDeleting(false);
				refreshPage(filesWithoutId.length, selectedFolderIds.length);
			})
			.catch((error) => {
				console.error(error);
				addToast(
					<ToastableFeedbackBlock
						error
						headerText={<span className="u-heading-xs heavy-font">Failed to delete</span>}
						bodyText='Please try again or check your permissions.'
					/>,
					{ autoDismiss: true }
				);
			});
	};

	const removeFoldersFromContentView = (selectedFolderIds: number[]) => {
		// Mark: Remove from content view
		const searchFoldersWithoutId = filter(
			searchState.results.folders,
			(folder: FolderMetaDataType) => !selectedFolderIds.includes(folder?.id)
		);
		doSearchStateAction({
			type: SEARCH_ACTIONS.UPDATE_SEARCH_RESULTS,
			payload: { ...searchState.results, folders: searchFoldersWithoutId },
		});
	};

	const deleteFolder = () => {
		const { selectedFolderIds, selectedFileIds } = folderState;
		if (selectedFolderIds.length === 0) { 
			return; 
		}
		setIsDeleting(true);
		const token = user!.access_token;
		deleteFolderItem({ token, selectedIds: selectedFolderIds })
			.then((response) => {
				if(selectedFolderIds.length === 1 && response.toString().includes('403')){
					addToast(
						<ToastableFeedbackBlock
							error
							headerText={<span className="u-heading-xs heavy-font">Failed to delete</span>}
							bodyText="You cannot delete this as it contains folders."
						/>,
						{ autoDismiss: true }
					);
					setIsDeleting(false);
					return;
				} else {
					const deleteResponse: DeleteResponse = response;
					if(deleteResponse.data.failed){
						addToast(
							<ToastableFeedbackBlock
								error
								headerText={<span className="u-heading-xs heavy-font">Failed to delete</span>}
								bodyText='Please try again or check your permissions.'
								/>,
							{ autoDismiss: true }
						);
						setIsDeleting(false);
						return;
					}
					if(deleteResponse.data.partiallySucceeded){
						addToast(
							<ToastableFeedbackBlock
								error
								headerText={<span className="u-heading-xs heavy-font">Failed to delete</span>}
								bodyText='Please try again or check your permissions.'
								/>,
							{ autoDismiss: true }
						);
						setIsDeleting(false);
						removeFoldersFromContentView(deleteResponse.data.deletedIds);
						return;
					}
				}

				const searchFoldersWithoutId = filter(
					searchState.results.folders,
					(folder: FolderMetaDataType) => !selectedFolderIds.includes(folder?.id)
				);
				doSearchStateAction({
					type: SEARCH_ACTIONS.UPDATE_SEARCH_RESULTS,
					payload: { ...searchState.results, folders: searchFoldersWithoutId },
				});

				// Mark: Remove folder tree
				const { matches } = find({
					treeData: folderState?.folderTree?.data,
					searchQuery: Number(selectedFolderIds[0]),
					searchMethod: searchId,
					getNodeKey,
				});
				if (matches.length > 0) {
					matches.forEach((match) => {
						const treeData = removeNode({
							treeData: folderState?.folderTree?.data,
							ignoreCollapsed: true,
							path: match.path,
							getNodeKey,
						});
						if (treeData) {
							doFolderStateAction({
								type: FOLDER_ACTIONS.UPDATE_FOLDER_TREE,
								payload: {
									...folderState?.folderTree?.data,
									data: treeData?.treeData,
								},
							});
						}
					});
				}

				doFolderStateAction({
					type: FOLDER_ACTIONS.CLEAR_SELECTION,
				});
				addToast(
					<ToastableFeedbackBlock
						success
						headerText={<span className="u-heading-xs">Deleted</span>}
						bodyText='If you need to recover the item(s), please contact support.'
					/>,
					{ autoDismiss: true }
				);
				setIsDeleting(false);
				refreshPage(selectedFileIds.length, searchFoldersWithoutId.length);
			})
			.catch((error) => {
				console.error(error);
				addToast(
					<ToastableFeedbackBlock
						error
						headerText={<span className="u-heading-xs heavy-font">Failed to delete</span>}
						bodyText='Please try again or check your permissions.'
					/>,
					{ autoDismiss: true }
				);
				setIsDeleting(false);
			});
	};

	const handleDelete = () => {
		setShowConfirmationModal(false);
		deleteFile();
		deleteFolder();
	};
	const isFolderSelected = folderState.selectedFolderIds.length > 0;
	const isFileSelected  = folderState.selectedFileIds.length > 0;
	const enableButton = (!isFolderSelected && isFileSelected) || (isFolderSelected && !isFileSelected);

	return (
		<span className="action-bar__deleteDocumentButton action-bar-button">
			<Button
				secondary
				id="deleteDocument"
				type="button"
				data-e2e-testid="actionBarDeleteDocumentButton"
				data-testid="actionBarDeleteDocumentButton"
				onClick={() => setShowConfirmationModal(!showConfirmationModal)}
				loading={isDeleting}
				disabled={isDeleting || !enableButton}
			>
				<div className="action-bar-button__content">
					<img className="action-bar-button__icon" src={deleteIcon} alt="Delete Document" />
					{!isDeleting && <>Delete </>}
					{isDeleting && <>Deleting </>}
					<span className="u-visuallyhidden">Delete</span>
				</div>
			</Button>
			<Modal modalIsOpen={showConfirmationModal} closeModal={() => setShowConfirmationModal(false)}>
				<div className="modal__header amended-modal-header">
					<h3 className="modal__heading">Delete file(s)/folder(s)</h3>
				</div>
				<div className="modal__body amended-modal-body footer-color">
					<p>Are you sure you want to delete these file(s)/folder(s)?</p>
				</div>	
				<div className="modal__footer amended-modal-footer">
					<Button
						secondary
						type="button"
						className="modal__cancel-btn"
						onClick={() => setShowConfirmationModal(false)}>
						Cancel
					</Button>
					<Button
						primary="true"
						type="button"
						className="c-button c-button--danger"
						loading={isDeleting}
						onClick={() => handleDelete()}
					>
						{!isDeleting && <>Delete</>}
						{isDeleting && <>Deleting</>}
					</Button>
				</div>
			</Modal>
		</span>
	);
};

export default DeleteInSearch;
