import React, { createContext, useReducer } from 'react';
import { SearchStateType } from './search.context.types';

export enum SEARCH_ACTIONS {
    UPDATE_SEARCH_STATE = 'UPDATE_SEARCH_STATE',
    UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM',
    UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS',
    UPDATE_SEARCH_SHOW_DELETED = 'UPDATE_SEARCH_SHOW_DELETED',
    UPDATE_SEARCH_PAGE = 'UPDATE_SEARCH_PAGE',
    UPDATE_SEARCH_IS_SCOPED = 'UPDATE_SEARCH_IS_SCOPED',
    UPDATE_SEARCH_SCOPE_ID = 'UPDATE_SEARCH_SCOPE_ID'
}

export interface SearchActionReducerType {
    type: SEARCH_ACTIONS;
    payload: any;
}

export const SearchContext = createContext([] as any);

const reducer = (state: SearchStateType, action: SearchActionReducerType): SearchStateType => {
    switch (action.type) {
        case SEARCH_ACTIONS.UPDATE_SEARCH_STATE:
            return action.payload;
        case SEARCH_ACTIONS.UPDATE_SEARCH_TERM:
            return { ...state, term: action.payload };
        case SEARCH_ACTIONS.UPDATE_SEARCH_RESULTS:
            return { ...state, results: action.payload };
        case SEARCH_ACTIONS.UPDATE_SEARCH_SHOW_DELETED:
            return { ...state, showDeleted: action.payload };
        case SEARCH_ACTIONS.UPDATE_SEARCH_PAGE:
            return { ...state, results: { ...state.results, page: action.payload } };
        case SEARCH_ACTIONS.UPDATE_SEARCH_IS_SCOPED:
            return { ...state, isScoped: action.payload };
        case SEARCH_ACTIONS.UPDATE_SEARCH_SCOPE_ID:
            let payloadValue = isNaN(action.payload) ? 0 : action.payload;
            return { ...state, scopeId: payloadValue };
        default:
            throw Error('SearchContext reducer error');
    }
};

const initialState: SearchStateType = {
    term: '',
    results: {
        files: [],
        folders: [],
        hasNextPage: true,
        hasPrevPage: false,
        page: 1,
        totalCount: 0
    },
    showDeleted: false,
    isScoped: false,
    scopeId: 0
};

export const SearchProvider = (props: any) => {
    const stateHook = useReducer(reducer, initialState);
    return <SearchContext.Provider value={stateHook}>{props.children}</SearchContext.Provider>;
};
