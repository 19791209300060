import React, { FC } from 'react';
import './ActionLine.css';

export const ActionLine: FC = ({ children }) => (
  <div className="c-action-line">
    <div className="c-action-line__line-col">
      <span className="c-action-line__line-col-circle"></span>
      <span className="c-action-line__line-col-circle"></span>
    </div>
    <div className="c-action-line__contents-col">{children}</div>
  </div>
);