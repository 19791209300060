import head from 'lodash/head';
import isNil from 'lodash/isNil';
import { FileType } from '../api';

interface selectedIds {
	selectedIds: string[];
}
interface GetSelectFileType extends selectedIds {
	files?: FileType[];
}
interface GetSelectFolderType extends selectedIds {
	folders?: any[];
}

export const getSelectedFileFrom = ({ files, selectedIds }: GetSelectFileType): FileType | undefined => {
	if (isNil(files)) return;
	return head(files?.filter((item: FileType) => item.id === Number(head(selectedIds))));
};

export const getSelectedFolderFrom = ({ folders, selectedIds }: GetSelectFolderType): any | undefined => {
	if (isNil(folders)) return;
	return head(folders?.filter((item: any) => item?.id === head(selectedIds)));
};
