import { useContext, useEffect } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { AppContext } from '../../authentication/AppProvider';
import { getConfig } from '../../common/config';
import { useLocation } from 'react-router-dom';
import { PREVIOUS_FOLDER_ID } from '../../common/constants';
import last from 'lodash/last';
import trimEnd from 'lodash/trimEnd';

export const FolderHubSetup = () => {
    let location = useLocation();
    const { hubConnection, user, setHubConnection } = useContext(AppContext);
    const config = getConfig();
    
    useEffect(() => {
        setupHubConnection();
        // eslint-disable-next-line
    }, []);

    const setupHubConnection = () =>  {
        if (hubConnection) {
          console.log('>> connection to hub already set up <<');
          return;
        }
    
        console.log(`>> build connection to hub at ${config.hubBase}/folders <<`);
    
        const connection = new HubConnectionBuilder()
        .withUrl(`${config.hubBase}/folders`,{
            accessTokenFactory: () => `${user?.access_token}`
          })
        .withAutomaticReconnect()
        .build();
    
        if (connection) {

            setHubConnection(connection);

            connection.start()
            .then(result => {
                console.log('Connected!');

                let activeFolderId = null;
                const previousFolderId = null;
                const pathArray = trimEnd(location.pathname,'/').split('/');
                if (pathArray.length > 0 && pathArray.indexOf('folder') !== -1) {
                    activeFolderId = last(pathArray);
                    console.log(`>> pathArray:activeFolderId = ${activeFolderId} <<`);
                }
                if (activeFolderId && Number(activeFolderId) !== Number(previousFolderId)){
                    if (activeFolderId && connection && connection.state === 'Connected') {
                        console.log(`>> SwitchClientFolderGroups from Folder${previousFolderId}Group to Folder${activeFolderId}Group <<`);
                        connection.send('SwitchClientFolderGroups', Number(previousFolderId), Number(activeFolderId));
                    }
        
                    sessionStorage.setItem(PREVIOUS_FOLDER_ID, activeFolderId);
                }
            })
            .catch(e => console.log('Connection failed: ', e));
        }
      };

    return null;
};