import React, { useContext, useState } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import { Button, ToastAtoms } from '@freshfields/react-component-library';
import { AppContext } from '../../../../authentication/AppProvider';
import { FolderContext, FOLDER_ACTIONS, FolderMetaDataType, SearchContext } from '../../../../context';
import deleteIcon from '../../../../images/delete.svg';
import { deleteFileItem, deleteFolderItem } from '../../../../api';
import { removeNode, find } from 'react-sortable-tree';
import { getNodeKey } from '../../../FolderTree';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../../Modal';

interface DeleteResponse {
	data: {
		succeeded : boolean;
		partiallySucceeded: boolean;
		failed: boolean;
		deletedIds: number[];
		failedDeletedIds: number[];
	};
}
const searchId = ({ node, path, treeIndex, searchQuery }: any) => {
	 return node.id === searchQuery;
};

const Delete: React.FC = () => {
	const history = useHistory();
	const { user } = useContext(AppContext);
	const [searchState] = useContext(SearchContext);
	const [folderState, doFolderStateAction] = useContext(FolderContext);
	const { activeFolderId, folders, folderTree } = folderState;
	const { page } = folders;
	const { addToast } = ToastAtoms.useToasts();
	// NOTE: workaround for incorrect types on ToastableFeedbackBlock
	const ToastableFeedbackBlock = ToastAtoms.ToastableFeedbackBlock as any;
	const [isDeleting, setIsDeleting] = useState(false);
	const [newFolderModal, setNewFolderModal] = useState(false);

	const refreshPage = (remainingCount : number) => {
		let newPage = (remainingCount === 0) ? page - 1 : page;
		newPage = (newPage < 1) ? null : newPage;
		const order = folders?.order ? `order=${folders?.order}` : null;
		const asc = folders?.asc ? `asc=${folders?.asc}` : null;
		const pageString = newPage ? `page=${newPage}` : null;
		const query = compact([order, asc, pageString]).join('&');
		history.push(`/folder/${activeFolderId}/?${query}`, {
			folderTree,
			searchState,
		});
	};

	const deleteFile = () => {
		const { selectedFileIds } = folderState;
		if (selectedFileIds.length === 0) {
			return;
		}
		setIsDeleting(true);
		const token = user!.access_token;
		deleteFileItem({ token, selectedIds: selectedFileIds })
			.then(() => {
				const filesWithoutId = filter(folderState.folders.files, (file: any) => {
					return !selectedFileIds.includes(file.id);
				});
				doFolderStateAction({
					type: FOLDER_ACTIONS.FETCH_FILES,
					payload: [...filesWithoutId],
				});
				doFolderStateAction({
					type: FOLDER_ACTIONS.CLEAR_SELECTION,
				});
				addToast(
					<ToastableFeedbackBlock
						success
						headerText={<span className="u-heading-xs">Deleted</span>}
						bodyText='If you need to recover the item(s), please contact support.'
					/>,
					{ autoDismiss: true }
				);
				setIsDeleting(false);
				refreshPage(filesWithoutId.length);
			})
			.catch((error) => {
				console.error(error);
				addToast(
					<ToastableFeedbackBlock
						error
						headerText={<span className="u-heading-xs heavy-font">Failed to delete</span>}
						bodyText='Please try again or check your permissions.'
					/>,
					{ autoDismiss: true }
				);
			});
	};

	const removeFoldersFromContentView = (selectedFolderIds: number[]) => {
		// Mark: Remove from content view
		const folderChildrenWithoutId = filter(
			folderState.folders?.children,
			(folder: FolderMetaDataType) => !selectedFolderIds.includes(folder?.id)
		);
		doFolderStateAction({
			type: FOLDER_ACTIONS.FETCH_FOLDERS,
			payload: { ...folderState.folders, children: folderChildrenWithoutId },
		});
	};

	const deleteFolder = () => {
		const { selectedFolderIds } = folderState;
		if (selectedFolderIds.length === 0) {
			return;
		}
		setIsDeleting(true);
		const token = user!.access_token;
		deleteFolderItem({ token, selectedIds: selectedFolderIds })
			.then((response) => {
				if(selectedFolderIds.length === 1 && response.toString().includes('403')){
					addToast(
						<ToastableFeedbackBlock
							error
							headerText={<span className="u-heading-xs heavy-font">Failed to delete</span>}
							bodyText="You cannot delete this as it contains folders."
						/>,
						{ autoDismiss: true }
					);
					setIsDeleting(false);
					return;
				} else {
					const deleteResponse: DeleteResponse = response;
					if(deleteResponse.data.failed){
						addToast(
							<ToastableFeedbackBlock
								error
								headerText={<span className="u-heading-xs heavy-font">Failed to delete</span>}
								bodyText='Please try again or check your permissions.'
								/>,
							{ autoDismiss: true }
						);
						setIsDeleting(false);
						return;
					}
					if(deleteResponse.data.partiallySucceeded){
						addToast(
							<ToastableFeedbackBlock
								error
								headerText={<span className="u-heading-xs heavy-font">Failed to delete</span>}
								bodyText='Please try again or check your permissions.'
								/>,
							{ autoDismiss: true }
						);
						setIsDeleting(false);
						removeFoldersFromContentView(deleteResponse.data.deletedIds);
						return;
					}
				}
				// Mark: Remove folder tree
				const { matches } = find({
					treeData: folderState?.folderTree?.data,
					searchQuery: Number(selectedFolderIds[0]),
					searchMethod: searchId,
					getNodeKey,
				});
				if (matches.length > 0) {
					matches.forEach((match) => {
						const treeData = removeNode({
							treeData: folderState?.folderTree?.data,
							ignoreCollapsed: true,
							path: match.path,
							getNodeKey,
						});
						if (treeData) {
							doFolderStateAction({
								type: FOLDER_ACTIONS.UPDATE_FOLDER_TREE,
								payload: {
									...folderState?.folderTree?.data,
									data: treeData?.treeData,
								},
							});
						}
					});
				}
				removeFoldersFromContentView(selectedFolderIds);
				doFolderStateAction({
					type: FOLDER_ACTIONS.CLEAR_SELECTION,
				});
				doFolderStateAction({
					type: FOLDER_ACTIONS.CLEAR_SELECTION,
				});
				addToast(
					<ToastableFeedbackBlock
						success
						headerText={<span className="u-heading-xs">Deleted</span>}
						bodyText='If you need to recover the item(s), please contact support.'
					/>,
					{ autoDismiss: true }
				);
				setIsDeleting(false);
				refreshPage(1);
			})
			.catch((error) => {
				console.error(error);
				addToast(
					<ToastableFeedbackBlock
						error
						headerText={<span className="u-heading-xs heavy-font">Failed to delete</span>}
						bodyText='Please try again or check your permissions.'
					/>,
					{ autoDismiss: true }
				);
				setIsDeleting(false);
			});
	};

	const handleDelete = () => {
		setNewFolderModal(false);
		deleteFile();
		deleteFolder();
	};
	const isFolderSelected = folderState.selectedFolderIds.length > 0;
	const isFileSelected  = folderState.selectedFileIds.length > 0;
	const enableButton = (!isFolderSelected && isFileSelected) || (isFolderSelected && !isFileSelected);

	return (
		<span className="action-bar__deleteDocumentButton action-bar-button">
			<Button
				secondary
				id="deleteDocument"
				type="button"
				data-e2e-testid="actionBarDeleteDocumentButton"
				data-testid="actionBarDeleteDocumentButton"
				onClick={() => setNewFolderModal(!newFolderModal)}
				loading={isDeleting}
				disabled={isDeleting || !enableButton}
			>
				<div className="action-bar-button__content">
					<img className="action-bar-button__icon" src={deleteIcon} alt="Delete Document" />
					{!isDeleting && <>Delete</>}
					{isDeleting && <>Deleting</>}
					<span className="u-visuallyhidden">Delete</span>
				</div>
			</Button>
			<Modal modalIsOpen={newFolderModal} closeModal={() => setNewFolderModal(false)}>
				<div className="modal__header amended-modal-header">
					<h3 className="modal__heading">Delete file(s)/folder(s)</h3>
				</div>
				<div className="modal__body amended-modal-body footer-color">
					<p>Are you sure you want to delete these file(s)/folder(s)?</p>
				</div>	
				<div className="modal__footer amended-modal-footer">
					<Button
						secondary
						type="button"
						className="modal__cancel-btn"
						onClick={() => setNewFolderModal(false)}>
						Cancel
					</Button>
					<Button
						primary="true"
						type="button"
						className="c-button c-button--danger"
						loading={isDeleting}
						onClick={() => handleDelete()}
					>
						{!isDeleting && <>Delete</>}
						{isDeleting && <>Deleting</>}
					</Button>
				</div>
			</Modal>		
		</span>
	);
};

export default Delete;
