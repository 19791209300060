import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { format, parse, formatISO } from 'date-fns';
import './Time.style.css';

interface Props {
  date: any;
  accessibilityContext: string;
}

export const Time: FC<Props> = ({ date, accessibilityContext }) => {

  return (
    <div className="c-time">
      <FontAwesomeIcon icon={faClock} size="xs" />
      <span className="u-visuallyhidden">{accessibilityContext}</span>
      <span className="c-time-text">{format(new Date(date), 'hh:mma')}</span>
    </div>
  );
};
