import React, { useContext } from 'react';
import { createMailtoLinkFromSearch } from '../../../../common/download';
import { Button } from '@freshfields/react-component-library';
import { AppContext } from '../../../../authentication/AppProvider';
import { FolderContext, SearchContext } from '../../../../context';
import shareIcon from '../../../../images/share.svg';
import filter from 'lodash/filter';
import first from 'lodash/first';

const ShareInSearch: React.FC = () => {
  const { user } = useContext(AppContext);
  const [folderState] = useContext(FolderContext);
  const [searchState] = useContext(SearchContext);

  const handleSharableLink = async () => {
		const { selectedFileIds, selectedFolderOrFileName } = folderState;
		if (selectedFileIds.length !== 1) return;
		if (searchState.results.files.length < 1) return;

		const selectedFileId = selectedFileIds[0];
		const selectedFile = first(filter(searchState.results.files,(f:any) => f.id === selectedFileId));

		const { parentFolderId } = selectedFile;

		const link = createMailtoLinkFromSearch({
			selectedFolderOrFileName,
			selectedFileIds,
			name: user?.profile?.name,
			parentFolderId
		});

		document.body.appendChild(link).click();
  };
  const isFolderSelected = folderState.selectedFolderIds.length > 0;
  const isFileSelected  = folderState.selectedFileIds.length === 1;
  const enableButton = (!isFolderSelected && isFileSelected);
   
  return (
		<span className="action-bar__shareButton action-bar-button">
			<Button
				secondary
				id="share"
				type="button"
				data-e2e-testid="actionBarShareButton"
				data-testid="actionBarShareButton"
				onClick={() => handleSharableLink()}
				disabled={!enableButton}
			>
				<div className="action-bar-button__content">
					<img className="action-bar-button__icon" src={shareIcon} alt="Share" />
					Share
					<span className="u-visuallyhidden">share</span>
				</div>
			</Button>
		</span>
  );
};

export default ShareInSearch;