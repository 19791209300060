import Chip from '@material-ui/core/Chip';
import StopIcon from '@material-ui/icons/Stop';
import { cancelJob } from '../../api/put';
import { ToastAtoms } from '@freshfields/react-component-library';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../authentication/AppProvider';
import { Modal } from '../Modal';
import { Button } from '@freshfields/react-component-library';
import './JobsCancel.css';

const JobCancel = (props: any) => {
    const { user } = useContext(AppContext);
	const { addToast } = ToastAtoms.useToasts();
	// NOTE: workaround for incorrect types on ToastableFeedbackBlock
	const ToastableFeedbackBlock = ToastAtoms.ToastableFeedbackBlock as any;
	const [showConfirmation, setShowConfirmation] = useState(false);
	
    const handleCancelJob = (id: number) => {
		const token = user!.access_token;
		cancelJob({
			token,
			id
		})
		.then((response) => {
			addToast(
				<ToastableFeedbackBlock
					success
					headerText={<span className="u-heading-xs">Job Cancellation</span>}
                    bodyText="A request to cancel the job has been sent successfully"
				/>,
				{ autoDismiss: true }
			);
		})
		.catch((error) => {
			console.log(error);
			addToast(
				<ToastableFeedbackBlock
					error
					headerText={<span className="u-heading-xs">Failed to send a cancel job request</span>}
				/>,
				{ autoDismiss: true }
			);
		});

		props.onDone();
    };

    return (
		<div className="jobs-chip">
			<Chip
				size="small"
				icon={<StopIcon />}
				label="Stop"
				color="primary"
				onClick={() => {
					setShowConfirmation(true);
				}}
			/>
			
			<Modal modalIsOpen={showConfirmation} closeModal={() => setShowConfirmation(false)}>
				<div className="modal__header amended-modal-header">
					<h3 className="modal__heading">Cancel Job?</h3>
				</div>
				<div className="modal__body amended-modal-body footer-color">
					<p>Are you sure you want to cancel job number <b>{props.id}</b>?</p>
				</div>	
				<div className="modal__footer amended-modal-footer">
					<Button
						secondary
						type="button"
						className="modal__cancel-btn"
						onClick={() => setShowConfirmation(false)}
					>
						Leave Job to run
					</Button>
					<Button
						primary="true"
						type="button"
						onClick={() => handleCancelJob(props.id)}
					>
						Cancel selected Job
					</Button>
				</div>
			</Modal>
		</div>
    );
};

export default JobCancel;

