import React, { useRef, useState, useContext } from 'react';
import get from 'lodash/get';
import { AppContext } from '../../../../authentication/AppProvider';
import { FolderContext, FOLDER_ACTIONS } from '../../../../context/FolderContext';
import { Button, ToastAtoms } from '@freshfields/react-component-library';
import { FolderTree } from '../../../FolderTree';
import { DrawerPanel } from '../../../DrawerPanel';
import { copyItemsToFolder } from '../../../../api';
import copyIcon from '../../../../images/copy.svg';
import { FolderRetriever } from '../../../FolderRetriever';
import { Modal } from '../../../Modal';

const CopyToFolder: React.FC = () => {
	const { user } = useContext(AppContext);
	const [folderState, doFolderStateAction] = useContext(FolderContext);
	const [showWindow, setShowWindow] = useState(false);
	const target = useRef({ id: -1, folderName: '' });
	const { addToast } = ToastAtoms.useToasts();
	// NOTE: workaround for incorrect types on ToastableFeedbackBlock
	const ToastableFeedbackBlock = ToastAtoms.ToastableFeedbackBlock as any;
	const [isCopying, setIsCopying] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [howToDoMessage, sethowToDoMessage] = useState('... or search via the folder tree');

	const handleCopyToFolder = () => {
		setShowConfirmation(false);
		setIsCopying(true);
		copyItemsToFolder({
			token: get(user, 'access_token'),
			fileIds: folderState.selectedFileIds,
			folderIds: folderState.selectedFolderIds,
			folderId: Number(target.current.id),
		})
			.then((response) => {
				// TODO: Validation
				setShowWindow(!showWindow);
				doFolderStateAction({
					type: FOLDER_ACTIONS.CLEAR_SELECTION,
				});

				if(response.message === 'Request failed with status code 409'){
					addToast(
						<ToastableFeedbackBlock
							error
							headerText={<span className="u-heading-xs">Copy failed</span>}
							bodyText='Unable to copy folder into itself.'
						/>,
						{ autoDismiss: true }
					);
				}
				else if(response.message === 'Request failed with status code 404'){
						addToast(
							<ToastableFeedbackBlock
								error
								headerText={<span className="u-heading-xs">Copy failed</span>}
								bodyText='Error copying file(s), please make sure you selected a destination folder.'
							/>,
							{ autoDismiss: true }
						);
				}else{

				addToast(
					<ToastableFeedbackBlock
						success
						headerText={<span className="u-heading-xs">File(s) copied</span>}
						bodyText='Your file(s) have been copied to the selected folder'
					/>,
					{ autoDismiss: true }
				);

				}
				setIsCopying(false);
			})
			.catch((error) => {
				console.error(error);
				addToast(
					<ToastableFeedbackBlock
						error
						headerText={<span className="u-heading-xs">Copy failed</span>}
						bodyText='An error occurred while copying your file(s)'
					/>,
					{ autoDismiss: true }
				);
				setIsCopying(false);
			});
	};

	const confirmAction = () => {
		if (target.current.id === -1) {
			setShowWindow(!showWindow);
			addToast(
				<ToastableFeedbackBlock
					error
					headerText={<span className="u-heading-xs">Please select a destination folder</span>}
					bodyText='Select a folder from the folder tree, or via search'
				/>,
				{ autoDismiss: true }
			);
			return;
		}

		setShowConfirmation(true);

	};

	const handleSearchFolderSelection = (folderId: number, folderName: string) => {
		target.current = { id: folderId, folderName: folderName };
	};

	const handleSearchFolderSelectionCancelled = () => {
		target.current = { id: -1, folderName: '' };
	};

	const showStandardHowToMessage = () => {
		const msg = '... or search via the folder tree';
		sethowToDoMessage(msg);

	};

	const showExtendedHowToMessage = () => {
		const msg = 'more than 50 results, be more specific if you do not find your folder ... or search via the folder tree';
		sethowToDoMessage(msg);

	};

	const handleOnNotifyAdditionalResults = () => {
		showExtendedHowToMessage();
	};

	const handleOnClearNotifyAdditionalResults = () => {
		showStandardHowToMessage();
	};

	const isFolderSelected = folderState.selectedFolderIds.length > 0;
	const isFileSelected = folderState.selectedFileIds.length > 0;
	const enableButton = (isFolderSelected || isFileSelected);

	const closeMe = () => {
		showStandardHowToMessage();
		setShowWindow(false);
	};

	return (
		<span className="action-bar__copyDocumentButton action-bar-button">
			<Button
				secondary
				id="copyToFolder"
				type="button"
				data-e2e-testid="actionBarCopyToFolderButton"
				data-testid="actionBarCopyToFolderButton"
				onClick={() => setShowWindow(!showWindow)}
				disabled={!enableButton}
			>
				<div className="action-bar-button__content">
					<img className="action-bar-button__icon" src={copyIcon} alt="Copy to folder" />
					Copy to folder
					<span className="u-visuallyhidden">Copy to folder</span>
				</div>
			</Button>
			<DrawerPanel toggleDrawer={showWindow} setToggleDrawer={() => closeMe()}>
				<div className="default-drawer">
					<h3 className="mb-2">Copy to folder</h3>
					<>
						<div className="panel-search-card">
							<FolderRetriever OnClearSelectionHandler={handleSearchFolderSelectionCancelled} OnSelectionHandler={handleSearchFolderSelection} 
								OnNotifyAdditionalResults={handleOnNotifyAdditionalResults} OnClearNotifyAdditionalResults={handleOnClearNotifyAdditionalResults}  />
							<Button
								primary="true"
								id="copyToFolder1"
								type="button"
								data-e2e-testid="actionBarCopyToFolderDrawButton"
								data-testid="actionBarCopyToFolderDrawButton"
								onClick={confirmAction}
								loading={isCopying}
								disabled={isCopying}
							>
								{!isCopying && <>Copy To Folder</>}
								{isCopying && <>Copying</>}
							</Button>	
						</div>					
						<p className="panel-card-header">{howToDoMessage}</p>
						<div className="panel-search-card">
						<div className="folder-tree-header">
							<h5 className="text-sm uppercase mb-3">Folder Tree</h5>
						</div>
						<div className="ml-6 my-6">
							<FolderTree
								independent
								onItemClick={(ft) => {
									target.current = ft;
								}}
							/>
						</div>
						<Button
								primary="true"
								id="copyToFolder2"
								type="button"
								data-e2e-testid="actionBarCopyToFolderDrawButton"
								data-testid="actionBarCopyToFolderDrawButton"
								onClick={confirmAction}
								loading={isCopying}
								disabled={isCopying}
							>
								{!isCopying && <>Copy To Folder</>}
								{isCopying && <>Copying</>}
							</Button>	
						</div>
					</>
				</div>
			</DrawerPanel>
			<Modal modalIsOpen={showConfirmation} closeModal={() => setShowConfirmation(false)}>
				<div className="modal__header amended-modal-header">
					<h3 className="modal__heading">Copy to folder file(s) / folder(s)?</h3>
				</div>
				<div className="modal__body amended-modal-body footer-color">
					<p>Are you sure you want to copy these file(s) / folder(s)?</p>
				</div>
				<div className="modal__footer amended-modal-footer">
					<Button
						secondary
						type="button"
						className="modal__cancel-btn"
						onClick={() => setShowConfirmation(false)}>
						Cancel
					</Button>
					<Button
						primary="true"
						type="button"
						onClick={() => handleCopyToFolder()}
					>
						Copy to folder
					</Button>
				</div>
			</Modal>			
		</span>
	);
};

export default CopyToFolder;
